import environment from "graphql/environment/index.js";
import { commitLocalUpdate } from "relay-runtime";

export default function selectData(values, dataId, useCache = true) {
  commitLocalUpdate(environment, store => {
    if (!values || !dataId) return;

    let data = store.get(`_${dataId}`);
    let newValues = values;
    if (!data) data = store.create(`_${dataId}`, `selectedData`);
    const oldData = data.getValue("details");
    if (oldData && useCache) {
      newValues = { ...JSON.parse(oldData), ...newValues };
    }
    data.setValue(JSON.stringify(newValues), `details`);

    if (!data) return;
    store.getRoot().setLinkedRecord(data, `selected${dataId}`);
  });
}
export function stringify(record = null, values) {
  if (!record) return JSON.stringify(values).toLowerCase();
  return record && record.setValue(JSON.stringify(values), "details");
}