import { checkCharLimit } from "helpers/checkCharLimit";

const toolBar = (maxChars, field) => ({
  menubar: false,
  inline: true,
  toolbar_mode: "scrolling",
  content_css: "//www.tiny.cloud/css/codepen.min.css",
  max_chars: maxChars,
  toolbar:
    "undo redo | bold italic underline | alignleft aligncenter alignright alignfull  |  fontfamily | fontsize | link | forecolor ",
  setup: function (editor) {
    checkCharLimit(editor);
    // This prevents the blur event from hiding the toolbar
    editor.on("blur", () => {
      return false;
    });
  },
  link_target_list: false,
  font_size_formats:
    "8px 10px 12px 14px 18px 24px 36px 38px 40px 45px 50px 60px",
  font_family_formats:
    "Raleway light=Raleway; Segoe UI Light=Segoe UI Light; Andale Mono=andale mono v1; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal-regular; Times New Roman=times new roman; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
  content_style:
    "@import url(https://db.onlinewebfonts.com/c/663974c9fe3ba55b228724fd4d4e445f?family=Andale+Mono+V1); @import url(https://db.onlinewebfonts.com/c/2abddb216b6ffd1c0f62697728f7cf94?family=Terminal-Regular); @import url(https://db.onlinewebfonts.com/c/32441506567156636049eb850b53f02a?family=Times+New+Roman); @import url(//db.onlinewebfonts.com/c/5076583fa2a14cd2cf3634ff59a1138b?family=Segoe+UI+Light);  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');",
});

export const headingInit = (limit = 60) => ({
  placeholder: "Add title here",
  content_style: `
	  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
		  color: rgba(34,47,62,.7);
	  }
	`,
  link_target_list: false,
  selector: "#main-heading",
  plugins: ["lists", "link"],
  valid_elements: "p[style],strong,em,span[style],a[href]",
  valid_styles: {
    "*": "font-size,font-family,color,text-decoration,text-align",
  },
  ...toolBar(limit, "headingInit"),
  init_instance_callback: function (editor) {
    checkCharLimit(editor, limit);
  },
});

export const subHeadingInit = (limit = 250) => ({
  placeholder: "Add subheading here",
  content_style: `
	  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
		  color: rgba(34,47,62,.7);
	  }
	`,
  link_target_list: false,
  selector: "#main-sub-heading",
  plugins: ["link", "lists", "autolink"],
  valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li",
  valid_styles: {
    "*": "font-size,font-family,color,text-decoration,text-align",
  },
  ...toolBar(limit, "subHeadingInit"),
  init_instance_callback: function (editor) {
    checkCharLimit(editor, limit);
  },
});

export const rowHeadingInit = id => ({
  placeholder: "Add row title here",
  content_style: `
	  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
		  color: rgba(34,47,62,.7);
	  }
	`,
  selector: `#showroom-row-${id}`,
  menubar: false,
  inline: true,
  link_target_list: false,
  plugins: ["link", "lists", "autolink"],
  valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li",
  valid_styles: {
    "*": "font-size,font-family,color,text-decoration,text-align",
  },
  content_css: "//www.tiny.cloud/css/codepen.min.css",
});

export const subBodyInit = (id, limit = 80) => ({
  placeholder: "Add description here",
  content_style: `
	  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
		  color: rgba(34,47,62,.7);
	  }
	`,
  selector: `#minor-heading-${id}`,
  link_target_list: false,
  plugins: ["link", "lists", "autolink"],
  valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li",
  valid_styles: {
    "*": "font-size,font-family,color,text-decoration,text-align",
  },
  ...toolBar(limit, "subBodyInit"),
  init_instance_callback: function (editor) {
    checkCharLimit(editor, limit);
  },
});

export const bodyInit = (id, limit = 300) => ({
  placeholder: "Add description here",
  content_style: `
	  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
		  color: rgba(34,47,62,.7);
	  }
	`,
  link_target_list: false,
  selector: `#minor-sub-heading-${id}`,
  plugins: ["link", "lists", "autolink"],
  valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li",
  valid_styles: {
    "*": "font-size,font-family,color,text-decoration,text-align",
  },
  ...toolBar(limit, "bodyInit"),
  init_instance_callback: function (editor) {
    checkCharLimit(editor, limit);
  },
});

export const focusEventHandlerEditor = (event, classNme) => {
  let pathNameList = window.location.pathname.split("/");
  let editor = event.target.editorContainer.querySelector(`.${classNme}`);
};
