const BREAKPOINTS = {
  fourK: 3840,
  twentySevenInch: 1920,
  thirteenInch: 1440,
};
export const IFRAMES =
  /(drive.google.com\/file)|(docs.google.com\/presentation)|(docs.google.com\/spreadsheets)|(docs.google.com\/document)|(youtube.com)|(youtu.be)|^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
const allowedExtensions =
  /(jpe?g|png|tif|gif|bmp|doc|docx|pdf|txt|plain|mp4|xls|excel|xlsx|ppt|msword|mpeg|wmv|ogg|mov|quicktime)/;
export const QUERIES = {
  fourK: `(min-width: ${BREAKPOINTS.fourK}px)`,
  twentySevenInch: `(min-width: ${BREAKPOINTS.twentySevenInch}px)`,
  thirteenInch: `(min-width: ${BREAKPOINTS.thirteenInch}px)`,
};
export const DEFAULT_RDR = "studio";
export const API = process.env.REACT_APP_API;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
export const LIBRARY_URL = process.env.REACT_APP_LIBRARY_URL;
export const STUDIO_URL = process.env.REACT_APP_STUDIO_URL;
export const MPUBLISHER_URL = process.env.REACT_APP_MPUBLISHER_URL;
export const WIZRX_URL = process.env.REACT_APP_WIZRX_URL;
export const VIDEO_DASHBOARD_URL = process.env.REACT_APP_VIDEO_DASHBOARD_URL;
export const WIZRUP_URL = process.env.REACT_APP_WIZRUP_URL;
export const ENC_SECRET = process.env.REACT_APP_ENC_SECRET;
export const SECRET_IV = process.env.REACT_APP_ENC_SECRET_IV;
export const BUCKET = process.env.REACT_APP_BUCKET;
export const AWS_KEY = process.env.REACT_APP_AWS_KEY;
export const AWS_KEY_ID = process.env.REACT_APP_AWS_KEY_ID;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const OFFICE_URL = `https://view.officeapps.live.com/op/embed.aspx?src=${STATIC_URL}`;
export const GOOGLE_URL = `https://docs.google.com/viewer?embedded=true&url=${STATIC_URL}`;
export const SOCKET_DOMAIN = API.replace("http", "ws");
export const ALLOWED_FILES = allowedExtensions;
export const ALLOWED_IMAGES = /(jpe?g|png|tif|gif|bmp|ico|svg)/;
export const ALLOWED_EXCEL = /(xlsx|xls|excel|xltm|xlts|xltx)/;
export const ALLOWED_DOCS = /(docx|docm|dotx|doc)/;
export const ALLOWED_PPT = /(sldx|sldm|pps|ppt)/;
export const ALLOWED_PDF = /(pdf)/;
export const VALID_URL =
  /^(?:https?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export const CREATOR = "CREATOR";
export const SUPERADMINISTRATOR = "SUPERADMINISTRATOR";
export const SUPER_ROLES = [CREATOR, SUPERADMINISTRATOR];
export const ADMINISTRATOR = "ADMINISTRATOR";
export const EDITOR = "EDITOR";
export const PUBLISHER = "PUBLISHER";
export const PORTAL_ROLES = [ADMINISTRATOR, EDITOR, PUBLISHER];
