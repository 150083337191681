import Banner from "../Templates/banner";
import { MainWrapper } from "../Templates/styles";
import { ListWrapper, CardWrapper } from "../lists/styles";
import StickyNav from "containers/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { ListImageComponent } from "./common";
import EmptyList from "./emptyList";
import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import "./style.scss";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as Link } from "../../assets/images/create-icon-2.svg";
import { Title } from "containers/Templates/coverpages/common";
import Input from "components/ui/atoms/Input/input";
import template1 from "assets/images/template1.png";
import template2 from "assets/images/template2.png";
import template3 from "assets/images/template3.png";
import template4 from "assets/images/template4.png";
import template5 from "assets/images/template5.png";
import template6 from "assets/images/template6.png";
import expandable3 from "assets/images/expandable3.png";
import template8 from "assets/images/template8.png";
import template9 from "assets/images/template9.png";
import template10 from "assets/images/template10.png";
import template11 from "assets/images/template11.png";
import { showErrorToast } from "@master-wizr/toast";
import { SUPER_ROLES, PORTAL_ROLES, STATIC_URL } from "constants/env";
import { useQuery } from "relay-hooks";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import ImgModal from "components/ui/atoms/ImgModal/ImgModal";
import IsLoading from "components/ui/atoms/IsLoading";
import microsite2 from "assets/images/micrositedemo.png";
import CancelButton from "components/ui/atoms/CancelButton";
import showroomsmicro1 from "assets/images/microsite1option.png";
import showroomsmicro2 from "assets/images/microsite2option.png";
import covermicro from "assets/images/covermicrosite.png";
import debounce from 'lodash.debounce';
import UpgradePoup from '../../components/ui/atoms/UpgradePopup/UpgradePopup.js';
import MicrositesByPortalQuery from "graphql/queries/GetMicrositesByPortal";
import selectData from "graphql/mutations/selectData";
import LimitModal from "components/ui/molecules/LimitModal/LimitModal";
import { useHasModuleAccess } from "hooks/useModuleAccess";
import { useTranslation } from "react-i18next";
import { useTour } from "@reactour/tour";
import ShowroomsByPortalQuery from "graphql/queries/showroom/ShowroomsByPortal";

export function List({
  title,
  subtitle,
  top,
  logo,
  bannerImg,
  items,
  isLoading,
  isList,
  deleteFunc,
  showrooms,
  handleEdit,
  isInStepOne,
  ShowRoomIds,
  checking,
  logosvg,
  mode,
  microsite,
  micrositeShowrooms,
  maxPerPage,
  selecectedListShowrooms,
  editMicrositeMode,
  editMicroste,
  editMicrositeSelectedCoverpage,
  editMicrositeSelectedTemplate,
  changeCoverpageFunc,
  editMicrositeCoverpageMode,
  isDeleting,
  isCreateMode
}) {
  const {t} = useTranslation();
  const navigation = useNavigate();
  const location = useLocation();
  const [check, setChecked] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedCoverPage, setSelectedCoverPage] = useState(editMicrositeSelectedCoverpage || null);
  const [selectedCoverPageTemplate, setSelectedCoverPageTemplate] =
    useState(editMicrositeSelectedTemplate || null);
  const [showRoomIds, setShowRoomIds] = useState();
  const [roomRadioOption, setRoomRadioOption] = useState("2+7");
  const [optionsPopup, setOptionsPopup] = useState(false);
  const [isShowroom, setisShowroom] = useState(false);
  const [finish, setFinish] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const sidebar = getClientData(useQuery, clientOnly, {}, "Sidebar");
  const portalRole = portal?.portalRole;
  const accountRole = getClientData(useQuery, clientOnly, {} , "Profile")?.accountRole;
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [showLicensePopup, setShowLicensePopup] = useState(false);
  const [micrositesArr, setMicrositesArr] = useState([]);
  const microsites = useQuery(
    MicrositesByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );
  const showroomsData = useQuery(
    ShowroomsByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );
  const{currentStep, setCurrentStep, setIsOpen, steps} = useTour();
  const modals = getClientData(useQuery, clientOnly, {}, "Modal");
  useEffect(() => {
    if (microsites.isLoading) return;
    setMicrositesArr(microsites?.data?.micrositesByPortal?.microsites);
  }, [microsites.isLoading, microsites?.data]);
  const checkLicense = () => {
    const license = profile?.accountInfo?.licenseInfo?.licenseType;
    if(license === "STUDENT" && micrositesArr.length >= 2){
      setShowLicensePopup(true);
      return true;
    }
    setOptionsPopup(true);
    return false;
  }
  useEffect(() => {
    !optionsPopup && setRoomRadioOption("2+7")
  }, [optionsPopup]);

  useLayoutEffect(() => {
    if (items) {
      setItemsData([...items].reverse());
    }
  }, [items]);
  useEffect(()=>{
    if(portalRole!==undefined&&accountRole!==undefined){
    if(!(PORTAL_ROLES.includes(portalRole)||SUPER_ROLES.includes(accountRole))){
      setUnAuthorized(true);
     }
    }
  },[accountRole, portalRole]);
  useEffect(()=>{
    if(editMicrositeMode||isCreateMode){
      setSelectedList(selecectedListShowrooms);
    }
  }, [editMicrositeMode,isCreateMode, selecectedListShowrooms]);

  useEffect(() => {
    if (editMicrositeMode||isCreateMode) {
      setChecked(true)
      if (selecectedListShowrooms.length < 2) {
        setisShowroom(true);
      }
    }
  }, [editMicrositeMode,isCreateMode, selecectedListShowrooms]);

  useEffect(() => {
  }, [selectedList])
  const itemsRemodeling = (searchedItems, newItemsData) => {
    handlePageSegmentationUpdate(0);
    if (itemsData) {
      setItemsData(
        newItemsData
          ?.filter(item =>
            (item?.tags && item?.tags?.includes(searchedItems)) ||
            item?.name?.toLowerCase().includes(searchedItems?.toLowerCase())
          )
          .reverse()
      );
    }
    if (searchedItems === "") {
      setItemsData([...items].reverse());
    }
    setIsSearching(false)
  }; 


  const debouncedSearchHandler = debounce((searchValue, itemsData) => {
    setIsSearching(true);
    itemsRemodeling(searchValue, itemsData);
  }, 300);

   maxPerPage = maxPerPage || itemsData?.length;
  const [paginationPointers, setPaginationPointers] = useState({
    start: 0,
    end: maxPerPage,
  });
  const [selectedPage, setSelectedPage] = useState(0);
  const numberOfPages = Math.ceil(itemsData?.length / maxPerPage) || 1;
  const [paginationSegment, setPaginationSegment] = useState([]);
  useEffect(() => {
    const initialArraySeg = [
      1,
      2,
    ];
    setPaginationSegment(initialArraySeg);
  }, [numberOfPages]);

  const handlePageSegmentationUpdate = i => {
    if (i === 0) {
      setPaginationSegment([1, 2]);
    } else if (i === numberOfPages - 1) {
      setPaginationSegment([
        numberOfPages - 3,
        numberOfPages - 2,
      ]);
    } else {
      let defaultVal = [i - 1, i, i + 1];
      if (i === 1) defaultVal = [i, i + 1, i + 2];
      if (i === numberOfPages - 2) defaultVal = [i - 2, i - 1, i];
      const newSegmentation = defaultVal;
      setPaginationSegment(newSegmentation);
    }
    handlePageChange(i);
  };
  const paginationHandler = (action, i) => {
    handlePageSegmentationUpdate(i);
  };

  const handlePageChange = useCallback((page) => {
   if (itemsData?.length>0 ) {
    setSelectedPage(page);
    setPaginationPointers({
      start: (page + 1) * maxPerPage - maxPerPage,
      end: (page + 1) * maxPerPage,
    });
   }
    
  }, [maxPerPage, itemsData]);

  const paginationElementsCreator = () => {
    if (numberOfPages < 4) {
      return [...Array(numberOfPages).keys()].map(i => (
        <li
          className={
            selectedPage === i ? "pagination-item selected" : "pagination-item"
          }
          key={i}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </li>
      ));
    } else {
      return (
        <>
          <li
            className={
              selectedPage === 0
                ? "pagination-item selected"
                : "pagination-item"
            }
            key={0}
            onClick={() => {
              handlePageSegmentationUpdate(0);
            }}
          >
            1
          </li>
          {selectedPage !== 0 && <li className="unclickable-dots">...</li>}
          {paginationSegment.filter((i) => i !== 0 && i !== numberOfPages - 1).map(i => (
            <li
              className={
                selectedPage === i
                  ? "pagination-item selected"
                  : "pagination-item"
              }
              key={i}
              onClick={
                i === paginationSegment[0] ||
                  i === paginationSegment[paginationSegment.length - 1]
                  ? () => {
                    handlePageSegmentationUpdate(i);
                  }
                  : () => handlePageChange(i)
              }
            >
              {i + 1}
            </li>
          ))}
          {selectedPage !== numberOfPages - 1 && <li className="unclickable-dots">...</li>}
          <li
            className={
              selectedPage === numberOfPages - 1
                ? "pagination-item selected"
                : "pagination-item"
            }
            key={numberOfPages - 1}
            onClick={() => handlePageSegmentationUpdate(numberOfPages - 1)}
          >
            {numberOfPages}
          </li>
        </>
      );
    }
  };

  useEffect(() => {
    if(!itemsData.slice(paginationPointers.start, paginationPointers.end).length) {
      handlePageChange(numberOfPages - 1);
    }
  }, [itemsData, handlePageChange, numberOfPages, paginationPointers, paginationPointers.start])

  // end of pagination logic

  useEffect(() => {
    if (ShowRoomIds !== null && ShowRoomIds !== undefined) {
      setShowRoomIds(ShowRoomIds);
    }
    if (checking) {
      setChecked(checking);
    }
  }, [selectedCoverPage, ShowRoomIds, checking]);

  const templateType = tempId => {
    const templateIds = [1,2,3,4,12,13,14,15]
    const expandableIds = [5,6,7]
    const verticalIds = [16,17]

    if (templateIds.includes(tempId)) {
      return "template"
    }
    if (expandableIds.includes(tempId)) {
      return "expandable"
    }
    if (verticalIds.includes(tempId)) {
      return "vertical"
    }
    else {
      return "portrait"
    }
  }
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const initialOpenSettingsStates = new Array(itemsData.length).fill(false);
  const [openSettingsStates, setOpenSettingsStates] = useState(initialOpenSettingsStates);
  const handleToggleSettings = (index) => {
    if (selectedItemIndex === index) {
      openSettingsStates[index] = !openSettingsStates[index];
    } else {
      if (selectedItemIndex !== null) {
        openSettingsStates[selectedItemIndex] = false;
      }
      openSettingsStates[index] = true;
    }
  
    setSelectedItemIndex(openSettingsStates[index] ? index : null);
    setOpenSettingsStates([...openSettingsStates]);
  };

  const switchImage = tempId => {
    switch (tempId) {
      case 1:
        return "https://cdn.cf.masterwizr.com/Frame_20496_gwc2et.jpg";
      case 2:
        return "https://cdn.cf.masterwizr.com/Frame_20497_vdwdlz.jpg";
      case 3:
        return "https://cdn.cf.masterwizr.com/Frame_20498_o9onka.jpg";
      case 4:
        return "https://cdn.cf.masterwizr.com/Frame_20499_xasmzz.jpg";
      case 5:
        return "https://cdn.cf.masterwizr.com/Frame_20500_dljnrn.jpg";
      case 6:
        return "https://cdn.cf.masterwizr.com/Frame_20501_anlzky.jpg";
      case 7:
        return "https://cdn.cf.masterwizr.com/Frame_20502_rpkbbe.jpg";

      default:
        break;
    }
  };
  const switchRoom = tempId => {
    switch (tempId) {
      case 1:
        return template1;
      case 2:
        return template2;
      case 3:
        return template3;
      case 4:
        return template4;
      case 5:
        return template5;
      case 6:
        return template6;
      case 7:
        return expandable3;
      case 8:
        return template8;
      case 9:
        return template9;
      case 10:
        return template10;
      case 11:
        return template11;
      default:
        break;
    }
  };

  function hideOptions() {
    setOptionsPopup(false);
  }

  const selectCoverPage = (template, id) => {
    setSelectedCoverPageTemplate(template);
    setSelectedCoverPage(id);
  };

  const showroomImage = id => {
    for (let i = 0; i < itemsData?.length; i++) {
      if (itemsData[i]?._id === id) {
        for (
          let j = 0;
          j < micrositeShowrooms?.length;
          j++
        ) {
          if (
            itemsData[i].showroomsIds[0] === micrositeShowrooms[j]?._id
          ) {
            return micrositeShowrooms[j]?.content[0]?.content[0]?.image;
          }
        }
      }
    }
    return;
  };

  const showroomImageData = id => {
    for (let i = 0; i < itemsData?.length; i++) {
      if (itemsData[i]?._id === id) {
        for (
          let j = 0;
          j < micrositeShowrooms?.length;
          j++
        ) {
          if (
            itemsData[i].showroomsIds[0] === micrositeShowrooms[j]?._id
          ) {
            return micrositeShowrooms[j];
          }
        }
      }
    }
    return;
  };

  function saveMicrositeChanges(){
    if (selectedList.length < 2 && !isShowroom) {
      showErrorToast("Please select 2 or more showrooms!");
    } else {
      const microsite = {
        _id: "631659341482c82251a6dff7",
        name: "edit",
        showroomsIds: selectedList || [],
        coverpageId: "507f191e810c19729de860ea",
        templateId: 0,
        portalId: "507f191e810c19729de860ea",
      };
      editMicroste(microsite);
    }
  }
  const toggleMenu = () => {
    selectData({ ...sidebar, show: true }, "Sidebar");
  };
  const hasTier = useHasModuleAccess()[2];
  function handleCreationMicrosite(){
    checkLicense();
    modals?.tour && setCurrentStep(currentStep+1);
  }
 



  const handleClick = () => {
    navigation('/showrooms', { replace: true, state: { fromHandleClick: true } });
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (location.pathname === '/showrooms' && location.state?.fromHandleClick) {
      setOptionsPopup(true);
    }
  }, [location.pathname, location.state]);

  return (
    <ListWrapper>
      {isDeleting && <IsLoading isContent/>}
      {!location.pathname.includes("/cover-pages") && !editMicrositeMode && !isCreateMode && !unAuthorized && portalRole!=="PUBLISHER" && !check &&
      showroomsData?.data?.showroomsByPortalId?.showrooms?.length>0 && (
      <button className="micrositeBtn" onClick={handleClick}> Create Microsite </button>
      )
    }
      {optionsPopup && (
       <ImgModal  extraClass={"microsite-create-modal"} hide={hideOptions} state={optionsPopup}>
        {!roomRadioOption ? 
        (<div className="default-case">
            <img src={microsite2} alt="microsite" className="micrositedemoimage" />
         </div>)
         : roomRadioOption === '1+1' ? (
          <div className="microsite-cases">
            <img src={covermicro} alt="microsite" className="micrositedemoimage" />
            <img src={showroomsmicro1} alt="microsite" className="micrositedemoimage" />
          </div>
        )
        : roomRadioOption === '2+7' ? (
          <div className="microsite-cases">
            <img src={covermicro} alt="microsite" className="micrositedemoimage" />
            <img src={showroomsmicro2} alt="microsite" className="micrositedemoimage" />
          </div>
        ) : null}
          <h1>{roomRadioOption === '2+7' ? `Microsite I` : `Microsite II`}</h1>
       <div className="options-container">
       <p>{roomRadioOption === '2+7' ? `You may want to only start with one Show Room and later add more Show Rooms. This option let you start with less contents. `:`You may want to only start with one Show Room and later add more Show Rooms. This option let you start with less contents. `}</p>
         <div style={{marginTop:"20px"}} className="options-container-div">
         <label onClick={() => setisShowroom(false)}>
           {items?.length > 1 ?
             <>
               <input checked={roomRadioOption==="2+7" ? true : false}  type="radio" name="radio" onChange={(e) => e.target.checked && setRoomRadioOption('2+7')} />
              <span style={{fontWeight:`${roomRadioOption==="2+7" ? "bold" : ""}`}}>Option 1 </span>   ( 2-7 Show Rooms + 1 Cover Page)
             </> : <>
               <input disabled type="radio" name="radio" />
               Please create 2 or more Show Rooms to be able to select the "2-7 Show Rooms (Microsite) + 1 Cover Page" mode
             </>
           }
         </label>
         <label onClick={() => setisShowroom(true)}>
           <input checked={roomRadioOption==="1+1" ? true : false}  type="radio" name="radio" onChange={(e) => e.target.checked && setRoomRadioOption('1+1')} /><span style={{fontWeight:`${roomRadioOption==="1+1" ? "bold" : ""}`}}>Option 2 </span>  (1 Show Room + 1 Cover Page)
         </label>
        </div>
       </div>
       <div className="buttons">
       <button
       style={{color:"white", opacity: 1}}
         onClick={() => {setOptionsPopup(false);setIsOpen(false)}}
         >
          Cancel
         </button>
         <button
           onClick={() => {
             setChecked(!check);
             setOptionsPopup(false);
             modals?.tour && setCurrentStep(currentStep+1);
             toggleMenu();
           }}
           style={{fontWeight: "bold", opacity: 1}}
         >
           Continue
         </button>
       </div>
     </ImgModal>
      )}
      <StickyNav />

      <MainWrapper columns>
        <Banner
          isList={isList}
          title={title || "Set up your Show Room"}
          subtitle={subtitle}
          logo={logo}
          top={top}
          logosvg={logosvg}
          bannerImg={
            bannerImg ||
            "https://master-wizr-uploads.s3.amazonaws.com/studio/covert2.png"
          }
        >  <div className="search-banner-content">
        <SearchIcon />
        <input
          placeholder={t("search")}
          onChange={e => debouncedSearchHandler(e.target.value, itemsData)}
        />
      </div> </Banner>
        {itemsData && itemsData.length === 0 && !isLoading ? (
          <EmptyList />
        ) : (
          <>
          {/* {!location.pathname.includes("/cover-pages")  && !editMicrositeMode && !isCreateMode && !unAuthorized && portalRole!=="PUBLISHER" && !check && (
           <div className="linkButton">
                <button className="link"
                  onClick={() => {
                   !hasTier && micrositesArr.length>=2 ? 
                   setLimitModal(true)
                   :
                   handleCreationMicrosite();
                  }}
                >
                  <Link />
                  <h2 className="tooltip2">Create Microsite</h2>
                </button>
            </div>
            )
          } */}
            {showLicensePopup && <UpgradePoup handleClose={() => setShowLicensePopup(false)}/>}
            {check && location.pathname.includes("showrooms") ? (
              <h2 className="micro-type">
                {isShowroom
                  ? "Choose only one Show Room"
                  : "Custom Microsite - Choose min 2 and max 7 Show Rooms"}
              </h2>
            ) : (
              ""
            )}
            {location.pathname.includes("/cover-pages") && check && (
              <h1 className="choose-coverpage">Choose only one Cover Page</h1>
            )}
            {!isSearching && <CardWrapper className="list-content-wrapper">
              {isLoading ? (
                <></>
              ) : (
                itemsData &&
                itemsData
                  .slice(paginationPointers.start, paginationPointers.end)
                  .map((item, index) => (
                    <ListImageComponent
                      itemsData={itemsData}
                      check={check}
                      deleteFunc={() => deleteFunc(item._id)}
                      item={item}
                      openSettings={openSettingsStates[index]}
                      toggleSettings={() => handleToggleSettings(index)}
                      typeOfCard={templateType(item.templateId)}
                      micrositeTypeOfCard={templateType}
                      handleEdit={handleEdit}
                      showrooms={showrooms}
                      selectedAsCoverPage={isInStepOne}
                      setSelectedCoverPage={setSelectedCoverPage}
                      setSelectedCoverPageTemplate={
                        setSelectedCoverPageTemplate
                      }
                      selectedCoverPageTemplate={selectedCoverPageTemplate}
                      selectedCoverPage={selectedCoverPage}
                      name={item.name}
                      tags={item.tags}
                      tag={item.tag}
                      key={item._id + item.name}
                      id={item._id}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                      mainHeading={item.mainHeading}
                      subHeading={item.subHeading}
                      sharingUrl={
                        item?.content
                          ? `/showrooms/${item._id}`
                          : `/coverpage-detailed/${item.templateId}?id=${item._id}`
                      }
                      url={
                        microsite
                          ? showroomImage(item?._id)
                          : window.location.pathname.includes("showrooms") || window.location.pathname.includes("edit-microsite")
                            ? switchRoom(item?.templateId)
                            : item.thumbnail ? STATIC_URL + item.thumbnail : switchImage(item?.templateId)
                      }
                      micrositeData = {showroomImageData(item?._id)}
                      microsite={microsite}
                      isShowroom={isShowroom}
                      unAuthorized={unAuthorized}
                      onClick={() => {
                        !microsite
                          ? !isInStepOne
                            ? item?.content
                              ? navigation(`/showrooms/${item._id}`, {
                                state: { alreadyExists: true },
                              })
                              : navigation(
                                `/coverpage-detailed/${item.templateId}?id=${item._id}`
                              )
                            : selectCoverPage(item.templateId, item._id)
                          : navigation(
                            `/microsite/${item.showroomsIds.toString()}/${item.templateId
                            }?id=${item.coverpageId}&micrositeid=${item._id}`
                          );
                      }}
                    />
                  ))
              )}
            </CardWrapper>}
            {maxPerPage < itemsData?.length ? (
              <div className="pagination-wrapper">
                <button
                  className={
                    selectedPage !== 0
                      ? "pagination-button"
                      : "pagination-button unallowed"
                  }
                  onClick={
                    selectedPage !== 0
                      ? () => paginationHandler("prev", selectedPage - 1)
                      : null
                  }
                >
                  {`<`}
                </button>
                <ul className="pagination-list">
                  {paginationElementsCreator()}
                </ul>
                <button
                  className={
                    selectedPage !== numberOfPages - 1
                      ? "pagination-button"
                      : "pagination-button unallowed"
                  }
                  onClick={
                    selectedPage !== numberOfPages - 1
                      ? () => paginationHandler("next", selectedPage + 1)
                      : null
                  }
                >
                  {`>`}
                </button>
              </div>
            ) : null}
          </>
        )}
        {check && !isInStepOne && (
          <div
            className={
              itemsData?.length < 6 ? "bottom-btn absolute microsite-actions" : "bottom-btn microsite-actions"
            }
          >
            {/* isShowroom ? "/1" : "/2" */}
            <CancelButton handleCancel={() => {
              setChecked(false)
              setSelectedList([])
              if(location.pathname.includes('edit-microsite')){
                navigation(-1)
              }
              setIsOpen(false);
            }} />
            {isShowroom && selectedList?.length > 0 && !editMicrositeMode && !isCreateMode? (
              <button
                className="cover-button"
                onClick={() => {
                  navigation(`/cover-pages/${selectedList.toString()}/2/null/null`);
                }}
              >
                Select Coverpage
              </button>
            ) : selectedList?.length > 1 && selectedList?.length < 8 && !editMicrositeMode && !isCreateMode ? (
              <button
                className="cover-button"
                onClick={() => {
                  navigation(
                    `/cover-pages/${selectedList.toString()}${isShowroom ? "/1" : "/2/null/null"
                    }`
                  );
                }}
              >
                 Select Coverpage
              </button>
            ) : (
              ""
            )}
            {selectedList?.length > 0 && !editMicrositeMode && !isCreateMode && (
              <button
                className="skip-button"
                onClick={() => {
                  navigation(`/microsite/${selectedList.toString()}/null`);
                  setCurrentStep(steps.length-1);
                }}
              >
                {" "}
                 Skip and Continue
              </button>
            )}
            {(editMicrositeMode||isCreateMode) && (
              <button className="cover-button"
                onClick={() => saveMicrositeChanges()}
              >
                Save Changes
              </button>
            )}
          </div>
        )}
        {isInStepOne && !editMicrositeCoverpageMode && !selectedCoverPage && (
        <div
          className={
            itemsData?.length < 6 ? "bottom-btn absolute microsite-actions" : "bottom-btn microsite-actions"
          }
        >
          <CancelButton handleCancel={() => {
            navigation(`/showrooms`);
          }} />
        </div>
        )}
        {isInStepOne && selectedCoverPage !== null ? (
          !editMicrositeCoverpageMode ?
            <div
              className={
                itemsData?.length < 6 ? "bottom-btn absolute microsite-actions" : "bottom-btn microsite-actions"
              }
            >
              <CancelButton handleCancel={() => {
                navigation(`/showrooms`);
              }} />
              <button className="skip-button finish-select"
                onClick={() => {
                  mode.toString() === "2"
                    ? navigation(
                      `/microsite/${showRoomIds.toString()}/${selectedCoverPageTemplate}?id=${selectedCoverPage}`
                    )
                    : navigation(
                      `/preview-cover/${selectedCoverPageTemplate}?id=${selectedCoverPage}&showroomid=${showRoomIds.toString()}`
                    ); setIsOpen(false)
                }}
              >
                Finish selecting
              </button>
            </div> :
            <div
              className={
                itemsData?.length < 6 ? "bottom-btn absolute microsite-actions" : "bottom-btn microsite-actions"
              }
            >
              <CancelButton handleCancel={() => {
                navigation(-1);
              }} />
              <button 
                className="skip-button finish-select" 
                onClick={() => changeCoverpageFunc(selectedCoverPage, selectedCoverPageTemplate)}
              >
                Save changes
              </button>
            </div>
        ) : (
          check &&
          selectedCoverPage !== null && (
            <div className="bottom-btn">
              <button onClick={() => setFinish(true)} className="skip-button">
                FINISH
              </button>
            </div>
          )
        )}
      </MainWrapper>

      <ImgModal state={finish} hide={finish}>
        <div className="center-content">
          <p className="title">{`Your ${title || "Show Room"
            } is almost done`}</p>
          <div className="text name">
            <Title>Name</Title>
            <Input
              type="text"
              name="link"
            // onChange={onChangeMetaData("name")}
            />
          </div>
          {title === "Cover Page" ? (
            <></>
          ) : (
            <>
              <div className="input-text name tags">
                <p>Tags</p>
                {/* <Tags
                    // tag={tag}
                    // tags={tags}
                    // handleRemoveTag={handleRemoveTag}
                    // handleChangeTag={handleChangeTag}
                    // handleKeyDown={handleKeyDown}
                  /> */}
              </div>

              <div className="input-text name">
                <p>Links</p>
                <Input
                  type="text"
                  name="link"
                // onChange={onChangeMetaData("link")}
                />
                <button>Copy</button>
              </div>
            </>
          )}
          <div className="buttons">
            <button onClick={() => setFinish(false)} className="finish">
              Cancel
            </button>
            <button
              // onClick={() => {
              //   // handleSaveCoverpage();
              //   // // Add toast for all scenarios.
              //   hidePopup();
              // }}
              onClick={() => setFinish(false)}
              className="finish"
            >
              Done
            </button>
          </div>
        </div>
      </ImgModal>
      {limitModal &&
      <LimitModal close={()=>setLimitModal(false)}/>
      }
    </ListWrapper>
  );
}

export default List;
