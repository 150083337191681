/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetMicrositeQueryVariables = {|
  micrositeId: string
|};
export type GetMicrositeQueryResponse = {|
  +microsite: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +showroomsIds?: ?$ReadOnlyArray<string>,
    +tags?: ?$ReadOnlyArray<string>,
    +backgroundImage?: ?string,
    +coverpageId?: ?string,
    +templateId?: ?number,
    +portalId?: string,
    +creator?: string,
    +accountId?: string,
  |}
|};
export type GetMicrositeQuery = {|
  variables: GetMicrositeQueryVariables,
  response: GetMicrositeQueryResponse,
|};
*/


/*
query GetMicrositeQuery(
  $micrositeId: ID!
) {
  microsite(id: $micrositeId) {
    __typename
    ... on Microsite {
      _id
      id
      name
      showroomsIds
      tags
      backgroundImage
      coverpageId
      templateId
      portalId
      creator
      accountId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "micrositeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "micrositeId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showroomsIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverpageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    }
  ],
  "type": "Microsite",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetMicrositeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "microsite",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetMicrositeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "microsite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1269e2e2d188a69199fc989daf72d77b",
    "id": null,
    "metadata": {},
    "name": "GetMicrositeQuery",
    "operationKind": "query",
    "text": "query GetMicrositeQuery(\n  $micrositeId: ID!\n) {\n  microsite(id: $micrositeId) {\n    __typename\n    ... on Microsite {\n      _id\n      id\n      name\n      showroomsIds\n      tags\n      backgroundImage\n      coverpageId\n      templateId\n      portalId\n      creator\n      accountId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '424d17985dd3c4ecd43f7fd770c5ac0a';

module.exports = node;
