import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.scss";
import FAQ from "components/ui/molecules/FAQ/FAQ";
import HomeCards from "components/ui/molecules/HomeCards/HomeCards";
import LeftRight from "components/ui/atoms/LeftRight/LeftRight";
import { homeNavigation, homeSections } from "constants/home";
import BannerNavigation from "components/ui/molecules/BannerNavigation/BannerNavigation";
import { useTranslation } from "react-i18next";

export default function Home() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <div>
      <BannerNavigation data={homeNavigation} />
      <div className={styles.topBanner}>
        <h1 className={styles.title}>{t("present-like-never-before")}</h1>
      </div>
      <HomeCards />
      {homeSections.slice(0, 3).map((d, i) => {
        return <LeftRight key={i} {...d} />;
      })}
      <div className={styles.middleSection}>
        <div className={styles.descSection}>
          <h1 className={styles.title}>
            {t("elevating")} <br /> {t("presentations")}
          </h1>
          <p className={styles.desc}>
            {t("studio-is-not-just-about-features")}
          </p>
          <button
            onClick={() => navigate(`/showroom-templates`)}
            className={styles.btn}
          >
            {t("create-show-rooms")}
          </button>
        </div>
        {homeSections.slice(3).map((d, i) => {
          return <LeftRight key={i} {...d} />;
        })}
        <FAQ />
      </div>
    </div>
  );
}
