import graphql from "babel-plugin-relay/macro";

const editAccount = graphql`
  mutation editAccountMutation($updates: editAccount) {
    editAccount(updates: $updates) {
      __typename
      message
      status
      success
    }
  }
`;

export default editAccount;
