
import React, { useEffect, useRef, useState } from "react";
import ImgModal from "../ImgModal/ImgModal";
import classes from "./ShareModal.module.scss";

function ShareableLink({ setShareableLink, shareableLink }) {
  const [buttonText, setButtonText] = useState("Copy Link");
  const inputRef = useRef(null);
  function copyUrl(e) {
    inputRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setButtonText("Copied");
  }

  const keybEvents = event => {
    if (event.key === "Enter") {
      setShareableLink(null);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keybEvents);
    return () => {
      document.removeEventListener("keydown", keybEvents);
    };
  }, []);

  return (
    <>
      <ImgModal
        handleClose={() => setShareableLink(null)}
        showCloseIcon
        state={true}
        extraClass={"sharingpart"}
      >
        <p style={{ marginBottom: "20px" }}>Sharable link </p>
        <div className={`${classes.inputbutton} input-copylink`}>
          <input
            ref={inputRef}
            className="inputGroup"
            value={shareableLink ? shareableLink : ""}
            type="text"
          />

          <p onClick={copyUrl}>{buttonText}</p>
        </div>
        <button onClick={() => setShareableLink(null)} className="sharevia-btn">
          Done
        </button>
      </ImgModal>
    </>
  );
}
export default ShareableLink;
