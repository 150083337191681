import classes from "./customeModal.module.scss";
import { ReactComponent as Close } from "assets/images/close.svg";
import StyledButton from "../../Buttons/StyledButton";

function CustomeModel({
  handleClose,
  handleSubmit,
  showSubmitButton,
  showCloseButton,
  showCloseIcon,
  children,
}) {
  return (
    <div className={`${classes.shareContainer} share-container`}>
      <div className={classes["backdrop"]}></div>
      <div className={`${classes.shareFrame} frame-theme`} >
        <div>
          {showCloseIcon && (
            <Close className={classes["close"]} onClick={handleClose} />
          )}

          {children}
          {showCloseButton && (
            <div className={classes["close-button-container"]}>
              <StyledButton clickEvent={handleClose} cancelbutton={"true"}>
                Close
              </StyledButton>
            </div>
          )}
          {showSubmitButton && (
            <div className={classes["submit-button-container"]}>
              <StyledButton clickEvent={handleSubmit} submitbutton={"true"}>
                Submit
              </StyledButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default CustomeModel;
