/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type presentationUpdateMutationVariables = {|
  presentationId: string,
  folderId: string,
  portalId: string,
  menuId: string,
  name: string,
  coverUrl?: ?string,
  showFileNames?: ?boolean,
|};
export type presentationUpdateMutationResponse = {|
  +editPresentation: ?{|
    +status: number,
    +message: string,
    +success: boolean,
    +__typename: string,
  |}
|};
export type presentationUpdateMutation = {|
  variables: presentationUpdateMutationVariables,
  response: presentationUpdateMutationResponse,
|};
*/


/*
mutation presentationUpdateMutation(
  $presentationId: ID!
  $folderId: ID!
  $portalId: ID!
  $menuId: ID!
  $name: String!
  $coverUrl: String
  $showFileNames: Boolean
) {
  editPresentation(presentationId: $presentationId, folderId: $folderId, portalId: $portalId, menuId: $menuId, name: $name, coverUrl: $coverUrl, showFileNames: $showFileNames) {
    status
    message
    success
    __typename
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverUrl"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folderId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "presentationId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showFileNames"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "coverUrl",
        "variableName": "coverUrl"
      },
      {
        "kind": "Variable",
        "name": "folderId",
        "variableName": "folderId"
      },
      {
        "kind": "Variable",
        "name": "menuId",
        "variableName": "menuId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "portalId",
        "variableName": "portalId"
      },
      {
        "kind": "Variable",
        "name": "presentationId",
        "variableName": "presentationId"
      },
      {
        "kind": "Variable",
        "name": "showFileNames",
        "variableName": "showFileNames"
      }
    ],
    "concreteType": "Response",
    "kind": "LinkedField",
    "name": "editPresentation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "presentationUpdateMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "presentationUpdateMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "ba9ec5004c140c2a3fb4939c60d8a219",
    "id": null,
    "metadata": {},
    "name": "presentationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation presentationUpdateMutation(\n  $presentationId: ID!\n  $folderId: ID!\n  $portalId: ID!\n  $menuId: ID!\n  $name: String!\n  $coverUrl: String\n  $showFileNames: Boolean\n) {\n  editPresentation(presentationId: $presentationId, folderId: $folderId, portalId: $portalId, menuId: $menuId, name: $name, coverUrl: $coverUrl, showFileNames: $showFileNames) {\n    status\n    message\n    success\n    __typename\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6461da63ca06cf3759e41f83792efae7';

module.exports = node;
