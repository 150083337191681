import { Link } from 'react-router-dom';
import styles from './Tabs.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

function Tabs({ data }) {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      {data?.map((item) => {
        return item.isButton ? (
          <button
            className={item?.isActive ? styles.selected : ''}
            key={item.title}
            onClick={item.onClick}
          >
            {t(item?.title)}
          </button>
        ) : (
          <Link
            className={window.location.pathname === item?.path ? styles.selected : ''}
            key={item.title}
            to={item?.path}
          >
            {t(item?.title)}
          </Link>
        );
      })}
    </div>
  );
}

export default Tabs;

Tabs.propTypes = {
  data: PropTypes.array,
  state: PropTypes.bool,
  setState: PropTypes.func
};
