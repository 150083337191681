import StickyNav from "containers/NavBar";
import React from "react";
import CoverpagesTemplates from "components/ui/organisms/CoverPages";

function CoverPagesRoute(){
    return(
        <>
        <StickyNav/>
        <CoverpagesTemplates />
        </>
    );
}
export default CoverPagesRoute;