import Tabs from 'components/ui/atoms/Tabs/Tabs';
import styles from './BannerNavigation.module.scss';

function BannerNavigation({ data }) {
    if (data && data.length <= 1) return null;
    return (
        <div className={styles.container}>
            <Tabs {...{ data }} />
        </div>
    );
}
export default BannerNavigation;
