import graphql from "babel-plugin-relay/macro";

const ShowroomsByPortalQuery = graphql`
  query ShowroomsByPortalIdQuery($portalId: ID!) {
    showroomsByPortalId(portalId: $portalId) {
      ... on ListOfShowrooms {
        showrooms {
          __typename
          _id
          id
          name
          mainHeading
          headerImage
          backgroundImage
          subHeading
          tags
          templateId
          portalId
          content {
            name
               content {
                 image
                 mainHeading
                 presentationId
                 subHeading
                 imageUrl
                 presentation {
                  coverUrl
                  portalId
                  name
                  menuId
                  folderId
                }
                 files {
                   _id
                   name
                   relatedPresentationIds
                   fileUrl
                   fileType
                   coverUrl
                   thumbnailUrl
                   visibility
                   creator
                 }
               }
          }
        }
      }
    }
  }
`;

export default ShowroomsByPortalQuery;
