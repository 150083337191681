
export const showroomTemplates = [
  { id: 1, url: "https://cdn.cf.masterwizr.com/Group_40582_m4wkth.png", nrOfRows: 1, numberOfItems: 2 },
  { id: 2, url: "https://cdn.cf.masterwizr.com/Group_40583_ntfzck.png", nrOfRows: 1, numberOfItems: 3 },
  { id: 3, url: "https://cdn.cf.masterwizr.com/Group_40584_tmf4rt.png", nrOfRows: 2, numberOfItems: 3 },
  { id: 4, url: "https://cdn.cf.masterwizr.com/Group_40576_v1cde6.png", nrOfRows: 3, numberOfItems: 4 },
  { id: 8, url: "https://cdn.cf.masterwizr.com/Group_40573_ugmv6g.png", nrOfRows: 1, numberOfItems: 2 },
  { id: 9, url: "https://cdn.cf.masterwizr.com/Group_40588_y7ojrv.png", nrOfRows: 1, numberOfItems: 3 },
  { id: 10, url: "https://cdn.cf.masterwizr.com/Group_40575_cvoz7w.png", nrOfRows: 1, numberOfItems: 4 },
  { id: 11, url: "https://cdn.cf.masterwizr.com/Group_40581_jirh4b.png", nrOfRows: 2, numberOfItems: 3 },
  {
    id: 5,
    url: "https://cdn.cf.masterwizr.com/Group_40585_thicvt.png",
    numberOfItems: 2,
    headerImage:"https://cdn.cf.masterwizr.com/Frame_40904_1_1_1_dxhyuv.png",
    nrOfRows: 1,
  },
  {
    id: 6,
    url: "https://cdn.cf.masterwizr.com/Group_40586_s7yowe.png",
    numberOfItems: 3,
    headerImage:"https://cdn.cf.masterwizr.com/Frame_40904_1_1_1_dxhyuv.png",
    nrOfRows: 1,
  },
  {
    id: 7,
    url: "https://cdn.cf.masterwizr.com/Group_40587_qwdfmh.png",
    numberOfItems: 5,
    headerImage: "https://cdn.cf.masterwizr.com/Frame_40904_1_1_1_dxhyuv.png",
    nrOfRows: 1,
  },
  { id: 12, url: "https://cdn.cf.masterwizr.com/Group_1321314336_ivqyts.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 13, url: "https://cdn.cf.masterwizr.com/Group_1321314337_qs54is.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 14, url: "https://cdn.cf.masterwizr.com/Group_1321314338_ipbmt7.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 15, url: "https://cdn.cf.masterwizr.com/Group_1321314339_sndjyj.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 16, url: "https://cdn.cf.masterwizr.com/Group_1321314342_mfvsnr.png", nrOfRows: 1, numberOfItems: 1 }, 
];
