import graphql from "babel-plugin-relay/macro";

const SharedItemQuery = graphql`
  query GetSharedItemBySlugQuery($slug: String!, $securityPass: String) {
    getSharedStudioItemBySlug(slug: $slug, securityPass: $securityPass) {
      ... on SharedStudioItemResponse {
        content {
          ... on Coverpage {
            __typename
            _id
            name
            mainHeading
            details
            coverpageTemplateId: templateId
            thumbnail
            showroomIds
            showroomLink
            creator
            coverpageAccountId: accountId
            templateStyle {
              cardPosition
              BackgroundBlur
            }
            updatedAt
          }

          ... on Microsite {
            __typename
            _id
            name
            showroomsIds
            backgroundImage
            coverpageId
            micrositeTemplateId: templateId
            portalId
            creator
            micrositeAccountId: accountId
          }

          ... on Magazine {
            __typename
            _id
            id
            name
            backgroundColor
            backgroundImage
            mainTemplateId
            subTemplateId
            portalId
            rows {
              content {
                heading
                subHeading
                description
                headerImage
                fileUrl
                videoThumbnail
                cardThumbnail
                headerOverlayContent
                linkTo
                buttonText
                graphData {
                  attr1
                  attr2
                  attr3
                  data {
                    name
                    val1
                    val2
                    val3
                  }
                }
              }
              isShown
            }
            thumbnail
            creator
            magazineAccountId: accountId
          }

          ... on Showroom {
            __typename
            _id
            id
            name
            backgroundImage
            mainHeading
            headerImage
            subHeading
            showroomTemplateId: templateId
            tags
            link
            creator
            coverpagesIds
            showroomAccountId: accountId
            content {
              name
              content {
                image
                mainHeading
                presentationId
                subHeading
                imageUrl
                presentation {
                  coverUrl
                  portalId
                  name
                  menuId
                  folderId
                }
                files {
                  _id
                  name
                  relatedPresentationIds
                  fileUrl
                  fileType
                  coverUrl
                  thumbnailUrl
                  positions {
                    presentationId
                    position
                  }
                  visibility
                  creator
                }
              }
            }
          }
        }
      }

      ... on Response {
        __typename
        message
        status
        success
      }
    }
  }
`;
export default SharedItemQuery;
