/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STUDIO_SHARING_ITEMS_NAME = "coverpage" | "magazine" | "microsite" | "showroom" | "%future added value";
export type sharedStudioItemInput = {|
  accountId: string,
  portalId: string,
  emails?: ?$ReadOnlyArray<string>,
  itemId: string,
  securityPass?: ?string,
  itemName: STUDIO_SHARING_ITEMS_NAME,
  activeStartDate?: ?any,
  activeEndDate?: ?any,
|};
export type CreateShareItemMutationVariables = {|
  values: sharedStudioItemInput
|};
export type CreateShareItemMutationResponse = {|
  +createShareStudioItem: {|
    +accountId: string,
    +creator: string,
    +itemId: string,
    +slug: string,
    +itemName: STUDIO_SHARING_ITEMS_NAME,
    +__typename: string,
  |}
|};
export type CreateShareItemMutation = {|
  variables: CreateShareItemMutationVariables,
  response: CreateShareItemMutationResponse,
|};
*/


/*
mutation CreateShareItemMutation(
  $values: sharedStudioItemInput!
) {
  createShareStudioItem(shareContent: $values) {
    accountId
    creator
    itemId
    slug
    itemName
    __typename
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "values"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "shareContent",
        "variableName": "values"
      }
    ],
    "concreteType": "SharedStudioItem",
    "kind": "LinkedField",
    "name": "createShareStudioItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "creator",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateShareItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateShareItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "18aebe2f67b24ea5d0b373af60ae196a",
    "id": null,
    "metadata": {},
    "name": "CreateShareItemMutation",
    "operationKind": "mutation",
    "text": "mutation CreateShareItemMutation(\n  $values: sharedStudioItemInput!\n) {\n  createShareStudioItem(shareContent: $values) {\n    accountId\n    creator\n    itemId\n    slug\n    itemName\n    __typename\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf3dc094a8e1327180eac01f87f8f87f';

module.exports = node;
