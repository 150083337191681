/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editMagazine = {|
  name?: ?string,
  portalId?: ?string,
  backgroundColor?: ?string,
  backgroundImage?: ?string,
  companyLogo?: ?string,
  mainTemplateId?: ?string,
  subTemplateId?: ?number,
  rows?: ?$ReadOnlyArray<?InputRows>,
  thumbnail?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  accountId?: ?string,
|};
export type InputRows = {|
  content?: ?$ReadOnlyArray<?InputCard>,
  isShown?: ?boolean,
|};
export type InputCard = {|
  heading?: ?string,
  subHeading?: ?string,
  description?: ?string,
  headerImage?: ?string,
  fileUrl?: ?string,
  videoThumbnail?: ?string,
  cardThumbnail?: ?string,
  headerOverlayContent?: ?string,
  linkTo?: ?string,
  buttonText?: ?string,
  graphData?: ?InputGraph,
|};
export type InputGraph = {|
  attr1?: ?string,
  attr2?: ?string,
  attr3?: ?string,
  data?: ?$ReadOnlyArray<?InputData>,
|};
export type InputData = {|
  name?: ?string,
  val1?: ?number,
  val2?: ?number,
  val3?: ?number,
|};
export type EditMagazineMutationVariables = {|
  editMagazineId: string,
  magazine?: ?editMagazine,
|};
export type EditMagazineMutationResponse = {|
  +editMagazine: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +backgroundColor?: ?string,
    +backgroundImage?: ?string,
    +mainTemplateId?: string,
    +subTemplateId?: number,
    +portalId?: string,
    +rows?: ?$ReadOnlyArray<?{|
      +content: ?$ReadOnlyArray<?{|
        +heading: ?string,
        +subHeading: ?string,
        +description: ?string,
        +headerImage: ?string,
        +fileUrl: ?string,
        +videoThumbnail: ?string,
        +cardThumbnail: ?string,
        +headerOverlayContent: ?string,
        +linkTo: ?string,
        +buttonText: ?string,
        +graphData: ?{|
          +attr1: ?string,
          +attr2: ?string,
          +attr3: ?string,
          +data: ?$ReadOnlyArray<?{|
            +name: ?string,
            +val1: ?number,
            +val2: ?number,
            +val3: ?number,
          |}>,
        |},
      |}>,
      +isShown: ?boolean,
    |}>,
    +thumbnail?: ?string,
    +tags?: ?$ReadOnlyArray<?string>,
    +creator?: string,
    +accountId?: ?string,
    +createdAt?: any,
    +updatedAt?: ?any,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +shared?: ?string,
    +hash?: ?string,
    +userId?: ?string,
    +__typename: "Response",
  |}
|};
export type EditMagazineMutation = {|
  variables: EditMagazineMutationVariables,
  response: EditMagazineMutationResponse,
|};
*/


/*
mutation EditMagazineMutation(
  $editMagazineId: ID!
  $magazine: editMagazine
) {
  editMagazine(id: $editMagazineId, magazine: $magazine) {
    __typename
    ... on Magazine {
      _id
      id
      name
      backgroundColor
      backgroundImage
      mainTemplateId
      subTemplateId
      portalId
      rows {
        content {
          heading
          subHeading
          description
          headerImage
          fileUrl
          videoThumbnail
          cardThumbnail
          headerOverlayContent
          linkTo
          buttonText
          graphData {
            attr1
            attr2
            attr3
            data {
              name
              val1
              val2
              val3
            }
          }
        }
        isShown
      }
      thumbnail
      tags
      creator
      accountId
      createdAt
      updatedAt
    }
    ... on Response {
      message
      status
      success
      token
      shared
      hash
      userId
      __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "editMagazineId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "magazine"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "editMagazineId"
  },
  {
    "kind": "Variable",
    "name": "magazine",
    "variableName": "magazine"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainTemplateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subTemplateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Row",
      "kind": "LinkedField",
      "name": "rows",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Card",
          "kind": "LinkedField",
          "name": "content",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heading",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subHeading",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerImage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoThumbnail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cardThumbnail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerOverlayContent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkTo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Graph",
              "kind": "LinkedField",
              "name": "graphData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attr1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attr2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attr3",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphData",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "val1",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "val2",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "val3",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isShown",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Magazine",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shared",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMagazineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editMagazine",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMagazineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editMagazine",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d4b6a04186f3b34ac306020da3ba950",
    "id": null,
    "metadata": {},
    "name": "EditMagazineMutation",
    "operationKind": "mutation",
    "text": "mutation EditMagazineMutation(\n  $editMagazineId: ID!\n  $magazine: editMagazine\n) {\n  editMagazine(id: $editMagazineId, magazine: $magazine) {\n    __typename\n    ... on Magazine {\n      _id\n      id\n      name\n      backgroundColor\n      backgroundImage\n      mainTemplateId\n      subTemplateId\n      portalId\n      rows {\n        content {\n          heading\n          subHeading\n          description\n          headerImage\n          fileUrl\n          videoThumbnail\n          cardThumbnail\n          headerOverlayContent\n          linkTo\n          buttonText\n          graphData {\n            attr1\n            attr2\n            attr3\n            data {\n              name\n              val1\n              val2\n              val3\n            }\n          }\n        }\n        isShown\n      }\n      thumbnail\n      tags\n      creator\n      accountId\n      createdAt\n      updatedAt\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      shared\n      hash\n      userId\n      __typename\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca2461976847d47129ed9121b61002a8';

module.exports = node;
