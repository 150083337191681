import styled from "styled-components";
import Image from "./coverbg2.png";
import { Blur, Wrapper, Title, Details, ShowroomsLink , Scroll} from "./common";
import SharedText from "components/ui/atoms/SharedText/SharedText";
import { mainHeadingInit, mainSubHeadingInit } from "components/ui/atoms/CoverPages/editorSettings";
import { useQuery } from "relay-hooks";
import CoverpageQuery from "graphql/queries/GetCoverpageQuery";
import { useState, useEffect } from "react";
import AddShowroom from "components/ui/atoms/CoverPages/addShowroomPopup";
import { useNavigate } from "react-router-dom";

const CurvedBlur = styled(Blur)`
    height: 100%;
    border-radius: 240px;
    @media screen and (max-height: 950px) {
        width:48%;
    }
    @media screen and (max-height: 720px) {
        width: 60%;
    }
`

const Content = styled(Details)`
    font-size: 14px;
    margin-bottom: 10px;
`

export function CoverPage5({coverpageData, onChangeEditor, backgroundUrl, textColor, isPreview, sharing, handleCheckoutShowrooms, updateCoverpage, disabled, showroomid}){
    const navigation = useNavigate();
    const [showList, setShowList] = useState(false)
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const { data, isLoading } = useQuery(
        CoverpageQuery,
        { coverpageId: id },
        {
          fetchPolicy: "store-and-network",
        }
    );

    useEffect(() => {
        if (id && !isLoading) {
            updateCoverpage(data.coverpage)
        }
    }, [data, isLoading]);

    // const addShowroomIds = (showroomIDs) => {
    //     updateCoverpage({showroomIds: showroomIDs})
    // }
    return(
        <Wrapper pd="4%" rightAlign image={Image} isPreview={(isPreview || sharing)}>
            <CurvedBlur width="36%" vCenter>
               <Scroll>
                <SharedText
                    isPreview={isPreview}
                    title={coverpageData.mainHeading}
                    isSubHead={true}
                    disabled={disabled}
                    description={coverpageData.details}
                    onChangeEditor={onChangeEditor}
                    mainHeadingInit={mainHeadingInit} 
                    subHeadingInit={mainSubHeadingInit}
                />
                {showroomid?<ShowroomsLink onClick={() =>{navigation(`/showrooms/${showroomid}`)}}>Checkout my Show Rooms</ShowroomsLink>:""}
                {/* <AddShowroom setShowList={setShowList} showList={showList} addShowroomIds={addShowroomIds} showroomIds={data ? data.coverpage.showroomIds : coverpageData.showroomIds} /> */}
               </Scroll>
            </CurvedBlur>
        </Wrapper>
    )
}

export default CoverPage5;
