import graphql from "babel-plugin-relay/macro";


const ShowroomsQuery = graphql`
  query GetShowroomsQuery {
    showrooms {
    ... on ListOfShowrooms {
    showrooms {
      _id
      id
      name
      mainHeading
      subHeading
      tags
      headerImage
      templateId
      backgroundImage
      content {
        name
        content {
          image
          mainHeading
          presentationId
          subHeading
          imageUrl
        }
      }
      creator
    }
  }

  ... on Response {
      message
      status
      success
      token
      hash
      signedUrl
      userId
      shared
      }
    }
  }
`;

export default ShowroomsQuery;
