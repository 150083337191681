import styled from 'styled-components';

export const Input = styled.input`
    border: ${props => props.b || 'none'};
    background-color: ${props => props.bg};
    width: ${props => props.width || ''};
    border-radius: ${props => props.br || ''};
    padding: ${props => props.p || ''};
    color: ${props => props.color || 'gray' };
    height: ${props => props.height || ""};

    ::placeholder {
        color:${props => props.color || 'gray' };
        opacity: 0.3;
    }

    :-ms-input-placeholder {
    color:${props => props.color || 'gray' };
    }

    ::-ms-input-placeholder {
    color: ${props => props.color || 'gray' };
    }
    &:focus {
        outline: none;
    }
    @media screen and (min-width: 2560px) {
        height:35px;
    }
    @media screen and (min-width: 3000px) {
        height:45px;
    }
    @media screen and (min-width: 4000px) {
        height:60px;
    }
`
export const Text = styled.span`
    font-size: ${props => props.fSize || '18px'};
    color: ${props => props.color || '#000000'};
    font-weight: ${props => props.bold ? 'bold' : ''};
    margin-left: ${props => props.ml ? props.ml : ''};
    @media screen and (min-width: 2560px) {
        font-size: 20px;
    }
    @media screen and (min-width: 3000px) {
        font-size: 32px;
    }
    @media screen and (min-width: 4000px) {
        font-size: 45px;
    }
`
