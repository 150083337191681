/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShowroomsByPortalIdQueryVariables = {|
  portalId: string
|};
export type ShowroomsByPortalIdQueryResponse = {|
  +showroomsByPortalId: ?{|
    +showrooms?: $ReadOnlyArray<{|
      +__typename: string,
      +_id: string,
      +id: string,
      +name: string,
      +mainHeading: string,
      +headerImage: ?string,
      +backgroundImage: ?string,
      +subHeading: string,
      +tags: ?$ReadOnlyArray<string>,
      +templateId: number,
      +portalId: ?string,
      +content: ?$ReadOnlyArray<?{|
        +name: string,
        +content: ?$ReadOnlyArray<?{|
          +image: string,
          +mainHeading: string,
          +presentationId: string,
          +subHeading: string,
          +imageUrl: string,
          +presentation: ?{|
            +coverUrl: ?string,
            +portalId: string,
            +name: string,
            +menuId: string,
            +folderId: string,
          |},
          +files: ?$ReadOnlyArray<?{|
            +_id: string,
            +name: string,
            +relatedPresentationIds: $ReadOnlyArray<string>,
            +fileUrl: string,
            +fileType: string,
            +coverUrl: ?string,
            +thumbnailUrl: ?string,
            +visibility: ?boolean,
            +creator: string,
          |}>,
        |}>,
      |}>,
    |}>
  |}
|};
export type ShowroomsByPortalIdQuery = {|
  variables: ShowroomsByPortalIdQueryVariables,
  response: ShowroomsByPortalIdQueryResponse,
|};
*/


/*
query ShowroomsByPortalIdQuery(
  $portalId: ID!
) {
  showroomsByPortalId(portalId: $portalId) {
    __typename
    ... on ListOfShowrooms {
      showrooms {
        __typename
        _id
        id
        name
        mainHeading
        headerImage
        backgroundImage
        subHeading
        tags
        templateId
        portalId
        content {
          name
          content {
            image
            mainHeading
            presentationId
            subHeading
            imageUrl
            presentation {
              coverUrl
              portalId
              name
              menuId
              folderId
            }
            files {
              _id
              name
              relatedPresentationIds
              fileUrl
              fileType
              coverUrl
              thumbnailUrl
              visibility
              creator
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "portalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "portalId",
    "variableName": "portalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainHeading",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subHeading",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portalId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverUrl",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Showroom",
      "kind": "LinkedField",
      "name": "showrooms",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headerImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundImage",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templateId",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NestedContent",
              "kind": "LinkedField",
              "name": "content",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "presentationId",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Presentation",
                  "kind": "LinkedField",
                  "name": "presentation",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    (v7/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "menuId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "folderId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "files",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relatedPresentationIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fileUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fileType",
                      "storageKey": null
                    },
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnailUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visibility",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "creator",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfShowrooms",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShowroomsByPortalIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "showroomsByPortalId",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShowroomsByPortalIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "showroomsByPortalId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04d56b9a6951921fd751d2b43c7aa488",
    "id": null,
    "metadata": {},
    "name": "ShowroomsByPortalIdQuery",
    "operationKind": "query",
    "text": "query ShowroomsByPortalIdQuery(\n  $portalId: ID!\n) {\n  showroomsByPortalId(portalId: $portalId) {\n    __typename\n    ... on ListOfShowrooms {\n      showrooms {\n        __typename\n        _id\n        id\n        name\n        mainHeading\n        headerImage\n        backgroundImage\n        subHeading\n        tags\n        templateId\n        portalId\n        content {\n          name\n          content {\n            image\n            mainHeading\n            presentationId\n            subHeading\n            imageUrl\n            presentation {\n              coverUrl\n              portalId\n              name\n              menuId\n              folderId\n            }\n            files {\n              _id\n              name\n              relatedPresentationIds\n              fileUrl\n              fileType\n              coverUrl\n              thumbnailUrl\n              visibility\n              creator\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ca79edb5e7593079e8eb995b4d7fc40';

module.exports = node;
