/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteMicrositeMutationVariables = {|
  deleteMicrositeId: string
|};
export type DeleteMicrositeMutationResponse = {|
  +deleteMicrosite: ?{|
    +_id?: string,
    +id?: string,
    +portalId?: string,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +userId?: ?string,
    +__typename: "Response",
  |}
|};
export type DeleteMicrositeMutation = {|
  variables: DeleteMicrositeMutationVariables,
  response: DeleteMicrositeMutationResponse,
|};
*/


/*
mutation DeleteMicrositeMutation(
  $deleteMicrositeId: ID!
) {
  deleteMicrosite(id: $deleteMicrositeId) {
    __typename
    ... on Microsite {
      _id
      id
      portalId
    }
    ... on Response {
      message
      status
      success
      token
      hash
      userId
      __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deleteMicrositeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deleteMicrositeId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    }
  ],
  "type": "Microsite",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMicrositeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteMicrosite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMicrositeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteMicrosite",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "696cc3e546f339b1a9e52e314e188486",
    "id": null,
    "metadata": {},
    "name": "DeleteMicrositeMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMicrositeMutation(\n  $deleteMicrositeId: ID!\n) {\n  deleteMicrosite(id: $deleteMicrositeId) {\n    __typename\n    ... on Microsite {\n      _id\n      id\n      portalId\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      hash\n      userId\n      __typename\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7241b90b13e212432c1deafeff14f1a4';

module.exports = node;
