/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteCoverpageMutationVariables = {|
  deleteCoverpageId: string
|};
export type DeleteCoverpageMutationResponse = {|
  +deleteCoverpage: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +portalId?: string,
    +message?: string,
    +status?: number,
    +token?: ?string,
    +success?: boolean,
    +hash?: ?string,
    +userId?: ?string,
    +__typename: "Response",
  |}
|};
export type DeleteCoverpageMutation = {|
  variables: DeleteCoverpageMutationVariables,
  response: DeleteCoverpageMutationResponse,
|};
*/


/*
mutation DeleteCoverpageMutation(
  $deleteCoverpageId: ID!
) {
  deleteCoverpage(id: $deleteCoverpageId) {
    __typename
    ... on Coverpage {
      _id
      id
      name
      portalId
    }
    ... on Response {
      message
      status
      token
      success
      hash
      userId
      __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deleteCoverpageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deleteCoverpageId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    }
  ],
  "type": "Coverpage",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCoverpageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteCoverpage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCoverpageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteCoverpage",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d28f85fc638beadb4bc965838911a58d",
    "id": null,
    "metadata": {},
    "name": "DeleteCoverpageMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCoverpageMutation(\n  $deleteCoverpageId: ID!\n) {\n  deleteCoverpage(id: $deleteCoverpageId) {\n    __typename\n    ... on Coverpage {\n      _id\n      id\n      name\n      portalId\n    }\n    ... on Response {\n      message\n      status\n      token\n      success\n      hash\n      userId\n      __typename\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24642ba66fd3ae67dda9d09d198bb673';

module.exports = node;
