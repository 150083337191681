import PropTypes from "prop-types";
import { Wrapper, Checkmark } from "./styles";

export default function RadioButton({
  option,
  handleCheck,
  checked,
  reverse,
  themeColor,
}) {
  return (
    <Wrapper
      key={option.id}
      onClick={handleCheck}
      reverse={reverse}
    >
      <Checkmark
        className={checked === option.value ? "checked" : ""}
        reverse={reverse}
      >
        <div className="innerCircle" />
      </Checkmark>
      <input
        type="radio"
        id={option.id}
        value={option.value}
        name={option.name}
      />
      <label htmlFor="id">{option.value}</label>
    </Wrapper>
  );
}

RadioButton.propTypes = {
  option: PropTypes.object,
  handleCheck: PropTypes.func,
  checked: PropTypes.string,
  reverse: PropTypes.bool,
  themeColor: PropTypes.string,
};
