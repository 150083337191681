import { Blur, Wrapper, Title, Details, ShowroomsLink, Scroll } from "./common";
import { useQuery } from "relay-hooks";
import CoverpageQuery from "graphql/queries/GetCoverpageQuery";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export function CoverPage4({isPreview,sharing,  handleCheckoutShowrooms, updateCoverpage, showroomid}){
    const navigation = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const { data, isLoading } = useQuery(
        CoverpageQuery,
        { coverpageId: id },
        {
          fetchPolicy: "store-and-network",
        }
    );

    useEffect(() => {
        if (id && !isLoading) {
            updateCoverpage(data.coverpage)
        }
    }, [data, isLoading]);

    const addShowroomIds = (showroomIDs) => {
        updateCoverpage({showroomIds: showroomIDs})
    }
    return(
        <Wrapper spaceBetween pd='5%' isPreview={(isPreview || sharing)}>
            <Blur width="49%">
              <Scroll height="181px">
                <Title>Title</Title>
                <Details>consectetur adipiscing elit. Nam lacinia hendrerit turpis, ut mattis nibh. Sed vitae</Details>
                {showroomid?<ShowroomsLink onClick={() =>{navigation(`/showrooms/${showroomid}`)}}>Checkout my Show Rooms</ShowroomsLink>:""}
              </Scroll>
            </Blur>
            <Blur width="49%">
              <Scroll height="181px">
                <Title>Title</Title>
                <Details>consectetur adipiscing elit. Nam lacinia hendrerit turpis, ut mattis nibh. Sed vitae</Details>
                {showroomid?<ShowroomsLink onClick={() =>{navigation(`/showrooms/${showroomid}`)}}>Checkout my Show Rooms</ShowroomsLink>:""}
              </Scroll>
            </Blur>
        </Wrapper>
    )
}

export default CoverPage4;
