/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetMicrositesByPortalQueryVariables = {|
  portalId: string
|};
export type GetMicrositesByPortalQueryResponse = {|
  +micrositesByPortal: ?{|
    +microsites?: $ReadOnlyArray<{|
      +__typename: string,
      +_id: string,
      +id: string,
      +name: string,
      +showroomsIds: ?$ReadOnlyArray<string>,
      +tags: ?$ReadOnlyArray<string>,
      +backgroundImage: ?string,
      +coverpageId: ?string,
      +templateId: ?number,
      +portalId: string,
      +creator: string,
      +accountId: string,
      +thumbnailUrl: ?string,
      +createdAt: ?any,
      +updatedAt: ?any,
    |}>
  |}
|};
export type GetMicrositesByPortalQuery = {|
  variables: GetMicrositesByPortalQueryVariables,
  response: GetMicrositesByPortalQueryResponse,
|};
*/


/*
query GetMicrositesByPortalQuery(
  $portalId: ID!
) {
  micrositesByPortal(portalId: $portalId) {
    __typename
    ... on ListOfMicrosites {
      microsites {
        __typename
        _id
        id
        name
        showroomsIds
        tags
        backgroundImage
        coverpageId
        templateId
        portalId
        creator
        accountId
        thumbnailUrl
        createdAt
        updatedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "portalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "portalId",
    "variableName": "portalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Microsite",
      "kind": "LinkedField",
      "name": "microsites",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showroomsIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverpageId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfMicrosites",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetMicrositesByPortalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "micrositesByPortal",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetMicrositesByPortalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "micrositesByPortal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d47cc094693f28191336fbb2fb689eea",
    "id": null,
    "metadata": {},
    "name": "GetMicrositesByPortalQuery",
    "operationKind": "query",
    "text": "query GetMicrositesByPortalQuery(\n  $portalId: ID!\n) {\n  micrositesByPortal(portalId: $portalId) {\n    __typename\n    ... on ListOfMicrosites {\n      microsites {\n        __typename\n        _id\n        id\n        name\n        showroomsIds\n        tags\n        backgroundImage\n        coverpageId\n        templateId\n        portalId\n        creator\n        accountId\n        thumbnailUrl\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccb29b06896a154106b82e4616c6ac0f';

module.exports = node;
