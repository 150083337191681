
import { createContext, useState } from "react";
import { state } from "./DefaultState";
import useSetCachedData from "./useSetCachedData";

export const TemplateContext = createContext(null);

export function TemplateContentContext({ children, userData,isSharingMode }) {
  const [context, setContext] = useState(state);
  useSetCachedData(userData,isSharingMode)

  return (
    <TemplateContext.Provider value={{ context, setContext }}>
      {children}
    </TemplateContext.Provider>
  );
}

