import selectData from "graphql/mutations/selectData";
import profilesByUserId from "graphql/queries/showroom/profilesByUserId";
import { getAuthData, setAuthData } from "helpers/authHandler";
import { serverQuery } from "helpers/queryHandler";
import React, { useEffect } from "react";
import { useQuery } from "relay-hooks";

function useSetCachedData(userData, isSharingMode) {
  const { data } = serverQuery(
    useQuery,
    profilesByUserId,
    {
      profilesByUserIdId: userData?._id,
    },
    isSharingMode
  );

  const getActiveProfileFromCookie = () => {
    return getAuthData("activeProfile");
  };

  const getLastAccessedProfile = () => {
    const lastAccessedProfileId = getActiveProfileFromCookie()
      ?.split("-")
      ?.at(0);
    const cachedProfile = profiles?.filter(
      prfel => prfel.accountId === lastAccessedProfileId
    )[0];
    return cachedProfile;
  };
  const getLastAccessedPortal = () => {
    const profilesPortals = getLastAccessedProfile()?.accessedPortals;
    const lastAccessedPortalId = getActiveProfileFromCookie()
      ?.split("-")
      ?.at(1);
    const cachedPortal = profilesPortals?.filter(
      prtl => prtl.portalId === lastAccessedPortalId
    )[0];
    return cachedPortal;
  };

  //This needs to come from Library, so we can know which profile/Account entered Studio
  const profile = data?.profilesByUserId?.profiles[0];
  const profiles = data?.profilesByUserId?.profiles;
  //It differs which portals we will have access based on profiles, so we have to get this from Library
  const portals = data?.profilesByUserId?.profiles[0]?.accessedPortals;
  //This index should come from universal menu every time we switch the portal
  const portal = data?.profilesByUserId?.profiles[0]?.accessedPortals[0];

  //Update cache (Relay Store)

  useEffect(() => {
    if (isSharingMode) return;
    const activeProfileFromCookie = getActiveProfileFromCookie()?.split("-");
    let activeProfile = `${data?.profilesByUserId?.profiles[0]?.accountId}-${data?.profilesByUserId?.profiles[0]?.accessedPortals[0]?.portalId}`;
    if (
      activeProfileFromCookie &&
      !activeProfileFromCookie.includes("undefined")
    ) {
      activeProfile = activeProfileFromCookie.join("-");
    }
    setAuthData(activeProfile, true);
  }, [profiles]);
  useEffect(() => {
    if (isSharingMode) return;
    selectData(profiles, "Accounts", true);
  }, [profiles]);
  useEffect(() => {
    if (isSharingMode) return;
    const cachedProfile = getLastAccessedProfile();
    selectData(
      cachedProfile ? { ...cachedProfile } : { ...profile },
      "Profile",
      true
    );
  }, [profile]);
  useEffect(() => {
    if (isSharingMode) return;
    const cachedPortal = getLastAccessedPortal();
    selectData(
      cachedPortal ? { ...cachedPortal } : { ...portal },
      "Portal",
      true
    );
  }, [portal]);
  useEffect(() => {
    if (isSharingMode) return;
    const profilePortals = getLastAccessedProfile()?.accessedPortals;

    selectData(
      profilePortals ? { ...profilePortals } : { ...portals },
      "Portals",
      true
    );
  }, [portals]);
}

export default useSetCachedData;
