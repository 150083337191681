import "./styles.scss";
import { ReactComponent as Add} from "assets/images/add.svg";
import { FaPlay } from "react-icons/fa";
function DocFrame({image,logo,title, isDocument, noContent, handleAdd, row, setRowKey,extraClassitem}){

    const backgroundUrl = `url("${image}")`

    return(
        <div className={isDocument ? "documents-container" : `doc-container ${extraClassitem}`} style={{backgroundImage: backgroundUrl}}>
            {logo}
            {noContent===true?<div className="no-content-sign"  onClick={()=>{handleAdd();setRowKey(row)}}><Add /></div>:""}
            {extraClassitem && extraClassitem==="videoCover" && <div className="video-cover-item"><FaPlay/> </div>}
           {title &&  <h1>{title}</h1>}
        </div>
    );
}
export default DocFrame;