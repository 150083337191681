const { useState, useEffect } = require("react");

const useOrientation = () => {
    const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > window.innerHeight) {
          setOrientation('landscape');
        } else {
          setOrientation('portrait');
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return orientation;
  };

    export default useOrientation;