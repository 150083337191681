/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetCoverpagesByPortalIdQueryVariables = {|
  portalId: string
|};
export type GetCoverpagesByPortalIdQueryResponse = {|
  +getCoverpagesByPortalId: ?{|
    +coverpages?: $ReadOnlyArray<{|
      +__typename: string,
      +_id: string,
      +id: string,
      +portalId: string,
      +name: string,
      +mainHeading: string,
      +details: string,
      +templateId: number,
      +thumbnail: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +showroomIds: ?$ReadOnlyArray<string>,
      +showroomLink: string,
      +creator: string,
      +createdAt: any,
      +updatedAt: ?any,
    |}>
  |}
|};
export type GetCoverpagesByPortalIdQuery = {|
  variables: GetCoverpagesByPortalIdQueryVariables,
  response: GetCoverpagesByPortalIdQueryResponse,
|};
*/


/*
query GetCoverpagesByPortalIdQuery(
  $portalId: ID!
) {
  getCoverpagesByPortalId(portalId: $portalId) {
    __typename
    ... on ListOfCoverPages {
      coverpages {
        __typename
        _id
        id
        portalId
        name
        mainHeading
        details
        templateId
        thumbnail
        tags
        showroomIds
        showroomLink
        creator
        createdAt
        updatedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "portalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "portalId",
    "variableName": "portalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Coverpage",
      "kind": "LinkedField",
      "name": "coverpages",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainHeading",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "details",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showroomIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showroomLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfCoverPages",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCoverpagesByPortalIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCoverpagesByPortalId",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetCoverpagesByPortalIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCoverpagesByPortalId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2093a51c72367213ddaa6fde675e997a",
    "id": null,
    "metadata": {},
    "name": "GetCoverpagesByPortalIdQuery",
    "operationKind": "query",
    "text": "query GetCoverpagesByPortalIdQuery(\n  $portalId: ID!\n) {\n  getCoverpagesByPortalId(portalId: $portalId) {\n    __typename\n    ... on ListOfCoverPages {\n      coverpages {\n        __typename\n        _id\n        id\n        portalId\n        name\n        mainHeading\n        details\n        templateId\n        thumbnail\n        tags\n        showroomIds\n        showroomLink\n        creator\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4afd32639a53ed3f444b7ebfcfbeaf3';

module.exports = node;
