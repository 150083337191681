/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetCoverPagesQueryVariables = {||};
export type GetCoverPagesQueryResponse = {|
  +coverpages: ?{|
    +coverpages?: $ReadOnlyArray<{|
      +_id: string,
      +id: string,
      +portalId: string,
      +name: string,
      +mainHeading: string,
      +details: string,
      +templateId: number,
      +thumbnail: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +showroomIds: ?$ReadOnlyArray<string>,
      +showroomLink: string,
      +creator: string,
      +createdAt: any,
      +updatedAt: ?any,
    |}>
  |}
|};
export type GetCoverPagesQuery = {|
  variables: GetCoverPagesQueryVariables,
  response: GetCoverPagesQueryResponse,
|};
*/


/*
query GetCoverPagesQuery {
  coverpages {
    __typename
    ... on ListOfCoverPages {
      coverpages {
        _id
        id
        portalId
        name
        mainHeading
        details
        templateId
        thumbnail
        tags
        showroomIds
        showroomLink
        creator
        createdAt
        updatedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Coverpage",
      "kind": "LinkedField",
      "name": "coverpages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainHeading",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "details",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showroomIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showroomLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfCoverPages",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCoverPagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "coverpages",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetCoverPagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "coverpages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40f4d92d5bcbd410fa4c58730179cc1b",
    "id": null,
    "metadata": {},
    "name": "GetCoverPagesQuery",
    "operationKind": "query",
    "text": "query GetCoverPagesQuery {\n  coverpages {\n    __typename\n    ... on ListOfCoverPages {\n      coverpages {\n        _id\n        id\n        portalId\n        name\n        mainHeading\n        details\n        templateId\n        thumbnail\n        tags\n        showroomIds\n        showroomLink\n        creator\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a89105256e35123e7841e3e756084d5';

module.exports = node;
