import { setAuthData, switchActiveProfile } from "helpers/authHandler";
import {
  updateCachedPortal,
  updateCachedPortals,
  updateCachedProfile,
} from "helpers/storeManipulation";
import React, { useMemo } from "react";
import { useSubscription } from "relay-hooks";
import { WatchUserUpdates } from "./Watch";

function useWatchUserUpdates(userId, onUpdates) {
  const userChangeWatch = useMemo(
    () => ({
      variables: { id: userId },
      subscription: WatchUserUpdates,
      updater: (store, { userUpdates }) => {

        const updatedProfiles = userUpdates?.profiles.filter(
          profile => profile.accountInfo !== null
        );
        if (!updatedProfiles) return;
        let selectedProfile = store.get(`_Profile`);
        selectedProfile = JSON.parse(selectedProfile.getValue("details"));

        let selectedPortal = store.get(`_Portal`);
        selectedPortal = JSON.parse(selectedPortal.getValue("details"));

        const currentSelectedProfile = updatedProfiles.find(
          profile => profile._id === selectedProfile._id
        );
        if (currentSelectedProfile) {
          updateCachedProfile(store, currentSelectedProfile);
          updateCachedPortals(store, currentSelectedProfile.accessedPortals);
          const currentSelectedPortal =
            currentSelectedProfile.accessedPortals.find(
              portal => portal.portalId === selectedPortal.portalId
            );
          if (currentSelectedPortal) {
            updateCachedPortal(store, currentSelectedPortal);
          } else {
            const newSelectedPortal = currentSelectedProfile.accessedPortals[0];
            if (!newSelectedPortal) {
              updateCachedPortal(store, {});
              switchActiveProfile(undefined, undefined, "portalId");
            } else {
              updateCachedPortal(store, newSelectedPortal);
              switchActiveProfile(null, newSelectedPortal.portalId, "portalId");
            }
          }
        } else {
          const newSelectedProfile = updatedProfiles[0];
          updateCachedProfile(store, newSelectedProfile);
          updateCachedPortals(store, newSelectedProfile.accessedPortals);
          const newSelectedPortal = newSelectedProfile.accessedPortals[0];
          updateCachedPortal(store, newSelectedPortal);
          const { _id: profileId } = newSelectedProfile;
          const { portalId } = newSelectedPortal;
          // change cookie
          setAuthData(`${profileId}-${portalId}`, true);
        }
        store
          .get(`_Accounts`)
          .setValue(JSON.stringify(updatedProfiles), `details`);
        window.store = store;
      },
    }),
    [userId]
  );
  useSubscription(userChangeWatch);
}

export default useWatchUserUpdates;
