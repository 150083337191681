import graphql from "babel-plugin-relay/macro";

const presentationUpdate = graphql`
  mutation presentationUpdateMutation(
    $presentationId: ID!
    $folderId: ID!
    $portalId: ID!
    $menuId: ID!
    $name: String!
    $coverUrl: String
    $showFileNames: Boolean
  ) {
    editPresentation(
      presentationId: $presentationId
      folderId: $folderId
      portalId: $portalId
      menuId: $menuId
      name: $name
      coverUrl: $coverUrl
      showFileNames: $showFileNames
    ) {
      status
      message
      success
      __typename

    }
  }
`;
export default presentationUpdate;