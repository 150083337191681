import jwtDecode from "jwt-decode";
import * as crypto from "crypto-browserify";
import Cookies from "universal-cookie";
import { showErrorToast } from "@master-wizr/toast";

import { LOGIN_URL, ENC_SECRET, SECRET_IV, DOMAIN_URL } from "constants/env";
import selectData from "graphql/mutations/selectData";
import { getClientData } from "./queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";

/**
 * An object with all valid error message keys and their values
 * @param {*} message
 * @returns transformed object
 */
export const errorHandler = message => {
  const text = message?.toLowerCase() || "something wrong, please try again";
  const responses = {
    "invalid email or password": "email",
    "user with email already exists": "email",
    "user with email does not exist": "email",
    "invalid verification code": "verificationCode",
    "verification code is expired": "verificationCode",
    "user with phone number already exists": "telephone",
    "invalid reset link. please try again": "password",
    "account with name already exists!": "accountName",
  };
  if (!responses[text]) return {};
  return { [responses[text]]: [message] };
};

//FIXME: Move cookies configs and values to come from the backend
/**
 * Sets the app cookie name with the value, accessible by all subdomains
 * @param {*} name
 * @param {*} value
 * @returns a new cookie and also set in local storage value
 */
export function setbrowserData(value, name = "authCode") {
  const cookies = new Cookies();
  const existingValue = cookies.get(name);

  if (existingValue === value) {
    return document.cookie;
  }

  document.cookie = `${name}=${value};domain=.${DOMAIN_URL};path=/`;
  return document.cookie;
}


export function setCustomizerData(value, name = "customizerState") {
  const cookies = new Cookies();
  cookies.set(name, JSON.stringify(value), { domain: `.${DOMAIN_URL}`, path: "/" });
  return document.cookie;
}


/**
 * Sets details used toauthenticate the user on other subdomains from the root domain"
 * @param {*} detail
 * @param {*} activeProfile
 * @returns new cookies
 */
export function setAuthData(detail, activeProfile = null) {
  const cookies = new Cookies();
  const existingValue = cookies.get(activeProfile ? "activeProfile" : "authCode");
  if (existingValue === detail) {
    return document.cookie;
  }
  if (activeProfile) return setbrowserData(detail, "activeProfile");
  if (!detail) return showErrorToast("Invalid token, login to continue");
  return setbrowserData(detail);
}
export function switchActiveProfile(accountId, portalId, type) {
  const activeProfile = getAuthData("activeProfile");
  if (type === "accountId" && accountId !== null) {
      return setAuthData(`${accountId}-${portalId}`, true);
  }
  if (type === "portalId" && portalId !== null && activeProfile) {
      const [currentAccountId] = activeProfile.split("-");
      return setAuthData(`${currentAccountId}-${portalId}`, true);
  }
}
/**
 * Sets the expiration for the app cookie to a date that considers it deleted
 * @param {*} noRedirect hard or partial logout with redirect
 * @returns expired cookie
 */
export function deleteAuthData(noRedirect = true) {
  document.cookie = `authCode=;domain=.${DOMAIN_URL};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  document.cookie = `activeProfile=;domain=.${DOMAIN_URL};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  const { href, pathname } = window.location;
  let params = "";
  if (noRedirect) params = `?_rdr=${href}&service=studio&path=${pathname}`;
  window.location.href = `${LOGIN_URL}${params}`;
  return true;
}
/**
 * Read the value for the app token that has been set in cookie
 * @returns token
 */
export function getAuthData(name = "authCode") {
  const cookies = new Cookies();
  return cookies.get(name);
}
/**
 * Compares current seconds with payload.exp to validate the expiration of a token
 * @param {*} token
 * @returns Boolean
 */
export function authenticateUser(token) {
  const jwt = token || getAuthData();
  if (!jwt) return [false];
  try {
    const decoded = jwtDecode(jwt);
    if (!decoded || !decoded.exp) return [false];
    const now = new Date();
    const nowInSec = Math.floor(now.getTime() * 0.001);
    return [decoded.exp > nowInSec, decoded];
  } catch (unusedERROR) {
    showErrorToast("Invalid token, login to continue");
    deleteAuthData();
    return [false];
  }
}
/**
 * Generates an encrypred string whose value is passed
 * @param {*} value
 * @returns
 */
export const encryptString = value => {
  let cipher;
  try {
    cipher = crypto.createCipheriv("aes-256-cbc", ENC_SECRET, SECRET_IV);
  } catch (e) {
    console.log("Error cipher", e);
  }
  let ciphertext = cipher.update(String(value), "utf8", "hex");
  ciphertext += cipher.final("hex");
  return ciphertext;
};
export const switchPortal = (portal, navigate) => {
  selectData({ portal }, "Portal", true);
  setAuthData(portal, true);
  return navigate("/templates");
};

export const userNamesProfile = profile => {
  if (profile) {
    return `${profile.firstName || ""} ${profile.otherNames || ""} ${
      profile.lastName || ""
    }`;
  }
  return "";
};

export function deleteProfileData() {
  document.cookie = `activeProfile=;domain=.${DOMAIN_URL};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  return document.cookie;
}

export const getUserData = () => {
  const jwt = getAuthData();
  let decoded;
  if (!jwt) return [false];
  try {
    decoded = jwtDecode(jwt);
    if (!decoded) return [false];
  } catch (unusedERROR) {
    return [false];
  }

  return decoded;
};
