import { useTranslation } from "react-i18next";


export function Banner({children,...props}) {
  const {t} = useTranslation();
  const {title, logo, logosvg} = props;

  return (
    <div className="templates-banner content-banner">
      <div className='logo-logosvg'>{logosvg ? logosvg : ""}</div>
    <img src={logo} alt=""/>
    <h2><span>{title}</span> {t("contents")}</h2>
    <div>
    {children}
    </div>
    </div>
  );
}

export default Banner;
