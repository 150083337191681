import "./TemplateCard.scss";
import PropTypes from "prop-types";
import SharedText from "../SharedText/SharedText";
import { ReactComponent as Trash } from "../../../../assets/images/trash.svg";
import DocFrame from "../DocFrame";
import { VideoPlayer } from "@master-wizr/video-player";
import {
  wordFile,
  excelFile,
  powerpointFile,
  pdfFile,
  videoFile,
} from "../../../../helpers/checkTypes";
import "@master-wizr/video-player/dist/index.css";
import PresentationsQuery from "graphql/queries/GetPresentationQuery";
import { useQuery } from "relay-hooks";
import { STATIC_URL } from "../../../../constants/env";
import { useState } from "react";
import SinglePres from "components/ui/organisms/SinglePres/SinglePres";
import BottomSlider from "components/ui/organisms/SinglePres/BottomSlider";
import txtthumbnail from '../../../../assets/images/txt.jpg';

function TemplateCard({
  image,
  showroomSubContent,
  elementData,
  extraClass,
  templateId,
  subHeading,
  index,
  onChangeEditor,
  title,
  disabled,
  noId = false,
  rowIndex,
  cardIndex,
  onClickfn,
  presentationId,
  isMicrosite,
  setOpenModal,
  deleteState,
  handleDeletePresentation,
  noContent,
  handleAdd,
  setRowKey,
  isPreview
}) {
  const [hideDescription, setHideDescription] = useState(false);
  const isSharingMode = window.location.pathname.includes("/share/");
  const { data, isLoading } = useQuery(
    PresentationsQuery,
    { presentationId: presentationId||"" },
    {
      fetchPolicy: "store-and-network",
      skip: isSharingMode || showroomSubContent?.presentation || noContent, 
    }
  );

  let showroomRelatedData;
  if(data){
    showroomRelatedData = {...data}
  }else{
    if(!showroomSubContent?.files) showroomRelatedData = {presentation:{...showroomSubContent?.presentation, files:[]}}
    else showroomRelatedData = {presentation:{...showroomSubContent?.presentation, files:[...showroomSubContent?.files]}}
  }
  const openThumbnailModal = e => {
    e.stopPropagation();
    deleteState.includes(presentationId)
      ? setOpenModal({
          open: true,
          data: {...showroomRelatedData.presentation,imageUrl:showroomSubContent?.imageUrl, id: presentationId,rowIndex},
          toDelete: true,
        })
      : setOpenModal({
          open: true,
          data: {...showroomRelatedData.presentation,imageUrl:showroomSubContent?.imageUrl, id: presentationId,rowIndex},
        }); 
  };

  const prepareDeletePresentation = (e) => {
    e.stopPropagation();
    const row = parseInt(rowIndex.split("&")[0]);
    handleDeletePresentation(presentationId, row);
  }
  const row = parseInt(rowIndex?.split("&")[0]);
  return isLoading ? (
    <></>
  ) :
  templateId === 14 ? (
    <div>
     <SinglePres 
     presentationId={presentationId}
     data={showroomRelatedData?.presentation}/>
    </div>
  )
  :
  templateId === 15 ? (
    <div>
      <BottomSlider 
      presentationId={presentationId}
      data={showroomRelatedData?.presentation}/>
    </div>
  ) :
   (
    <div className={`template-single-card ${extraClass}`}>
      {showroomRelatedData?.presentation && !noContent &&(
        <div
          id={presentationId+"&"+rowIndex.split("&")[0]}
          className={
            isMicrosite === true &&
            (templateId === 4 || templateId === 3 || templateId === 11)
              ? "image microsite-view"
              : "image"
          }
          onClick={() =>
            onClickfn({
              presentation: {files : [...showroomRelatedData.presentation.files]},
              presentationId,
            }) || null
          }
          onMouseEnter={() => isMicrosite && setHideDescription(true)}
          onMouseLeave={() => isMicrosite && setHideDescription(false)}
        >
          {(showroomSubContent?.image !== showroomSubContent?.imageUrl && showroomSubContent?.files?.[0]?.fileType?.includes("mp4")) ?
            <DocFrame
            extraClassitem="videoCover"
            image={STATIC_URL + showroomSubContent?.imageUrl} /> :
            showroomSubContent?.imageUrl?.includes("showroompresentation-JUo2mM")?
          ( <DocFrame
            image={STATIC_URL + showroomSubContent?.imageUrl}
            title={elementData?.name}
          />
          ): (showroomRelatedData?.presentation?.coverUrl !== null  && showroomRelatedData?.presentation?.coverUrl !== "" ) ? (
            <DocFrame
              image={showroomRelatedData?.presentation?.coverUrl?.includes("/Covers/") ? showroomRelatedData?.presentation.coverUrl :  STATIC_URL + showroomRelatedData?.presentation.coverUrl}
              title={elementData?.name}
            />
          ) 
          : image?.includes(".txt") ? (
            <DocFrame
              image={txtthumbnail}
              title={elementData?.name}
              isDocument={true}
            />
          )
          : wordFile.includes(image.split(".").pop()) || image.includes("/document/") ? (
            <DocFrame
              image={
                "https://cdn.cf.masterwizr.com/wordthumb_ktgxyp.jpg"
              }
              title={elementData?.name}
              isDocument={true}
            />
          ) : powerpointFile.includes(image.split(".").pop()) || image.includes('/presentation/') ? (
            <DocFrame
              image={
                "https://cdn.cf.masterwizr.com/pptthumbnail_jqrrk9.png"
              }
              title={elementData?.name}
              isDocument={true}
            />
          ) : pdfFile.includes(image.split(".").pop()) ? (
            <DocFrame
              image={
                "https://cdn.cf.masterwizr.com/pdfthumb_dd1uj1.jpg"
              }
              title={elementData?.name}
              isDocument={true}
            />
          ) : excelFile.includes(image.split(".").pop()) || image.includes('/spreadsheets/') ? (
            <DocFrame
              image={
                "https://cdn.cf.masterwizr.com/excelthumb_eclao5.jpg"
              }
              title={elementData?.name}
              isDocument={true}
            />
          ) : videoFile.includes(image.split(".").pop()) ? (
            <VideoPlayer src={`${image.replace(/\s/g, "%20")}`} />
          ) : image.includes("youtube") || image.includes("vimeo") ? (
            <iframe
              src={`${image.split("cloudfront.net/").pop()}`}
              title="iframe"
              loading="lazy"
            />
          ) : image?.includes("mp3") ? 
           <DocFrame
           image={"https://cdn.cf.masterwizr.com/audiothumb_hajv4t.jpg"}
          title={elementData?.name}/> : 
           (
            <DocFrame image={image.replace(/\s/g, "%20")} />
          )}
          <div className="icons">
            <div
              className="tooltip-container"
              onClick={e => {
                openThumbnailModal(e);
              }}
            >
              <button>+</button>
              <div className="dropdown">
                <p className="tooltiptext">Add new cover image</p>
              </div>
            </div>
            <div className="tooltip-container" onClick={prepareDeletePresentation}>
              <Trash />
              <div className="dropdown">
                <p className="tooltiptext">Delete presentation</p>
              </div>
            </div>
          </div>
          <div className={isMicrosite && templateId === 11? "image-overlay": "hide"}  >
            <SharedText
              disabled={disabled}
              title={title}
              description={subHeading}
              index={index}
              isSubHead={false}
              onChangeEditor={onChangeEditor}
              noId={noId}
              noTitle={true}
              rowIndex={rowIndex}
              cardIndex={cardIndex}
              isShowroom={true}
          />
          </div>
        </div>
      )}
      {noContent &&(
        <div
          className={"image"}
        >
            <DocFrame
             setRowKey={setRowKey} row={row}
             noContent={noContent} handleAdd={handleAdd} />

        </div>
      )}
      <div className={isMicrosite && templateId === 11? hideDescription ? "hide": "show": "textcontainer"}>
      {templateId !== 4 && templateId !== 7 && ((subHeading && isPreview) || (!subHeading && !isPreview) || (subHeading && !isPreview)) && (
        <SharedText
          disabled={disabled}
          title={title}
          description={subHeading}
          index={index}
          isSubHead={false}
          onChangeEditor={onChangeEditor}
          noId={noId}
          noTitle={true}
          rowIndex={rowIndex}
          cardIndex={cardIndex}
          isShowroom={true}
        />
      )}
      </div>
    </div>
  );
}
export default TemplateCard;
TemplateCard.propTypes = {
  image: PropTypes.string,
  extraClass: PropTypes.string,
  subHeading: PropTypes.string,
  isSubHead: PropTypes.bool,
  index: PropTypes.number,
  onChangeEditor: PropTypes.func,
  imagesTemplate: PropTypes.array,
  tooltip: PropTypes.bool,
  noId: PropTypes.bool,
  isPreview: PropTypes.bool,
};
