import graphql from "babel-plugin-relay/macro";

const GetCoverpagesByPortalQuery = graphql`
  query GetCoverpagesByPortalIdQuery($portalId: ID!) {
    getCoverpagesByPortalId(portalId: $portalId) {
      ... on ListOfCoverPages {
        coverpages {
          __typename
          _id
          id
          portalId
          name
          mainHeading
          details
          templateId
          thumbnail
          tags
          showroomIds
          showroomLink
          creator
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default GetCoverpagesByPortalQuery;
