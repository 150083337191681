/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetFilesQueryVariables = {|
  presentationId: string
|};
export type GetFilesQueryResponse = {|
  +presentation: ?{|
    +files?: $ReadOnlyArray<?{|
      +_id: string,
      +name: string,
      +relatedPresentationIds: $ReadOnlyArray<string>,
      +fileUrl: string,
      +fileType: string,
      +coverUrl: ?string,
      +thumbnailUrl: ?string,
      +positions: $ReadOnlyArray<?{|
        +presentationId: string,
        +position: number,
      |}>,
    |}>
  |}
|};
export type GetFilesQuery = {|
  variables: GetFilesQueryVariables,
  response: GetFilesQueryResponse,
|};
*/


/*
query GetFilesQuery(
  $presentationId: ID!
) {
  presentation(id: $presentationId) {
    __typename
    ... on Presentation {
      files {
        _id
        name
        relatedPresentationIds
        fileUrl
        fileType
        coverUrl
        thumbnailUrl
        positions {
          presentationId
          position
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "presentationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "presentationId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "files",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relatedPresentationIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Position",
          "kind": "LinkedField",
          "name": "positions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "presentationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Presentation",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetFilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "presentation",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetFilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "presentation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96bfc2c19f8203272e7d8aa95675006d",
    "id": null,
    "metadata": {},
    "name": "GetFilesQuery",
    "operationKind": "query",
    "text": "query GetFilesQuery(\n  $presentationId: ID!\n) {\n  presentation(id: $presentationId) {\n    __typename\n    ... on Presentation {\n      files {\n        _id\n        name\n        relatedPresentationIds\n        fileUrl\n        fileType\n        coverUrl\n        thumbnailUrl\n        positions {\n          presentationId\n          position\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '993760b101def908b9478e17476d4d10';

module.exports = node;
