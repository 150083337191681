import styled from "styled-components";
import defaultImage from "./coverbg.png";

export const Wrapper = styled.div`
    width: ${props => props.isPreview ? '90%' : '75%'};
    height:${props => props.isPreview ? '82vh' : '70vh'};
    background-image: url(${props => props.image || defaultImage});
    background-position: center; 
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    border-radius: 16px;
    display: flex;
    justify-content: ${props => props.centerAlign ? 'center' : props.rightAlign ? 'right' : props.spaceBetween ? 'space-between' : 'left'};
    padding: ${props => props.pd};
    padding-bottom: ${props => props.pb};
    margin-top: 10px;
    @media screen and (min-width: 2000px) {
        height:68vh;
    }
    @media screen and (min-height: 700px) {
        margin-top: 20px;
    }
`

export const Blur = styled.div`
    border-radius: ${props => props.br ? props.br : '16px'};
    width: ${props => props.width};
    height: ${props => props.height};
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    align-self: ${props => props.top ? 'flex-start' : props.center ? 'center' : 'flex-end' }; //to vertically align the blur
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.vCenter ? 'center' : ''};
`

export const Title = styled.h5`
    font-weight: 600;
    margin-bottom: 15px;
    font-size: ${props => props.big ? '32px' : '20px'};
    text-align: ${props => props.center ? 'center' : ''};
    @media screen and (min-width: 1600px) {
        font-size: 28px;
    }
    @media screen and (min-width: 2000px) {
        font-size: 35px;
    }
    @media screen and (min-width: 3000px) {
        font-size: 49px;
    }
`

export const Details = styled.p`
    opacity: 0.7;
    margin: 0;
    font-size: 13px;
    @media screen and (min-width: 1500px) {
        font-size: 16px;
    }
    @media screen and (min-height: 700px) {
        font-size: 15px;
    }
    @media screen and (min-width: 2000px) {
        font-size: 30px;
    }
    @media screen and (min-width: 3000px) {
        font-size: 35px;
    }
`

export const ShowroomsLink = styled.a`
    color: white;
    text-align: ${props => props.center ? 'center' : 'left'};
    text-decoration: underline !important;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
    @media screen and (min-height: 730px) {
        margin-top: 10px;
        font-size: 16px;
    }
    @media screen and (min-width: 2000px) {
        font-size: 30px;
        margin-top: 30px;
    }
    @media screen and (min-width: 2000px) {
        font-size: 35px;
        margin-top: 35px;
    }
`

export const Scroll = styled.div`
    overflow-y: auto;
    padding: 25px 40px;
    height:${props => props.height};
    @media screen and (min-width: 2000px) {
        padding: 40px 70px;
    }
    @media screen and (min-width: 3000px) {
        padding: 50px 80px;
    }
    @media screen and (min-width: 4000px) {
        padding: 80px 110px;
    }
    @media screen and (min-height: 730px) {
        padding: 30px 60px;
    }

`
