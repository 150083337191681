import React, { useEffect } from "react";
import ShowroomQuery from "graphql/queries/GetShowroomQuery";
import { useParams } from "react-router-dom";
import { useQuery } from "relay-hooks";
import ShowRoomDesign from "components/ui/organisms/ShowRoomDesign";
import NavMenu from "components/ui/organisms/NavMenu";


export const mapper = {
  1: { Component: ShowRoomDesign },
  2: { Component: ShowRoomDesign },
  3: { Component: ShowRoomDesign },
  4: { Component: ShowRoomDesign },
  5: { Component: ShowRoomDesign },
  6: { Component: ShowRoomDesign },
  7: { Component: ShowRoomDesign },
  8: { Component: ShowRoomDesign },
  9: { Component: ShowRoomDesign },
  10: { Component: ShowRoomDesign },
  11: { Component: ShowRoomDesign },
  12: { Component: ShowRoomDesign },
  13: { Component: ShowRoomDesign },
  14: { Component: ShowRoomDesign },
  15: { Component: ShowRoomDesign },
  16: { Component: ShowRoomDesign },

};

const RenderShowroom = () => {
  const { id } = useParams();
  const skipFetchShowroom = id.length === 1 || id <= 16 ? true: false;
  // useEffect(() => {
  //   if (id.length > 2) {
  //   }
  // }, []);

  const { data, isLoading } = useQuery(
    ShowroomQuery,
    { showroomId: id },
    {
      skip: skipFetchShowroom,
      fetchPolicy: "store-or-network",
    }
  );

  const [state, setState] = React.useState();

  useEffect(() => {
    // do some checking here to ensure data exist
    if (data) {
      // mutate data if you need to
      setState(data);
    }
    else{
      setState({showroom: {}});
    }
  }, [id, data]);

  let Template;

  const templateId = state?.showroom?.templateId;

  Template = mapper[templateId ? templateId : parseInt(id)];
  return (
    <>
      {/* {window.location.pathname !== "/preview-page" && <NavMenu />} */}
      {!isLoading && state && (
        <Template.Component
          templateId={templateId ? templateId : id}
          showroomData={state}
        />
      )}
    </>
  );
};

export default RenderShowroom;
