export const getThumbnail = item => {
    switch (true) {
      case item?.includes(".xls" || "spreadsheets" || "xlsx"):
        return "https://cdn.cf.masterwizr.com/excelthumb_eclao5.jpg";
      case item?.includes("mp4" || "mov" || "wmv" || "ogg" || "webm" || "mpeg" || "quicktime"):
        return "https://cdn.cf.masterwizr.com/videothumb_genqsx.jpg";
      case item?.includes(".pdf"):
        return "https://cdn.cf.masterwizr.com/pdfthumb_dd1uj1.jpg";
      case item?.includes(".doc" || ".docx" || ".dotx" || ".docm" || ".odt" || ".rtf" || ".txt" || ".wps"):
        return "https://cdn.cf.masterwizr.com/wordthumb_ktgxyp.jpg";
      case item?.includes("document"):
        return "https://cdn.cf.masterwizr.com/googledocthumb_bzlm5x.jpg";
      case item?.includes("application/vnd.ms-powerpoint"):
        return "https://cdn.cf.masterwizr.com/Frame_41862_mszmkc.png";
      case item?.fileUrl?.includes("google.com/presentation"):
        return "https://cdn.cf.masterwizr.com/Frame_41862_mszmkc.png";
        case item?.includes(".mp3"):
        return "https://cdn.cf.masterwizr.com/audiothumb_hajv4t.jpg"
      default:
        return item;
    }
  };
  