// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";
import { getCachedMicrosites_Id } from "graphql/utils/index.js";

// 2
const mutation = graphql`
  mutation EditMicrositeMutation($microsite: editMicrosite) {
    editMicrosite(microsite: $microsite) {
      ... on Microsite {
        _id
        id
        name
        showroomsIds
        backgroundImage
        coverpageId
        templateId
        thumbnailUrl
        portalId
        creator
        accountId
        createdAt
        updatedAt
      }
      ... on Response {
        message
        status
        success
        token
        shared
        hash
        userId
        __typename
      }
    }
  }
`;

export default (microsite, callback) => {
  // 4
  const variables = {
    microsite,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      // Temporary Acces to Store In Console for testing.
      window.store = store;
    },
  });
};