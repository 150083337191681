import graphql from "babel-plugin-relay/macro";

const PresentationsQuery = graphql`
  query GetPresentationQuery($presentationId: ID!) {
    presentation(id: $presentationId) {
      ... on Presentation {
        _id
        name
        accountId
        folderId
        menuId
        portalId
        coverUrl
        files {
          _id
          name
          relatedPresentationIds
          fileUrl
          fileType
          coverUrl
          thumbnailUrl
          positions {
            presentationId
            position
          }
          visibility
          creator
        }
      }
    }
  }
`;

export default PresentationsQuery;
