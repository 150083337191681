export const updateCachedPortal = (store, portal) => {
  const portalToSelect = { ...portal };
  let data = store.get(`_Portal`);
  if (!data || !portalToSelect) return;
  data.setValue(JSON.stringify(portalToSelect), `details`);
  if (!data) return;
  store.getRoot().setLinkedRecord(data, `selectedPortal`);
};

export const updateCachedPortals = (store, portals) => {
  const portalsToSelect = { ...portals };
  let data = store.get(`_Portals`);
  if (!data || !portalsToSelect) return;
  data.setValue(JSON.stringify(portalsToSelect), `details`);
  if (!data) return;
  store.getRoot().setLinkedRecord(data, `selectedPortals`);
};

export const updateCachedProfile = (store, profile) => {
  const updatedProfile = { ...profile };
  let data = store.get(`_Profile`);
  if (!data) return;
  data.setValue(JSON.stringify(updatedProfile), `details`);
  store.getRoot().setLinkedRecord(data, `selectedProfile`);
};

export const updateProfileInAccounts = (store, account) => {
  if (!account) return;
  const accountToSelect = { ...account };
  let data = store.get(`_Accounts`);
  if (!data || !accountToSelect) return;
  // find and update the account
  let oldData = JSON.parse(data.getValue("details"));
  oldData = oldData.map(acc => {
    if (acc._id === accountToSelect._id) {
      acc = { ...accountToSelect };
    }
    return acc;
  });
  data.setValue(JSON.stringify(oldData), `details`);
  if (!data) return;
  store.getRoot().setLinkedRecord(data, `selectedAccounts`);
};
