// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";
import { getCachedCoverpages_Id, getCachedMicrosites_Id } from "graphql/utils/index.js";

// 2
// deleteShowroom(id: ID!): Response
const mutation = graphql`
  mutation DeleteCoverpageMutation($deleteCoverpageId: ID!) {
    deleteCoverpage(id: $deleteCoverpageId) {
      ... on Coverpage {
        _id
        id
        name
        portalId
      }
      ... on Response {
        message
        status
        token
        success
        hash
        userId
        __typename

      }
    }
  }
`;

export default (deleteCoverpageId, callback) => {
  // 4
  const variables = {
    deleteCoverpageId,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      // deleteFromCache(store, deleteCoverpageId);
      // updateCorrespondingMicrosite(store, deleteCoverpageId);

      // Temporary Acces to Store In Consol for testing.
      window.store = store;
    },
  });
}; // 3
