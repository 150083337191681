import ImgModal from "../ImgModal/ImgModal";
import "./style.scss";
import { Link } from "react-router-dom";
import { LOGIN_URL } from "constants/env";

function UpgradePopup({handleClose}) {

    const HUB_URL = `${LOGIN_URL}/hub/payments`;
    return (
        <ImgModal extraClass="upgrade-popup" title="You reached the maximum of you free space in the Studio, dont be limited, get access to no-limit space." state={true}>
            <ol className="lists-upg">
                <li>Enjoy useses such</li>
                <li>Storing all Show Rooms</li>
                <li>Creating cool Micro Sites</li>
                <li>Job applications</li>
                <li>Client proposals</li>
                <li>Portofolio of work</li>
                <li>School work</li>
                <li>Hobbies</li>
                <li>All just one click a way</li>
            </ol>
            <div className="buttons-licences">
                <button onClick={handleClose} submitbutton={"true"}>
                    Cancel
                </button>
                <Link to={HUB_URL}>
                    Licences
                </Link>
            </div>
        </ImgModal>
    );
}
export default UpgradePopup;
