import graphql from "babel-plugin-relay/macro";

const MicrositeByShowroomId = graphql`
  query MicrositeByShowroomIdQuery($showroomId: ID!) {
    micrositeByShowroomId(showroomId: $showroomId) {
      ... on Microsite {
          __typename
          showroomsIds
          name
      }
      ... on Response {
        message
        status
        success
      }
    }
  }
`;

export default MicrositeByShowroomId;
