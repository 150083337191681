/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetMicrositesQueryVariables = {||};
export type GetMicrositesQueryResponse = {|
  +microsites: ?{|
    +microsites?: $ReadOnlyArray<{|
      +_id: string,
      +id: string,
      +name: string,
      +showroomsIds: ?$ReadOnlyArray<string>,
      +backgroundImage: ?string,
      +coverpageId: ?string,
      +tags: ?$ReadOnlyArray<string>,
      +templateId: ?number,
      +portalId: string,
      +creator: string,
      +accountId: string,
      +createdAt: ?any,
      +updatedAt: ?any,
    |}>
  |}
|};
export type GetMicrositesQuery = {|
  variables: GetMicrositesQueryVariables,
  response: GetMicrositesQueryResponse,
|};
*/


/*
query GetMicrositesQuery {
  microsites {
    __typename
    ... on ListOfMicrosites {
      microsites {
        _id
        id
        name
        showroomsIds
        backgroundImage
        coverpageId
        tags
        templateId
        portalId
        creator
        accountId
        createdAt
        updatedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Microsite",
      "kind": "LinkedField",
      "name": "microsites",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showroomsIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverpageId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfMicrosites",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetMicrositesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "microsites",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetMicrositesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "microsites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8dd399f4068073e810992ae775f4eae2",
    "id": null,
    "metadata": {},
    "name": "GetMicrositesQuery",
    "operationKind": "query",
    "text": "query GetMicrositesQuery {\n  microsites {\n    __typename\n    ... on ListOfMicrosites {\n      microsites {\n        _id\n        id\n        name\n        showroomsIds\n        backgroundImage\n        coverpageId\n        tags\n        templateId\n        portalId\n        creator\n        accountId\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5944ba64b44798f281b0ffed9dea984a';

module.exports = node;
