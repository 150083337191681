import graphql from "babel-plugin-relay/macro";


const CoverpageQuery = graphql`
  query GetCoverpageQuery($coverpageId: ID!) {
    coverpage(id: $coverpageId) {
      ... on Coverpage {
      _id
      id
      portalId
      name
      mainHeading
      details
      templateId
      thumbnail
      tags
      showroomIds
      showroomLink
      creator
      accountId
      templateStyle {
        cardPosition
        BackgroundBlur
      }
      createdAt
      updatedAt
    }
    ... on Response {
      message
      status
      success
      token
      hash
      signedUrl
      userId
    }
    }
  }
`;

export default CoverpageQuery;
