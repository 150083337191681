import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: sub;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  vertical-align: unset;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'salmon' : 'papayawhip')};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`
export const Text = styled.span`
    margin-left: ${props => props.ml ? props.ml : '8px'};
`
export const Label = styled.label`
  margin-left: ${props => props.ml ? props.ml : '3.5px'};
  display: block;
`

const Checkbox = ({ className, checked, ...props }) => (
 <Label>
    <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
        </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
    <Text>{props.labelText}</Text>
  </Label>
)

export default Checkbox


