export const adaptDataFromCotextForCustomizer = (array) =>{
    if(!array){
        return false;
    }

    let list = [];
    array.forEach((item) =>{
        let filesData = [];
        item.content.map(files =>{
            filesData.push({
                ...files,
                _id:files.presentationId
                
            })
        })
        list.push(filesData)
    })
    
    const convertArray = JSON.stringify(list);

    return JSON.parse(convertArray)
}