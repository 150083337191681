export const thumbnailGenerator = (type, url) => {
    switch (type) {
        case "video":
          return "https://cdn.cf.masterwizr.com/videothumb_genqsx.jpg";
        
        case  "links":
          return "links";
  
        case "image":
          return url||"image";
  
        case "word":
          return "https://cdn.cf.masterwizr.com/wordthumb_ktgxyp.jpg";
  
        case "spreadsheet":
          return "https://cdn.cf.masterwizr.com/excelthumb_eclao5.jpg";
  
        case "powerpoint":
          return "https://cdn.cf.masterwizr.com/pptthumbnail_jqrrk9.png";
  
        case "pdf":
          return "https://cdn.cf.masterwizr.com/pdfthumb_dd1uj1.jpg";
        
        default:
          return type;
    }
};