import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const ExamplesSlider = ({ examples,items }) => {
    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: items ? items : 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
    };   
    return (
        <div className="examples-slider">
        <Slider {...settings}>
        {examples?.map((example, index) => (
            <div key={index}>
            <img src={example} alt={`example-${index}`} />
            </div>
        ))}
        </Slider>
        </div>
    );
    };