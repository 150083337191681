/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MicrositeByShowroomIdQueryVariables = {|
  showroomId: string
|};
export type MicrositeByShowroomIdQueryResponse = {|
  +micrositeByShowroomId: ?{|
    +__typename: "Microsite",
    +showroomsIds?: ?$ReadOnlyArray<string>,
    +name?: string,
    +message?: string,
    +status?: number,
    +success?: boolean,
  |}
|};
export type MicrositeByShowroomIdQuery = {|
  variables: MicrositeByShowroomIdQueryVariables,
  response: MicrositeByShowroomIdQueryResponse,
|};
*/


/*
query MicrositeByShowroomIdQuery(
  $showroomId: ID!
) {
  micrositeByShowroomId(showroomId: $showroomId) {
    __typename
    ... on Microsite {
      __typename
      showroomsIds
      name
    }
    ... on Response {
      message
      status
      success
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showroomId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "showroomId",
    "variableName": "showroomId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showroomsIds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    }
  ],
  "type": "Response",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MicrositeByShowroomIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "micrositeByShowroomId",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Microsite",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MicrositeByShowroomIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "micrositeByShowroomId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Microsite",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ce9a492c92769789a79b08c557fb7a5",
    "id": null,
    "metadata": {},
    "name": "MicrositeByShowroomIdQuery",
    "operationKind": "query",
    "text": "query MicrositeByShowroomIdQuery(\n  $showroomId: ID!\n) {\n  micrositeByShowroomId(showroomId: $showroomId) {\n    __typename\n    ... on Microsite {\n      __typename\n      showroomsIds\n      name\n    }\n    ... on Response {\n      message\n      status\n      success\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3224c7ac4fcaee4d0d9640c0dfe275f6';

module.exports = node;
