/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetPresentationQueryVariables = {|
  presentationId: string
|};
export type GetPresentationQueryResponse = {|
  +presentation: ?{|
    +_id?: string,
    +name?: string,
    +accountId?: string,
    +folderId?: string,
    +menuId?: string,
    +portalId?: string,
    +coverUrl?: ?string,
    +files?: $ReadOnlyArray<?{|
      +_id: string,
      +name: string,
      +relatedPresentationIds: $ReadOnlyArray<string>,
      +fileUrl: string,
      +fileType: string,
      +coverUrl: ?string,
      +thumbnailUrl: ?string,
      +positions: $ReadOnlyArray<?{|
        +presentationId: string,
        +position: number,
      |}>,
      +visibility: ?boolean,
      +creator: string,
    |}>,
  |}
|};
export type GetPresentationQuery = {|
  variables: GetPresentationQueryVariables,
  response: GetPresentationQueryResponse,
|};
*/


/*
query GetPresentationQuery(
  $presentationId: ID!
) {
  presentation(id: $presentationId) {
    __typename
    ... on Presentation {
      _id
      name
      accountId
      folderId
      menuId
      portalId
      coverUrl
      files {
        _id
        name
        relatedPresentationIds
        fileUrl
        fileType
        coverUrl
        thumbnailUrl
        positions {
          presentationId
          position
        }
        visibility
        creator
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "presentationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "presentationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverUrl",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "folderId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "menuId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "files",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relatedPresentationIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileType",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Position",
          "kind": "LinkedField",
          "name": "positions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "presentationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Presentation",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetPresentationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "presentation",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetPresentationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "presentation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09e1cb870784b34193e3aecb74739639",
    "id": null,
    "metadata": {},
    "name": "GetPresentationQuery",
    "operationKind": "query",
    "text": "query GetPresentationQuery(\n  $presentationId: ID!\n) {\n  presentation(id: $presentationId) {\n    __typename\n    ... on Presentation {\n      _id\n      name\n      accountId\n      folderId\n      menuId\n      portalId\n      coverUrl\n      files {\n        _id\n        name\n        relatedPresentationIds\n        fileUrl\n        fileType\n        coverUrl\n        thumbnailUrl\n        positions {\n          presentationId\n          position\n        }\n        visibility\n        creator\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3be7a5d10e21bb3a18fb81d76c4b4f84';

module.exports = node;
