import graphql from "babel-plugin-relay/macro";

const profileQuery = graphql`
  query profileQuery($profileId: ID!) {
    profile(id: $profileId) {
      ... on Profile {
        accessedPortals {
          portalInfo {
            name
            _id
          }
        }
      }
    }
  }
`;
export default profileQuery;
