import PropTypes from "prop-types";
import "./frosted.scss";
import { Link } from "react-router-dom";
import { Heading } from "../../../../containers/Templates/styles";
import { useTranslation } from "react-i18next";

function Frosted({ title, list, checkLicense }) {
  const {t} = useTranslation();
  return (
    <div className="frusted images-container">
      <Heading className="titleBrightMode">{t(title)}</Heading>
      <div className="content">
        {list.map(d => {
          return (
            <Link
              key={d.id}
              to={`/coverpage-detailed/${d.id}`}
              state={{ list: d }}
              style={{ backgroundImage: `url("${d.image}")` }}
              className={`blur ${d.extraClass}`}
              onClick={() => {
                checkLicense();
            }}
            ></Link>
          );
        })}
      </div>
    </div>
  );
}
export default Frosted;
Frosted.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
};
