export function DeleteIcon(props){
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10.94}
            height={14.062}
            {...props}
        >
            <path
            data-name="Path 232"
            d="M4.295.849h2.35v.4h.849V.793A.794.794 0 0 0 6.701 0H4.239a.794.794 0 0 0-.793.793v.451h.849Zm0 0"
            fill={props.fill || "#8e8e8e"}
            />
            <path
            data-name="Path 233"
            d="M9.454 4.607H1.486a.374.374 0 0 0-.373.4l.666 8.237a.885.885 0 0 0 .881.814h5.618a.885.885 0 0 0 .881-.814l.666-8.237a.374.374 0 0 0-.371-.4Zm-6.039 8.576h-.027a.424.424 0 0 1-.423-.4l-.417-6.762a.424.424 0 1 1 .847-.052l.417 6.762a.424.424 0 0 1-.397.452Zm2.484-.424a.424.424 0 0 1-.849 0V5.997a.424.424 0 1 1 .849 0Zm2.493-6.737-.4 6.762a.424.424 0 0 1-.423.4h-.025a.424.424 0 0 1-.4-.449l.4-6.762a.424.424 0 1 1 .847.05Zm0 0"
            fill={props.fill || "#8e8e8e"}
            />
            <path
            data-name="Path 234"
            d="m10.921 3.297-.279-.836a.54.54 0 0 0-.512-.369H.808a.54.54 0 0 0-.509.37l-.279.836a.351.351 0 0 0 .332.461h10.237a.346.346 0 0 0 .185-.054.35.35 0 0 0 .147-.408Zm0 0"
            fill={props.fill || "#8e8e8e"}
            />
        </svg>
    )
}

export default DeleteIcon;
