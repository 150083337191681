import React, { useRef, useState } from "react";
import { useEffect } from "react";
import classes from "./copyInput.module.scss";
import { ReactComponent as Copy } from "assets/images/copy.svg";
import  { ReactComponent as Eye } from "assets/images/eye.svg";
import  { ReactComponent as Eyehiden } from "assets/images/eyehidden.svg";

function CopyInput({ onChange, value,className,type,setPasswordType,isSharedpassword, icon, ...rest }) {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleChange = e => {
    if (onChange) return onChange(e);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const togglePassword =()=>{
    if(type==="password"){
     setPasswordType("text")
    }else{
      setPasswordType("password")
    }
  }
  return (
    <div className={`${classes.inputGroup} ${className}`} >
      <input
        {...rest}
        ref={inputRef}
        type={type}
        value={inputValue}
        onChange={handleChange}
      />
     <div>
      { isSharedpassword && 
      <button onClick={togglePassword}>
        { type === "password" ? <Eyehiden/> : <Eye/> } 
      </button>
      }
     </div>
    </div>
  );
}

export default CopyInput;
