import img from "assets/images/coverpages.png";
import img1 from "assets/images/showrooms.png";
import img2 from "assets/images/microsites.png";
import allCovers from "assets/images/allcovers-_1__1.webp";
import coverBackground from "assets/images/coverBackground.png";
import viewBackground from "assets/images/viewBackground.png";
import backgrounds from "assets/images/backgrounds.png";
import presentation from "assets/images/presentation.webp";
import Microsite from "assets/images/microsite.webp";
import Customizer from "assets/images/customizerexample.webp";
import Sharing from "assets/images/sharingexample.png";

export const cardsData = [
  {
    title: "Cover Pages",
    desc: "enhance-user-connection-by-personalizing-show-rooms",
    btnText: "go-to-cover-pages",
    toLink: "cover-pages",
    image: img,
  },
  {
    title: "Show Rooms",
    desc: "create-impactful-presentations-and-videos",
    btnText: "go-to-show-rooms",
    toLink: "showrooms",
    image: img1,
  },
  {
    title: "micro-sites",
    desc: "putting-2-or-more-show-rooms-together",
    btnText: "go-to-micro-sites",
    toLink: "microsites",
    image: img2,
  },
];

export const homeSections = [
  {
    title: "Cover Pages",
    description:
      "discover-multiple-templates-of-cover-pages",
    backgroundImage: coverBackground,
    image: allCovers,
    btn: 'go-to-cover-pages',
    color: 'black',
    bgColor: '#444545',
    toLink: 'coverpage-templates'
  },
  {
    title: "grouping-presentations",
    description:
      "to-ensure-a-very-professional-style-of-presentation",
    btn: "create-show-rooms",
    background:
      "linear-gradient(89deg, #131313 0.56%, #272626 50.58%, #131313 99.56%)",
    bgColor: "#ec7600",
    toLink: "showroom-templates",
    image: presentation,
  },
  {
    extraClass: "left",
    title: "multiple-backgrounds",
    description:
      "vary-the-themes-of-your-work-based-on-what-emotion",
    backgroundImage: viewBackground,
    image: backgrounds,
    boxShadow: "0px 20px 150px 0px #d0650266",
  },
  {
    extraClass: "right",
    color: "white",
    title: "micro-sites",
    description:
      "an-innovative-approach-that-effortlessly-combines-related-show-rooms",
    image: Microsite,
  },
  {
    extraClass: "middle",
    color: "white",
    title: "share-any-presentations-created",
    description:
      "sharing-is-done-through-web-links",
    image: Sharing,
  },
  {
    extraClass: "left",
    color: "white",
    title: "studio-customizer",
    description:
      "selecting-presentations-videos-and-multimedia",
    image: Customizer,
  },
];

export const faqData = [
  {
    question: "what-inspired-studio",
    answer:
      "sharing-material-in-sales-job-interviews-with-investors",
  },
  {
    question: "how-do-we-create-show-rooms",
    answer:
      "when-are-signed-in-in-studio",
  },
  {
    question: "how-do-i-change-show-rooms-background",
    answer:
      "when-you-are-inside-a-show-room-in-editing",
  },
  {
    question: "how-do-i-create-a-cover-page",
    answer:
      "on-the-universal-menu-you-find-the-cover-pages-option",
  },
  {
    question: "how-do-i-create-micro-sites",
    answer:
      "from-the-universal-menu-you-go-to-the-show-rooms-you-have-created-already",
  },
];


export const homeNavigation = [
  {
    title: 'Home',
    path: '/studio-guide'
  },
  {
    title: 'Show Rooms',
    path: '/showrooms'
  },
];