import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import "./input.scss";
import { ReactComponent as Close } from "assets/images/close.svg";
import { ReactComponent as Save } from "assets/images/icon-save.svg";
import { showErrorToast } from "@master-wizr/toast";

function EditInput({ type, name, value, onChange, disabled, onClose,  handleRename, nrLength}) {
    const initialValue = useMemo(() => value, [name])
    const trimmedValue = value?.trim();
    const inputRef = useRef(null);

  return (
    <>  
        <input
          value={value || inputRef?.current?.value}
          type={type}
          ref={inputRef}
          name={name}
          onChange={onChange}
          disabled={disabled}
          onKeyPress={(e) => e.key === "Enter"  && handleRename(inputRef)}
          maxlength={nrLength}
        />
      
     {!disabled && <div id="save-div">
        <button 
        onClick={value === "" ? () => showErrorToast("Name should not be empty") : trimmedValue !== initialValue && trimmedValue !== "" ? () => handleRename(inputRef) : null }
        className={trimmedValue === "" || trimmedValue === initialValue ? "disabled-btn" : "" }
        >
          <Save />
        </button>
        <button onClick={onClose}>
          <Close />
        </button>
      </div> }
    </>
  );
}

export default EditInput;
EditInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

EditInput.defaultProps = {
  onChange: () => {},
};