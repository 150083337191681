import { showWarnToast } from "@master-wizr/toast";
import {
  getCachedCoverpages_Id,
  getCachedMicrosites_Id,
  getCachedShowrooms_Id,
  getCachedMagazines_Id,
} from "graphql/utils";
import { useMemo } from "react";
import { useSubscription } from "relay-hooks";

const showWarningIfUserInDeletedItemPage = (dataIdsToDelete, itemPageName) => {
  const currentUrl = window.location.href;
  const lct =
    itemPageName === "showrooms"
      ? "showroom"
      : itemPageName === "microsite"
      ? "microsite"
      : itemPageName === "magazine"
      ? "magazine"
      : "coverpage";
  dataIdsToDelete.forEach(id => {
    if (currentUrl.includes(itemPageName) && currentUrl.includes(id)) {
      showWarnToast(`This ${lct} has been deleted`);
    }
  });
};

const getDataIdsToDelete = (cachedData, serverData) => {
  const cachedDataIds = cachedData.map(data => data.getValue("_id"));
  const serverDataIds = serverData.map(data => data._id);
  const dataIdsToDelete = cachedDataIds.filter(
    showroomId => !serverDataIds.includes(showroomId)
  );
  return dataIdsToDelete;
};

const getDataIdsToAdd = (cachedData, serverData) => {
  const cachedDataIds = cachedData.map(data => data.getValue("_id"));
  const serverDataIds = serverData.map(data => data._id);
  const dataIdsToAdd = serverDataIds.filter(
    showroomId => !cachedDataIds.includes(showroomId)
  );
  return dataIdsToAdd;
};
const getCachedShowrooms = (store, portalId) => {
  const cachedShowroomsId = getCachedShowrooms_Id(`portalId:"${portalId}"`);

  const cachedShowrooms = store
    .get(cachedShowroomsId)
    ?.getLinkedRecords("showrooms");
  return cachedShowrooms;
};
const getCachedCoverpages = (store, portalId) => {
  const cachedCoverpagesId = getCachedCoverpages_Id(`portalId:"${portalId}"`);
  const cachedCoverpages = store
    .get(cachedCoverpagesId)
    ?.getLinkedRecords("coverpages");
  return cachedCoverpages;
};
const getCachedMicrosites = (store, portalId) => {
  const cachedMicrositesId = getCachedMicrosites_Id(`portalId:"${portalId}"`);
  const cachedMicrosites = store
    .get(cachedMicrositesId)
    ?.getLinkedRecords("microsites");
  return cachedMicrosites;
};

const getCachedMagazines = (store, portalId) => {
  const cachedMagazinesId = getCachedMagazines_Id(`portalId:"${portalId}"`);
  const cachedMagazines = store
    .get(cachedMagazinesId)
    ?.getLinkedRecords("magazines");
  return cachedMagazines;
};

const addShowroomToCache = (store, showroom) => {
  let cachedShowrooms = getCachedShowrooms(
    store,
    showroom.getValue("portalId")
  );
  cachedShowrooms.push(showroom);
  const cachedShowroomsId = getCachedShowrooms_Id(
    `portalId:"${showroom.getValue("portalId")}"`
  );

  store.get(cachedShowroomsId).setLinkedRecords(cachedShowrooms, "showrooms");
};

const addCoverpageToCache = (store, coverpage) => {
  let cachedCoverpages = getCachedCoverpages(
    store,
    coverpage.getValue("portalId")
  );
  cachedCoverpages.push(coverpage);
  const cachedCoverpagesId = getCachedCoverpages_Id(
    `portalId:"${coverpage.getValue("portalId")}"`
  );
  store
    .get(cachedCoverpagesId)
    .setLinkedRecords(cachedCoverpages, "coverpages");
};
const addMicrositeToCache = (store, microsite) => {
  let cachedMicrosites = getCachedMicrosites(
    store,
    microsite.getValue("portalId")
  );
  cachedMicrosites.push(microsite);
  const cachedMicrositesId = getCachedMicrosites_Id(
    `portalId:"${microsite.getValue("portalId")}"`
  );
  store
    .get(cachedMicrositesId)
    .setLinkedRecords(cachedMicrosites, "microsites");
};

const addMagazineToCache = (store, magazine) => {
  let cachedMagazines = getCachedMagazines(
    store,
    magazine.getValue("portalId")
  );
  cachedMagazines.push(magazine);
  const cachedMagazinesId = getCachedMagazines_Id(
    `portalId:"${magazine.getValue("portalId")}"`
  );
  store.get(cachedMagazinesId).setLinkedRecords(cachedMagazines, "magazines");
};

function useWatchPortalUpdates(portalId, WatchPortalUpdates, onUpdates) {
  const portalChangeWatch = useMemo(
    () => ({
      variables: { id: portalId },
      subscription: WatchPortalUpdates,
      updater: (store, data) => {
        if (onUpdates) onUpdates(data.portalUpdates);
        window.store = store;

        let cachedShowrooms = getCachedShowrooms(store, data.portalUpdates._id);
        let cachedCoverpages = getCachedCoverpages(
          store,
          data.portalUpdates._id
        );
        let cachedMicrosites = getCachedMicrosites(
          store,
          data.portalUpdates._id
        );
        let cachedMagazines = getCachedMagazines(store, data.portalUpdates._id);
        const { showrooms, coverpages, microsites, magazines } =
          data.portalUpdates;
        if (
          cachedShowrooms &&
          showrooms.length === cachedShowrooms.length &&
          cachedCoverpages &&
          coverpages.length === cachedCoverpages.length &&
          cachedMicrosites &&
          microsites.length === cachedMicrosites.length &&
          cachedMagazines &&
          magazines.length === cachedMagazines.length
        ) {
          return data;
        }

        const {
          showrooms: serverShowrooms,
          coverpages: serverCoverpages,
          microsites: serverMicrosites,
          magazines: serverMagazines,
        } = data.portalUpdates;

        // SHOWROOM=============================================
        if (cachedShowrooms) {
          const dataIdsToDelete = getDataIdsToDelete(
            cachedShowrooms,
            serverShowrooms
          );
          const dataIdsToAdd = getDataIdsToAdd(
            cachedShowrooms,
            serverShowrooms
          );
          if (dataIdsToDelete.length) {
            cachedShowrooms = cachedShowrooms.filter(
              item => !dataIdsToDelete.includes(item?.getValue("id"))
            );
            showWarningIfUserInDeletedItemPage(dataIdsToDelete, "showrooms");
            const cachedShowroomsId = getCachedShowrooms_Id(
              `portalId:"${data.portalUpdates._id}"`
            );
            store
              .get(cachedShowroomsId)
              .setLinkedRecords(cachedShowrooms, "showrooms");
          }
          if (dataIdsToAdd.length) {
            dataIdsToAdd.forEach(showroomId => {
              const newShowroom = store.get(showroomId);
              addShowroomToCache(store, newShowroom);
            });
          }
        }

        // COVERPAGES=============================================
        if (cachedCoverpages) {
          const dataIdsToDelete = getDataIdsToDelete(
            cachedCoverpages,
            serverCoverpages
          );
          const dataIdsToAdd = getDataIdsToAdd(
            cachedCoverpages,
            serverCoverpages
          );
          if (dataIdsToDelete.length) {
            cachedCoverpages = cachedCoverpages.filter(
              item => !dataIdsToDelete.includes(item?.getValue("id"))
            );
            showWarningIfUserInDeletedItemPage(
              dataIdsToDelete,
              "coverpage-detailed"
            );
            const cachedCoverpagesId = getCachedCoverpages_Id(
              `portalId:"${data.portalUpdates._id}"`
            );

            store
              .get(cachedCoverpagesId)
              .setLinkedRecords(cachedCoverpages, "coverpages");
          }
          if (dataIdsToAdd) {
            dataIdsToAdd.forEach(coverpageId => {
              const newCoverpage = store.get(coverpageId);
              addCoverpageToCache(store, newCoverpage);
            });
          }
        }

        // MICROSITES=============================================

        if (cachedMicrosites) {
          const dataIdsToDelete = getDataIdsToDelete(
            cachedMicrosites,
            serverMicrosites
          );
          const dataIdsToAdd = getDataIdsToAdd(
            cachedMicrosites,
            serverMicrosites
          );
          if (dataIdsToDelete.length) {
            cachedMicrosites = cachedMicrosites.filter(
              item => !dataIdsToDelete.includes(item?.getValue("id"))
            );
            showWarningIfUserInDeletedItemPage(dataIdsToDelete, "microsite");
            const cachedMicrositesId = getCachedMicrosites_Id(
              `portalId:"${data.portalUpdates._id}"`
            );
            store
              .get(cachedMicrositesId)
              .setLinkedRecords(cachedMicrosites, "microsites");
          }
          if (dataIdsToAdd.length) {
            dataIdsToAdd.forEach(micrositeId => {
              const newMicrosite = store.get(micrositeId);
              addMicrositeToCache(store, newMicrosite);
            });
          }
        }
        if (cachedMagazines) {
          const dataIdsToDelete = getDataIdsToDelete(
            cachedMagazines,
            serverMagazines
          );
          const dataIdsToAdd = getDataIdsToAdd(
            cachedMagazines,
            serverMagazines
          );
          if (dataIdsToDelete.length) {
            cachedMagazines = cachedMagazines.filter(
              item => !dataIdsToDelete.includes(item?.getValue("id"))
            );
            showWarningIfUserInDeletedItemPage(dataIdsToDelete, "magazine");
            const cachedMagazinesId = getCachedMagazines_Id(
              `portalId:"${data.portalUpdates._id}"`
            );
            store
              .get(cachedMagazinesId)
              .setLinkedRecords(cachedMagazines, "magazines");
          }
          if (dataIdsToAdd.length) {
            dataIdsToAdd.forEach(magazineId => {
              const newMagazine = store.get(magazineId);
              addMagazineToCache(store, newMagazine);
            });
          }
        }
      },
    }),
    [portalId]
  );
  useSubscription(portalChangeWatch);
}

export default useWatchPortalUpdates;
