import graphql from "babel-plugin-relay/macro";

const PortalsQuery = graphql`
  query GetPortalsQuery {
    portals {
      ... on ListOfPortals {
        portals {
          menus {
            folders {
              presentations {
                coverUrl
                files {
                  _id
                  name
                  relatedPresentationIds
                  fileUrl
                  fileType
                  coverUrl
                  thumbnailUrl
                  audioUrl
                  visibility
                  creator
                  positions {
                    presentationId
                    position
                  }
                  createdAt
                  updatedAt
                }
                _id
                name
              }
              _id
              name
            }
            _id
            name
          }
          _id
          name
        }
      }
    }
  }
`;

export default PortalsQuery;
