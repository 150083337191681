import styled from 'styled-components';
import { FlexContainer } from 'containers/flexContainer';

export const MainWrapper = styled(FlexContainer)`
    width: 72%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 2rem;
    margin-top:${props => props.mt ? props.mt : "6rem"};
    @media screen and (max-height: 550px) {
      width: 70%;
    } 
    @media screen and (min-width: 2000px) {
      width: 72%;
    } 
`

export const Heading = styled.p`
    margin-bottom: 10px;
    margin-top: 30px;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    color:white;
    &:not(:first-child){
      margin-top: 3rem;
    }
    span{
      opacity: 0.5;
      font-weight: 500;
    }
    @media screen and (max-height: 722px) {
      font-size: 12px;
    } 
    @media screen and (min-width: 2000px) {
      font-size: 24px;

    } 
    @media screen and (min-width: 3000px) {
      font-size: 35px;
      margin-top:50px;
      margin-bottom:30px;
    } 
    @media screen and (min-width: 4000px) {
      font-size: 49px;
    } 
    @media screen and (max-height: 950px) {
      margin-top: 15px;
      font-size: 14px;
      margin-bottom: 8px;
    } 
    @media screen and (max-height: 600px) {
      margin-top: 12px;
      font-size: 12px;
      margin-bottom: 5px;
    } 
`

export const TempWrapper = styled.div`
    display: flex;
    width: 100%;

  & div:nth-child(4n + 0) {
    /* Remove margin from 4th element in a row */
    margin-right: 0;
  }
`;

export const ExitButton = styled.button`
  position: absolute;
  width: 120px;
  height: 60px;
  background: #373737 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px 0px 0px 10px;
  bottom: 130px;
  right: 0;
  cursor: pointer;
  font: normal normal bold 16px/18px Raleway;
  letter-spacing: 0px;
  color: #EC7600;
  z-index: 1;
  @media screen and (max-width: 1440px) {
    bottom: 190px;
  }
  @media screen and (max-width: 1280px) {
    bottom: 215px;
  }
  @media screen and (max-height: 832px) {
    bottom: 132px;
  } 
  @media screen and (max-height: 722px) {
    bottom: 90px;
  } 
  @media screen and (max-height: 650px) {
      bottom: 55px;
  }
  @media screen and (max-height: 950px) {
    width: 90px;
    height: 50px;
  }
  @media screen and (min-height: 1000px) {
    bottom: 200px;

  }
  @media screen and (max-width:1240px) and  (max-height: 1366px) {
    bottom: 410px;
  }
  @media screen and (min-width: 2000px) {
    bottom: 290px;
    width: 150px;
    font-size: 30px;
    height: 90px;
  }
  @media screen and (min-width: 3000px) {
  bottom: 440px;
    width: 200px;
    font-size: 44px;
    height: 120px;
  } 
  @media screen and (min-width: 4000px) {
    bottom: 650px;
    width: 250px;
    font-size: 49px;
    height: 150px;
  } 

`
