import styled from "styled-components";

export const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => (props.columns ? "column" : "row")};
    justify-content: ${props =>
      props.rightAlign
        ? "flex-end"
        : props.hCenterAlign
        ? "center"
        : props.spaceBetween
        ? "space-between"
        : props.flexStart
        ? "flex-start"
        : props.spaceEvenly
        ? "space-evenly"
        : ""};
    align-items: ${props =>
      props.vCenterAlign ? "center" : props.vFlexStart ? "flex-start" : ""};
    padding: ${props => props.pd};
    padding-bottom: ${props => props.pb};
    padding-top: ${props => props.pt};
    background: ${props => props.bg};
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.mg};
    margin-left: ${props => props.ml};
    margin-right: ${props => props.mr};
    margin-top: ${props => props.mt};
    margin-bottom: ${props => props.mb};
    position: ${props => props.position};
    border: ${props => props.border};
    border-top: ${props => (props.bt ? props.bt : "")};
    border-radius: ${props => props.radius};
    border-top-left-radius: ${props => (props.btlr ? props.btlr : "")};
    border-top-right-radius:${props => (props.btrr ? props.btrr : "")};
    border-bottom-left-radius: ${props => (props.bblr ? props.bblr : "")};
    border-bottom-right-radius:${props => (props.bbrr ? props.bbrr : "")};
    bottom: ${props => props.bottom || ""}
    border-bottom: ${props => (props.bb ? "solid 1px rgb(0,0,0,10%)" : "")};
    gap:${props => props.gap};
    @media screen and (max-width:768px){
        margin-left:0px;
    }
`;
