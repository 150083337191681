import { useQuery } from "relay-hooks";
import List from "containers/lists";
import CoverPagesQuery from "graphql/queries/GetCoverPagesQuery";
import { useParams, useNavigate } from "react-router-dom";
import GetCoverpagesByPortalQuery from "graphql/queries/GetCoverpagesByPortal";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import {useState, useEffect} from "react";
import EditMicrositeMutation from "graphql/mutations/EditMicrositeMutation";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import logo1 from "assets/images/coverpage_white.png";
import IsLoading from "../IsLoading";
import responseHandler from "helpers/responseHandler";

export function CoverPageListSelect() {
  const navigation = useNavigate();
  
  const { showroomids, mode, micrositeId, coverpageId, templateId, selectedCover } = useParams();
  

  const [editMicrositeMode, setEditMicrositeMode] = useState(micrositeId!==undefined&&coverpageId!==undefined&&templateId!==undefined);
  const [editCreateMicrositeMode,setEditCreateMicrositeMode] = useState(selectedCover!==undefined&&selectedCover!==null&&selectedCover!=="null")

  //Get Actual Portal
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  //Fetch Data with actual PortalId
  const { data, isLoading } = useQuery(
    GetCoverpagesByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-and-network",
    }
  );

  const changeCoverpageFunc = (coverpageId, templateId) => {
    const arr = showroomids.split(",");
    const editedMicrosite = {
      _id: micrositeId,
      name:"changecover",
      showroomsIds: arr,
      coverpageId:coverpageId,
      templateId:parseInt(templateId),
      portalId: portal?.portalId,
    }
    const { updatedAt, accountId, createdAt, creator, id ,__typename , ...edited } =
    editedMicrosite;
  let errors = "";
  EditMicrositeMutation(edited, response => {
    responseHandler(response, "Microsite Updated Successfully", ({ _id, showroomsIds, templateId, coverpageId}) =>{
      navigation(`/microsite/${showroomsIds.toString()}/${templateId}?id=${coverpageId}&micrositeid=${_id}`)

    })
  });
  };

  if (isLoading) return <IsLoading isCoverpage />;

  // Remove logging after usage
  return (
    <>
    {editMicrositeMode?
    <List
        mode={mode}
        isInStepOne={true}
        ShowRoomIds={showroomids}
        checking={true}
        title="My Cover Pages "
        logo={logo1}
        isList
        bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/bg-coverpages.png"
        items={data && data?.getCoverpagesByPortalId?.coverpages}
        isLoading={isLoading}
        maxPerPage={9}
        editMicrositeSelectedCoverpage={coverpageId}
        editMicrositeSelectedTemplate={templateId}
        changeCoverpageFunc={changeCoverpageFunc}
        editMicrositeCoverpageMode={true}
      ></List>:
      editCreateMicrositeMode?
      <List
        mode={mode}
        editMicrositeSelectedCoverpage={selectedCover}
        editMicrositeSelectedTemplate={templateId}
        isInStepOne={true}
        ShowRoomIds={showroomids}
        checking={true}
        title="Cover Pages List"
        subtitle="This cotains all coverpages starting with the most recently created"
        bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/bg-coverpages.png"
        items={data && data?.getCoverpagesByPortalId?.coverpages}
        isLoading={isLoading}
        maxPerPage={9}
      ></List>:<List
      mode={mode}
      isInStepOne={true}
      ShowRoomIds={showroomids}
      checking={true}
      title="Cover Page"
      logo={logo1}
      subtitle="This cotains all coverpages starting with the most recently created"
      bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/bg-coverpages.png"
      items={data && data?.getCoverpagesByPortalId?.coverpages}
      isLoading={isLoading}
      maxPerPage={9}
    ></List>}
    </>
  );
}

export default CoverPageListSelect;
