import graphql from "babel-plugin-relay/macro";


const CoverPagesQuery = graphql`
  query GetCoverPagesQuery {
    coverpages {
      ... on ListOfCoverPages {
        coverpages {
          _id
          id
          portalId
          name
          mainHeading
          details
          templateId
          thumbnail
          tags
          showroomIds
          showroomLink
          creator
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default CoverPagesQuery;
