import graphql from "babel-plugin-relay/macro";

const MicrositeByCoverpageId = graphql`
  query MicrositeByCoverpageIdQuery($coverpageId: ID!) {
    micrositeByCoverpageId(coverpageId: $coverpageId) {
      ... on Response {
        __typename
        message
        status
      }
      ... on Microsite {
        __typename
        coverpageId
        name
      }
    }
  }
`;

export default MicrositeByCoverpageId;
