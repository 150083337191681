import styled from "styled-components";
import "./PopUp.scss";
import PropTypes from "prop-types";
import { Button } from '../../../../containers/buttons';

const Wrapper = styled.div`
  max-width: ${props => props.maxWidth};
`

function PopUp({ children,listClassName,  state, hide, width, maxWidth, style, setShowList }) {
  return (
    <Wrapper>
      {state === true ? (
        <div className="shared-popup" style={style}>
          <div
            onClick={hide}
            role="button"
            aria-hidden="true"
            className="backdrop"
          ></div>
          <div className="popup-frame" style={{width: width || "40%", maxWidth: maxWidth }}>
            <div className={listClassName}>
              {children}
            </div>
            {listClassName && <Button onClick={() => setShowList(false)} m='5px' mr="8px" bg="#595959" bold>Done</Button>}
          </div>
        </div>
      ) : null}
    </Wrapper>
  );
}
export default PopUp;
PopUp.propTypes = {
  children: PropTypes.node,
  state: PropTypes.bool,
  hide: PropTypes.any,
};
