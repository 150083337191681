import styles from "./Banner.module.scss";
import {ReactComponent as Premium} from "../../../assets/images/premium.svg";
import { LOGIN_URL } from "constants/env";
import { useTranslation } from "react-i18next";
export default function Banner({ title, bgImage, desc,button }) {
  const {t} = useTranslation();
  return (
    <div className={styles.banner}>
        <div
          style={{ backgroundImage: `url("${bgImage}")` }}
          className={styles.image}
        >
          <div className={styles.content}>
            <h2>{t(title)}</h2>
            <p>{t(desc)}</p>
            {button && <button onClick={()=> window.location.href=`${LOGIN_URL}/hub/payments`}>{t(button)} <Premium/> </button>}
          </div>
        </div>
    </div>
  );
}
