import List from "containers/lists";
import { useQuery } from "relay-hooks";
import MicrositesQuery from "graphql/queries/GetMicrositesQuery";
import MicrositesByPortalQuery from "graphql/queries/GetMicrositesByPortal";
import DeleteMicrositeMutation from "graphql/mutations/DeleteMicrositeMutation";
import EditMicrositeMutation from "graphql/mutations/EditMicrositeMutation";
import ShowroomsByPortalQuery from "graphql/queries/showroom/ShowroomsByPortal";
import { useEffect, useState } from "react";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { useNavigate } from "react-router-dom";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import IsLoading from "../IsLoading";
import responseHandler from "helpers/responseHandler";
import {ReactComponent as Logo} from "assets/images/micrositelogo.svg";
export function MicrositesList() {
  //Get Actual Portal
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const navigation = useNavigate();
  const [micrositesArr, setMicrositesArr] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const microsites = useQuery(
    MicrositesByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );

  //   const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showrooms = useQuery(
    ShowroomsByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );
  //   const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteFunc = id => {
    setIsDeleting(true);
    DeleteMicrositeMutation(id, response => {
      responseHandler(response, "Microsite Deletion Successfully", () =>{
        navigation("/microsites");
      })
      setIsDeleting(false);
    });
  };

  const handleEdit = microsite => {
    const { updatedAt, accountId, createdAt, creator, id ,__typename , ...edited } =
      microsite;
    let errors = "";
    if (!edited.name) {
      errors = "Name";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} is required`);
    }
    EditMicrositeMutation(edited, response => {
      responseHandler(response, "Microsite Updated Successfully")
    });
  };

  useEffect(() => {
    if (microsites.isLoading) return;
    setMicrositesArr(microsites?.data?.micrositesByPortal?.microsites);
  }, [microsites.isLoading, microsites?.data]);

  if(microsites.isLoading) return <IsLoading isMicrosite/>

  return (
    <List
      isList
      title="Microsites"
      bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/covert2.png"
      items={micrositesArr || []}
      isLoading={microsites.isLoading}
      microsite={true}
      micrositeShowrooms={showrooms?.data?.showroomsByPortalId?.showrooms}
      deleteFunc={deleteFunc}
      handleEdit={handleEdit}
      maxPerPage={9}
      isDeleting={isDeleting}
      logosvg={<Logo/>}
    ></List>
  );
}

export default MicrositesList;
