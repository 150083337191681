import { ExamplesSlider } from "containers/Templates/showrooms/examplesSlider";
import styles from "./LimitModal.module.scss";
import { IoClose } from "react-icons/io5";
import { micrositesExamples } from "containers/Templates/showrooms/data";
import {
    LOGIN_URL,
  } from "constants/env";
export default function LimitModal({close}){
    return(
      <div className={`${styles.modal} limitmodal`}>
            <div className={styles.frame}>
                <span onClick={close}><IoClose/></span>
                <div className={styles.content}>
                <h1>Maximum Limit Reached</h1>
                <p>You have reached the maximum number of microsites allowed.</p>
                <button
                onClick={()=> window.location.href = `${LOGIN_URL}/hub/payments`}
                >Go to Licenses</button>
                </div>
                <ExamplesSlider items={3} examples={micrositesExamples}/>
            </div>
            </div>
        );
}