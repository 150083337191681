import styled from "styled-components";
import { FlexContainer } from "containers/flexContainer";

export const Wrapper = styled.li`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 0px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  flex-direction: row-reverse;
  justify-content: space-between;

  input {
    visibility: hidden;
    width: 0px;
  }

  label {
    text-transform: capitalize;
    color: black!important;
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const Checkmark = styled(FlexContainer)`
  border: 1px solid #707070;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  align-items: center;
  justify-content: center;
  &.checked {
    background:green;
  }

    .innerCircle {
      border-radius: 100%;
      height: 12px;
      width: 12px;
      background: #b1b5bc24;
    }
`;
