import React, { useContext, useEffect } from "react";
import "./assets/css/App.css";
import "./assets/scss/globals.scss";
import Routes from "./routes";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import "./App.scss";
import { MasterToast } from "@master-wizr/toast";
import {
  TemplateContentContext,
  TemplateContext,
} from "context/TemplateContentContext";
import {
  authenticateUser,
  deleteAuthData,
  deleteProfileData,
  getUserData,
} from "helpers/authHandler";
import environment from "./graphql/environment/index.js";
import { getCookie } from "helpers";
import { getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import useWatchUserUpdates from "graphql/subscriptions/useWatchUserUpdates";
import useWatchPortalUpdates from "graphql/subscriptions/useWatchPortalUpdates";
import { WatchPortalUpdatesStudio } from "graphql/subscriptions/Watch";
import "./tour.scss";
import { useTranslation } from "react-i18next";
// Initialize Suspense
const { Suspense } = React;

function MainAppComponent({ portalId }) {
  const { _id } = getUserData();
  useWatchUserUpdates(_id);
  useWatchPortalUpdates(portalId, WatchPortalUpdatesStudio);
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

function AppWithUserData() {
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const {
    i18n: { changeLanguage }
  } = useTranslation();

  // Remove logging after usage
  const [authenticated, userData] = authenticateUser();

  const { context, setContext } = useContext(TemplateContext);

  //THIS SETTER SHOULD BE DELETED AFTER CONTEXT GETS FULLY DELETED
  useEffect(() => {
    changeLanguage(profile?.locale?.toLowerCase() || 'en');
    setContext(prevState => {
      return {
        ...prevState,
        userInfo: userData,
      };
    });

  }, [profile?.locale]);

  if (!profile?.accountId) return null;
  return (
    <MainAppComponent portalId={portal.portalId} profileId={profile._id} />
  );
}
function AppWithoutUserData() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

function App({ isSharingMode }) {
  return isSharingMode ? <AppWithoutUserData /> : <AppWithUserData />;
}

function AppRoot() {
  const [authenticated, userData] = authenticateUser();
  const isSharingMode = window.location.pathname.includes("/share/");
  useEffect(() => {
    document.querySelector("body").classList.add("studio-body");
  }, []);

  //If not authenticated, then delete Token
  if (!authenticated && !isSharingMode) {
    deleteAuthData(); // this function will direct the user to login if he is not authenticated
    deleteProfileData();
  }
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={"Loading..."}>
        <MasterToast autoClose={3000} />
        <TemplateContentContext
          isSharingMode={isSharingMode}
          userData={userData}
        >
          <App isSharingMode={isSharingMode} />
        </TemplateContentContext>
      </Suspense>
    </RelayEnvironmentProvider>
  );
}

export default AppRoot;
