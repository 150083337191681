import React, { useState, useEffect } from "react";
import { useQuery } from "relay-hooks";
import Checkbox from "containers/checkbox";
import PopUp from "../PopUp/PopUp";
import ShowroomsQuery from "graphql/queries/GetShowroomsQuery";
import { arrayEquals } from "helpers";


const AddShowroom = ({addShowroomIds, showroomIds, showList, setShowList, left, top, height}) => {
    const { data, isLoading } = useQuery(
        ShowroomsQuery,
        { },
        {
          fetchPolicy: "store-and-network",
        }
    );
    const [showroomID, setshowroomID] = useState([])

    const setshowroom = (showroom, index) => {
        if(!showroomID.find(id => (id === showroom))) {
            setshowroomID([ ...showroomID, showroom]) 
        }  
        else {
            setshowroomID(showroomID.filter(showroomId => (showroomId !== showroom))) 
        }  
        
    }

    useEffect(() => {
        addShowroomIds(showroomID)
    },[showroomID])
    
    useEffect(() => {
        if(!arrayEquals(showroomIds, showroomID)) {
          setshowroomID(showroomIds)
        }
    },[showroomIds])

    return (
        !isLoading && 
        <>
            <PopUp setShowList={setShowList} listClassName="list" state={showList} style={{top: top || "5%", left: left || "70%", height: height || "90%"}}>
                {
                    data && data.showrooms.showrooms.map((showroom, index) => 
                        <Checkbox
                            checked={showroomID.find( id => id === showroom._id)}
                            onChange={() => setshowroom(showroom._id, index)}
                            labelText={showroom.name}
                        />  
                    )
                }
            </PopUp>
        </>
    )
}

export default AddShowroom