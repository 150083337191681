// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";

// 2
const mutation = graphql`
  mutation EditMagazineMutation($editMagazineId: ID!, $magazine: editMagazine) {
    editMagazine(id: $editMagazineId, magazine: $magazine) {
      ... on Magazine {
        _id
        id
        name
        backgroundColor
        backgroundImage
        mainTemplateId
        subTemplateId
        portalId
        rows {
          content {
            heading
            subHeading
            description
            headerImage
            fileUrl
            videoThumbnail
            cardThumbnail
            headerOverlayContent
            linkTo
            buttonText
            graphData {
              attr1
              attr2
              attr3
              data {
                name
                val1
                val2
                val3
              }
            }
          }
          isShown
        }
        thumbnail
        tags
        creator
        accountId
        createdAt
        updatedAt
      }
      ... on Response {
        message
        status
        success
        token
        shared
        hash
        userId
        __typename
      }
    }
  }
`;

export default (editMagazineId, magazine, callback) => {
  // 4
  const variables = {
    editMagazineId,
    magazine,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      // Temporary Acces to Store In Console for testing.
      window.store = store;
    },
  });
};
