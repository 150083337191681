import styled from "styled-components";
import F404 from "assets/images/saved404.png";
const StyledDiv = styled.div`
  height: 100vh;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledHeader = styled.p`
  font: normal normal 600 173px/180px Raleway;
  letter-spacing: 0px;
  color: #cbcbcb;
`;
const StyledP = styled.p`
  font: normal normal normal 63px/74px Raleway;
  letter-spacing: 0px;
  color: #cbcbcb;
`;
const StyledImage = styled.img`
  width: 130px;
  margin-bottom: -20px;
`;
export default function NotFound() {
  // Any functionality to show the user the page not found
  // message
  return (
    <StyledDiv>
      <StyledImage src={F404} />
      <StyledHeader>404</StyledHeader>

      <StyledP>Page not found</StyledP>
    </StyledDiv>
  );
}
