/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type newMicrosite = {|
  name: string,
  showroomsIds?: ?$ReadOnlyArray<string>,
  tags?: ?$ReadOnlyArray<string>,
  backgroundImage?: ?string,
  coverpageId?: ?string,
  templateId?: ?number,
  portalId: string,
  creator: string,
  accountId: string,
|};
export type CreateMicrositeMutationVariables = {|
  microsite?: ?newMicrosite
|};
export type CreateMicrositeMutationResponse = {|
  +createMicrosite: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +showroomsIds?: ?$ReadOnlyArray<string>,
    +backgroundImage?: ?string,
    +coverpageId?: ?string,
    +templateId?: ?number,
    +tags?: ?$ReadOnlyArray<string>,
    +portalId?: string,
    +creator?: string,
    +accountId?: string,
    +createdAt?: ?any,
    +updatedAt?: ?any,
    +__typename: "Response",
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +userId?: ?string,
  |}
|};
export type CreateMicrositeMutation = {|
  variables: CreateMicrositeMutationVariables,
  response: CreateMicrositeMutationResponse,
|};
*/


/*
mutation CreateMicrositeMutation(
  $microsite: newMicrosite
) {
  createMicrosite(microsite: $microsite) {
    __typename
    ... on Microsite {
      _id
      id
      name
      showroomsIds
      backgroundImage
      coverpageId
      templateId
      tags
      portalId
      creator
      accountId
      createdAt
      updatedAt
    }
    ... on Response {
      __typename
      message
      status
      success
      token
      hash
      userId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "microsite"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "microsite",
    "variableName": "microsite"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showroomsIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverpageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Microsite",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMicrositeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createMicrosite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMicrositeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createMicrosite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91c3eac5195f495b0d3bc090b4d5752e",
    "id": null,
    "metadata": {},
    "name": "CreateMicrositeMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMicrositeMutation(\n  $microsite: newMicrosite\n) {\n  createMicrosite(microsite: $microsite) {\n    __typename\n    ... on Microsite {\n      _id\n      id\n      name\n      showroomsIds\n      backgroundImage\n      coverpageId\n      templateId\n      tags\n      portalId\n      creator\n      accountId\n      createdAt\n      updatedAt\n    }\n    ... on Response {\n      __typename\n      message\n      status\n      success\n      token\n      hash\n      userId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c767672f72d6bb661bfaf101da0a2d51';

module.exports = node;
