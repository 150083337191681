import styled from "styled-components";
import ShareIcon from "../../../../containers/shareIcon";
import { FlexContainer } from "containers/flexContainer";

export const Title = styled.p`
  margin: 0px;
`

export const ShareButton = styled(ShareIcon)`
    position: absolute;
    bottom: 8%;
    right: 8%;
    cursor: pointer;
`

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.62);
    z-index: 100;
`

export const ContentWrapper = styled.div`
    position: fixed;
    bottom: 20px;
    height: 25vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ShowRoomCard = styled(FlexContainer)`
    width: 22%;
    padding: 8px;
    background: #202020;
    box-shadow: 0px 3px 20px #000000;
    border-radius: 5px;
    margin-right: 20px;
`

export const CloseButton = styled.span`
    text-decoration: underline;
    color: white;
    position: fixed;
    bottom: calc(20px + 25vh + 20px);
    font-weight: 600;
    cursor: pointer;
`