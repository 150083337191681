const micrositesQueryName = "micrositesByPortal";
const coverpagesQueryName = "getCoverpagesByPortalId";
const showroomsQueryName = "showroomsByPortalId";
const magazinesQueryName = "magazinesByPortalId";

export const getCachedMicrosites_Id = (parametersString = "") => {
  return `client:root:${micrositesQueryName}(${parametersString})`;
};

export const getCachedShowrooms_Id = (parametersString = "") => {
  return `client:root:${showroomsQueryName}(${parametersString})`;
};

export const getCachedCoverpages_Id = (parametersString = "") => {
  return `client:root:${coverpagesQueryName}(${parametersString})`;
};

export const getCachedMagazines_Id = (parametersString = "") => {
  return `client:root:${magazinesQueryName}(${parametersString})`;
};
