import "./style.scss";
import PropTypes from "prop-types";
import { useState , useEffect, useContext,  useCallback, useRef} from "react";
import { ReactComponent as FullScreen } from "assets/images/fullscreen.svg";
import { ReactComponent as ExitScreen } from "assets/images/exitfullscreen.svg";
import { ReactComponent as Close } from "assets/images/icon-close.svg";
import { useNavigate, useLocation } from 'react-router-dom';
import MWMenu from "../../ui/atoms/UniversalMenuComponent/MwMenu";
import UniversalMenu from "../../ui/atoms/UniversalMenuComponent/UniversalMenuComponent";
import { TemplateContext } from "context/TemplateContentContext";
import IsLoading from "../../ui/atoms/IsLoading";
import { useTour } from "@reactour/tour";
import ProgressBar from "components/ui/atoms/ProgressBar/ProgressBar";
import stepgif from "assets/images/stepgif.gif";
import { getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import selectData from "graphql/mutations/selectData";
import useWindowDimensions from "components/ui/atoms/windowDimensions";
import useOrientation from "components/ui/atoms/windowDimensions/orientation";
function General({ children }) {
  const [fullScreen, setFullScreen] = useState(false);
  const [state, setState] = useState({});
  const location = useLocation();
  const [pathName, setPathName] = useState(location.pathname);
  const [loadingContent, setLoadingContent] = useState(true);
  const { currentStep, setCurrentStep, steps, setIsOpen } = useTour();
  const [isLoading, setIsLoading] = useState(false);
  const sidebar = getClientData(useQuery, clientOnly, {}, "Sidebar");
  const dimensions = useWindowDimensions();
  const orientation = useOrientation();
  const checkElementInDOM = useCallback(async () => {
    const foundStep = steps[currentStep+1];
    if (!foundStep) return; 

    const elementInDOM = document.querySelector(foundStep?.selector);
    if (elementInDOM) {
      setIsOpen(true);
      setIsLoading(false);
      setCurrentStep(currentStep+1);
    } else {
      setIsOpen(false);
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000)); 
      checkElementInDOM(); 
    }
  }, [currentStep, setCurrentStep, setIsOpen, steps]);
  useEffect(() => {
    const handlePathnameChange = async () => {
if (location.pathname.includes(steps[currentStep+1]?.pathname))  {
        // Check if the element associated with the next step is in the DOM
        checkElementInDOM();
      }
    };
    if (!isLoading) {
      handlePathnameChange();
    }
    const popstateListener = () => {
      handlePathnameChange();
    };
    window.addEventListener("popstate", popstateListener);

    return () => {
      window.removeEventListener("popstate", popstateListener);
    };
  }, [location.pathname, currentStep, steps, checkElementInDOM, isLoading]);
  let elem = document.documentElement;
  const openFullscreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { 
    elem.webkitRequestFullscreen();
  }
}
  const closeFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }  else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
  }

  const exitHandler = () => {
    if (!document.fullscreenElement 
        && !document.webkitIsFullScreen 
        && !document.mozFullScreen 
        && !document.msFullscreenElement) {
      setFullScreen(false);
    }
  };

  useEffect(() => {
      document.addEventListener('fullscreenchange', exitHandler);
      document.addEventListener('webkitfullscreenchange', exitHandler);
      document.addEventListener('mozfullscreenchange', exitHandler);
      document.addEventListener('MSFullscreenChange', exitHandler);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    }
  }, []);

  useEffect(()=>{
    if(pathName!==location.pathname){
      setState(oldState => ({
        ...oldState,
        showMenu: false,
      }));
      setPathName(location.pathname);
    };
    setLoadingContent(false);
  },[location.pathname])
  const toggleMenu = () => {
    selectData({ ...sidebar, show: !sidebar?.show }, "Sidebar");
  };
  const magazineIndex = window.location.pathname?.indexOf("magazine/");
  const magazineId = window.location.pathname?.slice(magazineIndex + "magazine/".length);

  const isSharingMode = window.location.pathname.includes("/share/");
  const isShowroom = window.location.pathname.includes("/showrooms/") || window.location.pathname.includes("/showroom-template-expandable/") ;
  const isCoverpage = window.location.pathname.includes("/coverpage-detailed/");
  const isMicrosite = window.location.pathname.includes("/microsite/");
  const isMagazine= window.location.pathname===(`/magazine/${magazineId}`) && !window.location.pathname?.includes("/my-magazines") && magazineId?.length>1;
  const isCreateMagazine= window.location.pathname.includes("/create");

  const showUniversalMenu = () => {
    setState(oldState => ({
      ...oldState,
      showMenu: !oldState.showMenu,
    }));
  };
  const { context } = useContext(TemplateContext);

  useEffect(() => {
    if (context?.showroomDetails?.backgroundImage) {
      document.body.classList.add('bg-images');
    } else {
      document.body.classList.remove('bg-images');
    }
    return () => {
      document.body.classList.remove('bg-images');
    };
  }, [context?.showroomDetails?.backgroundImage]);
  return (
    <div className={`general-page-wrapper ${context?.showroomDetails?.backgroundImage !== null && `backdrop-showroom`} ${!isSharingMode && dimensions?.width<=1024 && `mobile-cover-image`}
    ${orientation==="landscape" && dimensions?.width<=1024 && `landscape-content-wrapper`}
    `}
      style={{backgroundImage: context?.showroomDetails?.backgroundImage !== null? `url(${context?.showroomDetails?.backgroundImage})` : ``}}
    >
      {isLoading &&
      <div className="loading__popover">
        <ProgressBar currentStep={currentStep} totalSteps={steps.length}/>
        <div>
        Preparing the next step... 
        <img alt="stepgif" src={stepgif} />
        </div>
      </div>
      }
      <div className="themeafter" />
      {loadingContent ? (
        <IsLoading title="Studio" isContent/>
      ) : (
        <>
          {!isSharingMode && !isShowroom && !isCoverpage && !isMicrosite && !isMagazine && !isCreateMagazine && (
            <div className="universalmenu-icon">
              <MWMenu onClick={toggleMenu} />
            </div>
          )}
           {!isSharingMode && !isShowroom && !isCoverpage && !isMicrosite && !isMagazine && !isCreateMagazine && (
             <UniversalMenu
            showMenu={sidebar?.show}
            handleShowMenu={toggleMenu}
          />
          )}
          <div className="bottom-main-btns">
            {fullScreen ? (
              <ExitScreen onClick={() => { setFullScreen(false); closeFullscreen() }} />
            ) : (
              <FullScreen onClick={() => { setFullScreen(true); openFullscreen() }} />
            )}
          </div>
          {children}
        </>
      )}
    </div>
  );
  
}
export default General;
General.propTypes = {
  children: PropTypes.object,
};
