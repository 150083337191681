// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";

// 2
const mutation = graphql`
  mutation EditCoverpageMutation($editCoverpageId: ID!, $coverpage: editCoverpage) {
    editCoverpage(id: $editCoverpageId, coverpage: $coverpage) {
      ... on Coverpage {
      _id
      id
      name
      mainHeading
      details
      templateId
      thumbnail
      tags
      showroomIds
      showroomLink
      creator
      accountId
      createdAt
      updatedAt
    }
    ... on Response {
      message
      status
      success
      token
      hash
      userId
      __typename

    }
    }
  }
`;

export default (editCoverpageId, coverpage, callback) => {
  // 4
  const variables = {
    editCoverpageId,
    coverpage,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      // Temporary Acces to Store In Console for testing.
      window.store = store;
    },
  });
};
