// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";

// 2
const mutation = graphql`
  mutation CreateCoverpageMutation($coverpage: newCoverpage) {
    createCoverpage(coverpage: $coverpage) {
      __typename
      ... on Coverpage {
          _id
          id
          name
          portalId
          mainHeading
          details
          templateId
          thumbnail
          tags
          showroomLink
          creator
          accountId
          updatedAt
          createdAt
          showroomIds
      }
      ... on Response {
        __typename
        message
        status
        success
        token
        hash
        userId
      }
    }
  }
`;

export default (coverpage, callback) => {
  // 4
  const variables = {
    coverpage,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      window.store = store;
    },
  });
}; // 3
