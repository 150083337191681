import React from "react";
import "./SharedText.scss";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import {
  headingInit,
  subBodyInit,
  focusEventHandlerEditor,
  subHeadingInit,
} from "./editorSettings";
import { useTranslation } from "react-i18next";

function SharedText({
  title,
  description,
  isSubHead,
  onChangeEditor,
  disabled,
  disableEditor,
  index,
  noId = false,
  noTitle = false,
  cardIndex,
  isShowroom,
  isShowroomLink,
  redirectEvent,
  showroomLink,
}) {
  const { t } = useTranslation();
  let init = headingInit();
  let subHeadingInitObj = subHeadingInit(isShowroom ? 80 : 250);
  let subHeadingInitObj80 = subHeadingInit(80)
  init.placeholder = t(init.placeholder);
  subHeadingInitObj.placeholder = t(subHeadingInitObj.placeholder);
  subHeadingInitObj80.placeholder = t(subHeadingInitObj80.placeholder);
  const subBody = (cardIndex) => {
    let subBodyObj = subBodyInit(cardIndex);
    subBodyObj.placeholder = t(subBodyObj.placeholder);
    return subBodyObj;
  }
  return (
    <div className="main-shared-text">
      <div>
        {noTitle ? (
          ""
        ) : noId ? (
          <Editor
            value={title}
            init={init}
            onEditorChange={onChangeEditor("mainHeading")}
            disabled={disabled || disableEditor || false}
            onInit={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
            onFocus={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
          />
        ) : (
          <Editor
            id="main-heading"
            value={title}
            init={init}
            onEditorChange={onChangeEditor("mainHeading")}
            disabled={disabled || disableEditor || false}
            onInit={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
            onFocus={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
          />
        )}
      </div>
      <h3 className={`${isSubHead ? "main-description" : "description"}`}>
        {isSubHead ? (
          noId === true ? (
            <Editor
              value={description}

              init={subHeadingInitObj}
              onEditorChange={onChangeEditor("subHeading")}
              disabled={disabled || disableEditor || false}
              onInit={event =>
                focusEventHandlerEditor(event, "tox-toolbar__primary")
              }
              onFocus={event =>
                focusEventHandlerEditor(event, "tox-toolbar__primary")
              }
            />
          ) : (
            <Editor
              id="main-sub-heading"
              value={description}

              init={subHeadingInitObj}
              onEditorChange={onChangeEditor("subHeading")}
              disabled={disabled || disableEditor || false}
              onInit={event =>
                focusEventHandlerEditor(event, "tox-toolbar__primary")
              }
              onFocus={event =>
                focusEventHandlerEditor(event, "tox-toolbar__primary")
              }
            />
          )
        ) : noId === true ? (
          <Editor
            value={description}
            init={subBody(cardIndex)}
            onEditorChange={onChangeEditor(`body`)}
            disabled={disabled || disableEditor || false}
            onInit={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
            onFocus={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
          />
        ) : (
          <Editor
            // id={`minor-heading-${index}`}
            value={description}
            init={subBody(cardIndex)}
            onEditorChange={onChangeEditor(`body-${index}&${cardIndex}`)}
            disabled={disabled || disableEditor || false}
            onInit={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
            onFocus={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
          />
        )}
      </h3>
      {isShowroomLink && (
        <h3 onClick={redirectEvent} className="showroom-link">
          <Editor
            value={t(showroomLink)}
            init={subHeadingInitObj80}
            onEditorChange={onChangeEditor("showroomLink")}
            disabled={disabled || disableEditor || false}
            onInit={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
            onFocus={event =>
              focusEventHandlerEditor(event, "tox-toolbar__primary")
            }
          />
        </h3>
      )}
    </div>
  );
}

export default SharedText;
SharedText.propTypes = {
  title: PropTypes.string,
  isSubHead: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  disableEditor: PropTypes.bool,
  index: PropTypes.number,
  noId: PropTypes.bool,
  noTitle: PropTypes.bool,
  isShowroom: PropTypes.bool,
  showroomLink: PropTypes.string,
};
