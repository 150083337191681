import React from "react";
import PropTypes from "prop-types";
import "./input.scss";

function Input({ type, name, value, onChange, nrlength, required = false, ...rest }) {
  return (
    <input
      value={value}
      type={type}
      name={name}
      onChange={onChange}
      required={required}
      maxLength={nrlength}
      {...rest}
    />
  );
}

export default Input;
Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  nrlength: PropTypes.string
};

Input.defaultProps = {
  onChange: () => {},
};
