import React from 'react';
import styled from 'styled-components';
import Modal from './index';
import { Button, DangerButton } from './../buttons';
import { FlexContainer } from './../flexContainer';
import { Text } from './../input';
import {Link} from 'react-router-dom';

export const Heading = styled.span`
    font-size: ${props => props.fSize || '36px'};
    margin-bottom: ${props => props.mb || '15px'};
    font-weight: bold;
    color: #fff;
    @media screen and (min-width: 3000px) {
        font-size: 40px;
    }
     @media screen and (min-width: 4000px) {
        font-size: 55px;
    }`
    

export const UnsavedContainer = styled(FlexContainer)`
    @media screen and (min-width: 3000px) {
        padding: 40px;
    }
    @media screen and (min-width: 4000px) {
        padding: 50px;
    }
`   
export const Buttons = styled(FlexContainer)`
    @media screen and (min-width: 3000px) {
        padding: 15px 30px 40px;
    }
    @media screen and (min-width: 4000px) {
        padding: 20px 40px 50px 40px;
        gap: 40px;
    }
`   

const SavingModal = (props) => {
    const handleCloseModal = async (e) => {
    }
    return (
        <Modal size="md" open={true} onHide={props.onHide || handleCloseModal}>
            <FlexContainer width='100%' hCenterAlign vCenterAlign>
                <UnsavedContainer width='100%' columns pd="20px 30px" btlr="18px" btrr="18px" bt="1px solid rgba(0, 0, 0, 0.75)" >
                    <FlexContainer spaceBetween hCenterAlign pb="15px" pt="5px">
                        <Heading fontWeight="600" color="#ffff"  fSize="24px">{`Unsaved changes`}</Heading>
                    </FlexContainer>
                    <FlexContainer pb="5px" hCenterAlign>
                        <Text color='rgba(255, 255, 255, 0.9)'  fSize="15px">{`Would you like to save your changes before proceeding?`}
                        </Text>
                    </FlexContainer>
                </UnsavedContainer>
                <Buttons width='100%' hCenterAlign vCenterAlign pd="0px 30px 30px" bblr="18px" bbrr="18px" bb="1px solid rgba(0, 0, 0, 0.75)" gap="30px">  
                    <DangerButton bg='#EE780D' color="#fff" width='120px' br='5px' onClick={props.handleSave}>Yes</DangerButton>
                    <Link to="/templates"><Button color="#fff" width='120px' br='5px' bg='#575757' onClick={handleCloseModal}>No</Button></Link>
                </Buttons>
            </FlexContainer>
        </Modal>
    )
}

export default SavingModal;
