import React from "react";
import styles from "./ImgModal.module.scss";

function ImgModal({children,title,description,state,extraClass}){
    return(
        <>
        {state &&
        <div className={`${styles.imgmodal} ${styles[extraClass]}`}>
            <div className={styles.backdrop}/>
             <div className={`${styles.frame} framethemes`}>
            <div className={`${styles.imagepart} imagepart`}/>
            <h1 className={styles.title}>
                {title}
            </h1>
                <p className={styles.text}>{description}</p>
                {children}
             </div>
           </div>
        }
        </>
    );
}
export default ImgModal;