import styled, { css } from "styled-components";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

const ButtonStyling = styled(Button)`
  border-radius: 35px;
  padding: 7px 44px;
  border: none;
  height: 35px;
  font: normal normal 600 14px/19px Raleway;
  border: 1px solid transparent;
  &:focus,
  &:hover {
    background: #232323 0% 0% no-repeat padding-box;
    border: 1px solid #7b7b7b;
  }
  &:focus {
    background: #232323 0% 0% no-repeat padding-box;
    border: 1px solid #7b7b7b;
    outline: 0;
    box-shadow: none;
  }
  &:hover {
    background: #232323 0% 0% no-repeat padding-box;
    border: 1px solid #7b7b7b;
  }
  ${props =>
    props.cancelbutton &&
    css`
      background: #151515;
      color: #c3c3c3;
      letter-spacing: 0;
      margin-right: 1rem;
    `}
  ${props =>
    props.submitbutton &&
    css`
      width: 160px;
      background: #ec7600;
      color: #ffffff;
      letter-spacing: 0;
      float: right;
    `}
  ${props =>
    props.primary &&
    css`
      background-color: #ec7600;
      &:hover {
        background-color: #ff850b;
      }
    `}
    ${props =>
    props.orangebtn &&
    css`
      background-color: #303030;
      color: lightgray;
      border: 1px solid rgba(191, 190, 190, 0.2);
      &:hover {
        background-color: #2b2a2a;
        border: 1px solid rgba(191, 190, 190, 0.5);
      }
    `}
  ${props =>
    props.wrapper &&
    css`
      background-color: transparent;
      border: none;
      padding: 0;
      height: auto;
      border-radius: 0;
      outline: none;
      font: none;

      &:hover {
        background: transparent;
        border: none;
        outline: none;
      }

      &:focus {
        outline: 0;
        border: none;
        background: none;
      }

      &:active {
        &:focus {
          box-shadow: none;
        }
      }
    `}
  ${props =>
    props.menutab &&
    css`
      display: flex;
      align-items: center;
      padding-right: 0px;
      justify-content: space-between;
      background: #232323 0% 0% no-repeat padding-box;
      border: 1px solid #7b7b7b33;
      border-radius: 8px;
      opacity: 1;
      color: #ffffff;
      font-size: 15px;
      margin: 0 0 0 4px;
      svg {
        display: none;
      }
      &:hover {
        svg {
          display: block;
        }
      }
    `}
  ${props =>
    props.menushead &&
    css`
      width: 200px;
      height: 40px;
      background: #1c1c1c 0% 0% no-repeat padding-box;
      display: flex;
      margin-left: 20px;
      align-items: center;
      justify-content: space-between;
      background-color: #232323;
      border-radius: 8px;
      opacity: 1;
      color: #ffffff;
      font-size: 15px;
      &:focus {
        background: #232323 0% 0% no-repeat padding-box;
        border: 1px solid #7b7b7b;
        outline: 0;
        box-shadow: none;
      }
      &:hover {
        background: #232323 0% 0% no-repeat padding-box;
        border: 1px solid #7b7b7b;
      }
    `}
    ${props => props.menubutton && css``}
    ${props =>
    props.deletebutton &&
    css`
      width: 226px;
      background: grey !important;
      top: 602px;
      left: 815px;
      width: 136px;
      height: 36px;
      color: #fc9621 0% 0% no-repeat padding-box;
      border-radius: 35px;
    `}  ${props =>
    props.removebutton &&
    css`
      background: #ec6700;
      left: 815px;
      width: 136px;
      border-radius: 35px;
      top: 1006px;
      font: normal normal medium 20px/24px Raleway;
      letter-spacing: 0px;
      color: white;
      text-transform: capitalize;
      opacity: 1;
      padding: 0;
      border: 1px solid #7b7b7b33;
      &:hover {
        color: white;
        background: #ff8000;
      }
    `}
    @media screen and (min-height: 900px) {
      height: 40px;
      font: normal normal 600 16px/19px Raleway;
      padding: 10px 44px;
      ${props =>
        props.submitbutton &&
        css`
        width: 180px;
        `}
    } 
`;
const StyledButton = props => {
  const {
    submitbutton,
    cancelbutton,
    children,
    clickEvent,
    menutab,
    disabled,
    menushead,
    primary,
    wrapper,
    orangebtn,
    className,
    deletebutton,
    removebutton,
    type,
  } = props;

  return (
    <ButtonStyling
      onClick={clickEvent}
      submitbutton={submitbutton}
      cancelbutton={cancelbutton}
      menushead={menushead}
      menutab={menutab}
      primary={primary}
      wrapper={wrapper}
      orangebtn={orangebtn}
      className={className}
      deletebutton={deletebutton}
      removebutton={removebutton}
      disabled={disabled}
      type={type}
    >
      {children}
    </ButtonStyling>
  );
};

StyledButton.propTypes = {
  submitbutton: PropTypes.string,
  cancelbutton: PropTypes.string,
  menutab: PropTypes.string,
  menushead: PropTypes.string,
  children: PropTypes.any,
  clickEvent: PropTypes.func,
  primary: PropTypes.string,
  wrapper: PropTypes.string,
  orangebtn: PropTypes.string,
  className: PropTypes.string,
  deletebutton: PropTypes.string,
  removebutton: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StyledButton;
