import clientOnly from "graphql/queries/clientOnly";
import { useEffect, useState } from "react";
import { useQuery } from "relay-hooks";

function useFetch(query, variables, skip, fetchPolicy = "network-only") {
    return useQuery(query, variables, { skip, fetchPolicy });
  }

  
export const useClientFetchOnly = key => {
  const [skip, updateSkip] = useState(true);
  useEffect(() => {
    let result = true;
    if (!result) return "";
    updateSkip(false);
    return () => {
      result = false;
    };
  }, []);
  const { data } = useFetch(clientOnly, {}, skip, "store-only");
  if (!data?.[`selected${key}`] || !data[`selected${key}`].details) return null;
  return JSON.parse(data[`selected${key}`]?.details);
};
