// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";

// 2
// deleteCoverpage(id: ID!): Response
const mutation = graphql`
  mutation DeleteMagazineMutation($deleteMagazineId: ID!) {
    deleteMagazine(id: $deleteMagazineId) {
      ... on Magazine {
        _id
        id
        portalId
      }
      ... on Response {
        message
        status
        success
        token
        hash
        signedUrl
        userId
        shared
      }
    }
  }
`;

export default (deleteMagazineId, callback) => {
  // 4
  const variables = {
    deleteMagazineId,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      // Temporary Acces to Store In Consol for testing.
      window.store = store;
    },
  });
}; // 3
