import React from "react";
import styles from "./HomeCards.module.scss";
import Card from "components/ui/atoms/Card/Card";
import { cardsData } from "constants/home";
import { useTranslation } from "react-i18next";

export default function HomeCards() {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <section className={styles.cardsContainer}>
        <div className={styles.card}>
          <h1 className={styles.title}>{t("presenting-for-long-lasting-impact")}</h1>
          <div className={styles.cardsMapped}>
            {cardsData.map((d, i) => {
              return <Card key={i} {...d} />;
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
