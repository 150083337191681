/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WatchPortalUpdatesSubscriptionVariables = {|
  id: string
|};
export type WatchPortalUpdatesSubscriptionResponse = {|
  +portalUpdates: ?{|
    +_id: string,
    +name: string,
    +menus: $ReadOnlyArray<?{|
      +name: string,
      +_id: string,
      +visibility: boolean,
      +folders: $ReadOnlyArray<?{|
        +_id: string,
        +name: string,
        +presentations: $ReadOnlyArray<?{|
          +_id: string,
          +name: string,
          +files: $ReadOnlyArray<?{|
            +fileType: string,
            +fileUrl: string,
            +name: string,
            +_id: string,
          |}>,
        |}>,
      |}>,
    |}>,
  |}
|};
export type WatchPortalUpdatesSubscription = {|
  variables: WatchPortalUpdatesSubscriptionVariables,
  response: WatchPortalUpdatesSubscriptionResponse,
|};
*/


/*
subscription WatchPortalUpdatesSubscription(
  $id: ID!
) {
  portalUpdates(id: $id) {
    _id
    name
    menus {
      name
      _id
      visibility
      folders {
        _id
        name
        presentations {
          _id
          name
          files {
            fileType
            fileUrl
            name
            _id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Portal",
    "kind": "LinkedField",
    "name": "portalUpdates",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Menu",
        "kind": "LinkedField",
        "name": "menus",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visibility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Folder",
            "kind": "LinkedField",
            "name": "folders",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Presentation",
                "kind": "LinkedField",
                "name": "presentations",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "files",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchPortalUpdatesSubscription",
    "selections": (v3/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchPortalUpdatesSubscription",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "be4f416989e38cf2621722026b2bde50",
    "id": null,
    "metadata": {},
    "name": "WatchPortalUpdatesSubscription",
    "operationKind": "subscription",
    "text": "subscription WatchPortalUpdatesSubscription(\n  $id: ID!\n) {\n  portalUpdates(id: $id) {\n    _id\n    name\n    menus {\n      name\n      _id\n      visibility\n      folders {\n        _id\n        name\n        presentations {\n          _id\n          name\n          files {\n            fileType\n            fileUrl\n            name\n            _id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47d2bc92c52fc86b35780c159a53759c';

module.exports = node;
