import graphql from "babel-plugin-relay/macro";


const FilesQuery = graphql`
  query GetFilesQuery($presentationId: ID!) {
   presentation(id: $presentationId) {
    ... on Presentation {
      files {
        _id
        name
        relatedPresentationIds
        fileUrl
        fileType
        coverUrl
        thumbnailUrl
        positions {
          presentationId
          position
        }
      }
    }
  }
  }
`;

export default FilesQuery;
