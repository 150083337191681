/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editCoverpage = {|
  name?: ?string,
  mainHeading?: ?string,
  details?: ?string,
  owner?: ?InputOwner,
  thumbnail?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  showroomIds?: ?$ReadOnlyArray<?string>,
  showroomLink?: ?string,
  accountId?: ?string,
  portalId?: ?string,
  templateId?: ?number,
  templateStyle?: ?InputTemplateStyle,
|};
export type InputOwner = {|
  name: string,
  avatarUrl: string,
  job: string,
  shortBio: string,
  quote: string,
  skills: string,
  services: string,
  engagements: string,
|};
export type InputTemplateStyle = {|
  cardPosition?: ?string,
  BackgroundBlur?: ?string,
|};
export type EditCoverpageMutationVariables = {|
  editCoverpageId: string,
  coverpage?: ?editCoverpage,
|};
export type EditCoverpageMutationResponse = {|
  +editCoverpage: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +mainHeading?: string,
    +details?: string,
    +templateId?: number,
    +thumbnail?: ?string,
    +tags?: ?$ReadOnlyArray<?string>,
    +showroomIds?: ?$ReadOnlyArray<string>,
    +showroomLink?: string,
    +creator?: string,
    +accountId?: string,
    +createdAt?: any,
    +updatedAt?: ?any,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +userId?: ?string,
    +__typename: "Response",
  |}
|};
export type EditCoverpageMutation = {|
  variables: EditCoverpageMutationVariables,
  response: EditCoverpageMutationResponse,
|};
*/


/*
mutation EditCoverpageMutation(
  $editCoverpageId: ID!
  $coverpage: editCoverpage
) {
  editCoverpage(id: $editCoverpageId, coverpage: $coverpage) {
    __typename
    ... on Coverpage {
      _id
      id
      name
      mainHeading
      details
      templateId
      thumbnail
      tags
      showroomIds
      showroomLink
      creator
      accountId
      createdAt
      updatedAt
    }
    ... on Response {
      message
      status
      success
      token
      hash
      userId
      __typename
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverpage"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "editCoverpageId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "coverpage",
    "variableName": "coverpage"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "editCoverpageId"
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainHeading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "details",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showroomIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showroomLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Coverpage",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCoverpageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editCoverpage",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditCoverpageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editCoverpage",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e959703526a39f1c336888969b57224",
    "id": null,
    "metadata": {},
    "name": "EditCoverpageMutation",
    "operationKind": "mutation",
    "text": "mutation EditCoverpageMutation(\n  $editCoverpageId: ID!\n  $coverpage: editCoverpage\n) {\n  editCoverpage(id: $editCoverpageId, coverpage: $coverpage) {\n    __typename\n    ... on Coverpage {\n      _id\n      id\n      name\n      mainHeading\n      details\n      templateId\n      thumbnail\n      tags\n      showroomIds\n      showroomLink\n      creator\n      accountId\n      createdAt\n      updatedAt\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      hash\n      userId\n      __typename\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f4be3cdcbc5d4593535de060b552821';

module.exports = node;
