import graphql from "babel-plugin-relay/macro";


const MicrositeQuery = graphql`
  query GetMicrositeQuery($micrositeId: ID!) {
    microsite(id: $micrositeId) {
      ... on Microsite {
          _id
          id
          name
          showroomsIds
          tags
          backgroundImage
          coverpageId
          templateId
          portalId
          creator
          accountId
      }
    }
  }
`;

export default MicrositeQuery;
