import React, { useState } from "react";
import "./FileSlideStyles.scss";
import { ReactComponent as ArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/icon-arrow-left.svg";
import File from "components/ui/atoms/Presentations/File";
import { usePresentationFilesOrdering } from "hooks/useFileHandler";

export default function SinglePres({ data, presentationId }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sortedData = 
  usePresentationFilesOrdering(
     presentationId? presentationId : [],
     data?.files || []
   )
  const handleNextSlide = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === data.files.length - 1) {
        return 0;
      } else {
        return prevIndex + 1;
      }
    });
  };

  const handlePrevSlide = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === 0) {
        return data.files.length - 1;
      } else {
        return prevIndex - 1;
      }
    });
  };

  return (
    <div className="filesSingle">
      {data?.files?.length > 1 && (
        <div className="leftfile">
          <ArrowLeft onClick={handlePrevSlide} />
        </div>
      )}
      <div className="singlefile">
        <File
          file={sortedData?.[currentIndex]}
          data={sortedData}
          index={currentIndex}
        />
      </div>
      {data?.files?.length > 1 && (
      <div className="rightfile">
        <ArrowRight onClick={handleNextSlide} />
      </div>
       )}
    </div>
  );
}
