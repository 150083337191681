import { Image } from "../../image";
import { FlexContainer } from "containers/flexContainer";
import { useNavigate } from "react-router-dom";
import { MainWrapper, Heading, TempWrapper } from "../styles";
import { showroomTemplates } from "../../../constants/templates";
import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "relay-hooks";
import { getClientData } from "helpers/queryHandler";
import logo from "assets/images/coverpage_white.png";
import { TemplateContext } from "context/TemplateContentContext";
import clientOnly from "graphql/queries/clientOnly";
import profileQuery from "graphql/queries/showroom/profileQuery";
import ShowroomsByPortalQuery from "graphql/queries/showroom/ShowroomsByPortal.js";
import { useSubscribeToInvalidationState } from "react-relay";
import Customizer from "components/ui/organisms/Customizer";
import { setCustomizerData, getAuthData } from "helpers/authHandler";
import { STATIC_URL } from "constants/env";
import UpgradePopup from "components/ui/atoms/UpgradePopup/UpgradePopup";
import Banner from "../Banner/Banner";

import { useTour } from "@reactour/tour";
import { ExamplesSlider } from "./examplesSlider";
import { showroomExamples } from "./data";
import { useHasModuleAccess } from "hooks/useModuleAccess";
import { useTranslation } from "react-i18next";
export const ImageWrapper = styled(FlexContainer)`
  @media screen and (max-height: 722px) {
    margin-bottom: 0px;
  }
`;

export function ShowroomTemplates(props) {
  const {t} = useTranslation();
  const [activeCustomizer, setActiveCustomizer] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [detailsOfTemplate, setDetailsOfTemplate] = useState();
  const [isExpandable, setIsExpandable] = useState(false);
  const [activePortal, setActivePortal] = useState();
  const { setContext } = useContext(TemplateContext);
  const navigation = useNavigate();
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const [content, setContent] = useState(null);
  const [showLicensePopup, setShowLicensePopup] = useState(false);
  const { setCurrentStep, currentStep } = useTour();
   const modals = getClientData(useQuery, clientOnly, {}, "Modal");
   const hasTier = useHasModuleAccess()[2];
  function handleButtonClick(){
    navigation('/coverpage-templates');
    window.scrollTo(0, 0)
  }

  const { data, isLoading, retry } = useQuery(
    profileQuery,
    { profileId: profile?._id },
    {
      fetchPolicy: "store-or-network",
    }
  );

  const { data: showroomsData } = useQuery(
    ShowroomsByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );

  useSubscribeToInvalidationState(
    [`client:root:profile(id:"${profile?._id}")`],
    () => {
      setLoadData(true);
    }
  );

  const docsThumnbails = [
    {
      name: "word",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/wordthumbnail_klkmyr.png",
    },
    {
      name: "presentation",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/pptthumbnail_jqrrk9.png",
    },
    {
      name: "excel",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/excelthumbnail_zxgjwn.png",
    },
    {
      name: "pdf",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/pdfthumbnail_oycrij.png",
    },
  ];

  const checkLicense = () => {
    const license = profile?.accountInfo?.licenseInfo?.licenseType;
    if(license === "STUDENT" && showroomsData?.showroomsByPortalId?.showrooms?.length >= 2){
      setShowLicensePopup(true);
      return true;
    }
    return false;

  }

  const HandleClick = (id, nrOfRows, nrPresentation) => {
    const licenseCheckPassed = checkLicense();

    if (!licenseCheckPassed) {
      setDetailsOfTemplate({
        nrOfRows: nrOfRows,
        id: id,
        nrPresentation: nrPresentation,
      });

      setActiveCustomizer(true);
      setCustomizerData({
        state: true,
        prevTemp: {
          nrOfRows: nrOfRows,
          id: id,
          nrPresentation: nrPresentation,
        },
      });
    }
  };

  useEffect(() => {
    document.body.classList.toggle("customizer-open", activeCustomizer);
  }, [activeCustomizer]);

  useEffect(() => {
    const prevCookies = getAuthData("customizerState") || null;
    if (activePortal !== undefined && prevCookies !== null) {
      setActiveCustomizer(prevCookies?.state);
      setDetailsOfTemplate(prevCookies?.prevTemp);
      setInitialRender(false);
    }
   
  }, [activePortal]);

  useEffect(() => {
    const prevCookies = getAuthData("customizerState");
    if (!activeCustomizer && !initialRender) {
      setCustomizerData({
        state: false,
        prevTemp: prevCookies?.prevTemp || null
      });
    }
    if (!initialRender) {
      
      if (prevCookies?.state !== activeCustomizer) {
        setCustomizerData({
          state: activeCustomizer,
          prevTemp: prevCookies?.prevTemp,
        });
      }
    }
  }, [activeCustomizer, initialRender]);
  const handleFinishing = array => {
    var array2 = Object.keys(array).map(function (key) {
      return array[key];
    });
    const newContent = array2.map((item, index) => {
      return {
        name: `<p>Row ${index + 1}</p>`,
        content: item.map((element, i) => {
          const presId = element?._id;
          const presFiles1 = element?.files;
          
          const fileWithPosition1 = presFiles1?.reduce((lowestPositionFile, file) => {
            const positionInfo = file?.positions?.find(position =>
              position?.presentationId === presId
            );
            
            if (positionInfo) {
              if (!lowestPositionFile || positionInfo?.position < lowestPositionFile.position) {
                return {
                  ...file,
                  position: positionInfo?.position
                };
              }
            }
            
            return lowestPositionFile;
          }, null);
          return {
            image: `${STATIC_URL}${fileWithPosition1?.fileUrl}`,
            mainHeading: "",
            subHeading: "",
            presentationId: element._id,
            imageUrl: `${STATIC_URL}${fileWithPosition1?.fileUrl}`,
          };
        }),
      };
    });

    setContext(prevState => {
      return {
        ...prevState,
        showroomDetails: {
          ...prevState.showroomDetails,
          content: newContent,
        },
      };
    });
  };

  const finishHandle = array => {
    setActiveCustomizer(!activeCustomizer);
    handleFinishing(array);
    document.body.classList.remove("customizer-open");
    !isExpandable
      ? navigation(`/showrooms/${detailsOfTemplate?.id}`, {
          state: { detailsOfTemplate: detailsOfTemplate },
        })
      : navigation(`/showroom-template-expandable/${detailsOfTemplate?.id}`, {
          state: { detailsOfTemplate: detailsOfTemplate },
        });
  };

  useEffect(() => {
    data?.profile?.accessedPortals.map((item, index) => {
      if (portal?.portalId === item?.portalInfo?._id) {
        setActivePortal(index);
        setInitialRender(false);
      }
      return false;
    });
  }, [data, portal?.portalId]);

  useEffect(() => {
    if (!loadData || isLoading) return;
    retry(null, {
      onComplete: e => {
        setLoadData(false);
      },
    });
  }, [loadData, isLoading, retry]);

  useEffect(() => {
    if (isLoading && !data) return;
    setContent(data);
  }, [isLoading, data]);
  if (!content) return null;
  return (
    <>
          <Banner title={!hasTier && showroomsData?.showroomsByPortalId?.showrooms?.length>=6 ? "go-to-licenses" : "bring-your-representation-to-life"}
     desc={!hasTier && showroomsData?.showroomsByPortalId?.showrooms?.length>=6 ? "you-have-reached-the-maximum-number-of-showrooms" : "present-like-never-before-with-unique-show-room-templates"}
     bgImage="https://cdn.cf.masterwizr.com/Group_40567_1_1_ovvgo4.png"
     button={!hasTier && showroomsData?.showroomsByPortalId?.showrooms?.length>=6 ? "go-to-licenses" : false}
     />
        {!hasTier && showroomsData?.showroomsByPortalId?.showrooms?.length>=6 && (
          <ExamplesSlider examples={showroomExamples}/>
        )
        }
        {((!hasTier && showroomsData?.showroomsByPortalId?.showrooms?.length<6) || hasTier !== false) && (
      <MainWrapper mt="-3rem" columns className={`main-wrapper-templates main-templates-container ${!hasTier && showroomsData?.showroomsByPortalId?.showrooms?.length===6 &&  `liteFull` }`}>
      <Heading className="heading-template">{t("single")} <span> - {t("ideal-for-single-contents")}</span></Heading>
        <TempWrapper>
          {showroomTemplates.slice(11,15).map(item => (
            <div
            onClick={() => {
              HandleClick(item.id, item.nrOfRows);
              modals?.tour && setCurrentStep(currentStep+1);
            }}
              to={`/showrooms/${item.id}`}
              key={item.id}
              style={{
                width: "calc((100% - 2%) / 4)",
                marginRight: "calc(2% / 4)",
              }}
            >
              <ImageWrapper width="100%" mb="20px">
                <Image url={item.url} />
              </ImageWrapper>
            </div>
          ))}
        </TempWrapper>
        <Heading className="heading-template">{t("recommended")}<span> - {t("ideal-for-micro-sites")}</span></Heading>
        <TempWrapper>
          {showroomTemplates.slice(0, 4).map(item => (
            <div
              onClick={() => {
                HandleClick(item.id, item.nrOfRows);
                modals?.tour && setCurrentStep(currentStep+1);
              }}
              to={`/showroom/${item.id}`}
              key={item.id}
              style={{
                width: "calc((100% - 2%) / 4)",
                marginRight: "calc(2% / 4)",
              }}
            >
              <ImageWrapper width="100%" mb="20px">
                <Image url={item.url} />
              </ImageWrapper>
            </div>
          ))}
          {showLicensePopup && <UpgradePopup handleClose={() => setShowLicensePopup(false)}/>}
        </TempWrapper>
        <Heading className="heading-template">{t("portrait")}<span> - {t("ideal-for-standing-formats")}</span></Heading>
        <TempWrapper>
          {showroomTemplates.slice(4, 8).map(item => (
            <div
              onClick={() => HandleClick(item.id, item.nrOfRows)}
              to={`/showroom/${item.id}`}
              key={item.id}
              style={{
                width: "calc((100% - 2%) / 4)",
                marginRight: "calc(2% / 4)",
              }}
            >
              <ImageWrapper width="100%" mb="20px">
                <Image url={item.url} />
              </ImageWrapper>
            </div>
          ))}
        </TempWrapper>
        <Heading className="heading-template">{t("expandable")}<span> - {t("customize-with-panorama-images")}</span></Heading>
        <TempWrapper>
        {showroomTemplates.slice(8, 11).map(item => (
    <div
        onClick={() => {
            HandleClick(item.id, item.nrOfRows, item.numberOfItems);
            item?.id !== 16 && setIsExpandable(true);
        }}
        to={`/showroom-template-expandable/${item.id}`}
        key={item.id}
        style={{
            width: "calc((100% - 2%) / 4)",
            marginRight: "calc(2% / 4)",
        }}
    >
        <ImageWrapper width="100%" mb="20px">
            <Image url={item.url} />
        </ImageWrapper>
    </div>
))}
</TempWrapper>
        <Heading className="heading-template">{t("multi-single-sharing")}</Heading>
        <TempWrapper>
          {showroomTemplates.slice(15, 17).map(item => (
            <div
              onClick={() => HandleClick(item.id, item.nrOfRows)}
              to={`/showroom/${item.id}`}
              key={item.id}
              style={{
                width: "calc((100% - 2%) / 4)",
                marginRight: "calc(2% / 4)",
              }}
            >
              <ImageWrapper width="100%" mb="20px">
                <Image url={item.url} />
              </ImageWrapper>
            </div>
          ))}
        </TempWrapper>
      </MainWrapper>
        )}
      {activeCustomizer && (
        <div
          className="studiocustomizer"
          style={{
            position: "absolute",
            top: 0,
            zIndex: "99999999",
            backgroundColor: "#212121",
            maxHeight: "100vh !important",
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            left: 0,
          }}
        >
          <Customizer
            type="customizer"
            data={content?.profile?.accessedPortals?.map(
              item => item.portalInfo
            )}
            rows={detailsOfTemplate?.nrOfRows}
            presentationNumber={1}
            setActiveCustomizer={setActiveCustomizer}
            activeCustomizer={activeCustomizer}
            finishHandle={finishHandle}
            docsThumnbails={docsThumnbails}
            activePortal={activePortal}
            activeRow={0}
            templateId={detailsOfTemplate?.id}
          />
        </div>
      )}
      <button onClick={handleButtonClick}>
        <div className="showrooms-bottom-content">
          <img src={logo} className="banner-logo" alt =""/>
          <p className="explore-showrooms-bottom">{t("explore-cover-pages")}</p>
        </div>
      </button>
    </>
  );
}

export default ShowroomTemplates;
