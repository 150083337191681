/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetShowroomQueryVariables = {|
  showroomId: string
|};
export type GetShowroomQueryResponse = {|
  +showroom: ?{|
    +_id?: string,
    +id?: string,
    +name?: string,
    +mainHeading?: string,
    +subHeading?: string,
    +headerImage?: ?string,
    +templateId?: number,
    +tags?: ?$ReadOnlyArray<string>,
    +link?: ?string,
    +creator?: string,
    +coverpagesIds?: ?$ReadOnlyArray<string>,
    +backgroundImage?: ?string,
    +accountId?: ?string,
    +createdAt?: any,
    +content?: ?$ReadOnlyArray<?{|
      +name: string,
      +content: ?$ReadOnlyArray<?{|
        +image: string,
        +mainHeading: string,
        +presentationId: string,
        +subHeading: string,
        +imageUrl: string,
        +presentation: ?{|
          +coverUrl: ?string,
          +portalId: string,
          +name: string,
          +menuId: string,
          +folderId: string,
        |},
        +files: ?$ReadOnlyArray<?{|
          +_id: string,
          +name: string,
          +fileUrl: string,
          +fileType: string,
          +coverUrl: ?string,
          +thumbnailUrl: ?string,
          +positions: $ReadOnlyArray<?{|
            +presentationId: string,
            +position: number,
          |}>,
        |}>,
      |}>,
    |}>,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +signedUrl?: ?string,
    +userId?: ?string,
    +shared?: ?string,
  |}
|};
export type GetShowroomQuery = {|
  variables: GetShowroomQueryVariables,
  response: GetShowroomQueryResponse,
|};
*/


/*
query GetShowroomQuery(
  $showroomId: ID!
) {
  showroom(id: $showroomId) {
    __typename
    ... on Showroom {
      _id
      id
      name
      mainHeading
      subHeading
      headerImage
      templateId
      tags
      link
      creator
      coverpagesIds
      backgroundImage
      accountId
      createdAt
      content {
        name
        content {
          image
          mainHeading
          presentationId
          subHeading
          imageUrl
          presentation {
            coverUrl
            portalId
            name
            menuId
            folderId
          }
          files {
            _id
            name
            fileUrl
            fileType
            coverUrl
            thumbnailUrl
            positions {
              presentationId
              position
            }
          }
        }
      }
    }
    ... on Response {
      message
      status
      success
      token
      hash
      signedUrl
      userId
      shared
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showroomId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "showroomId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainHeading",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subHeading",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presentationId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverUrl",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverpagesIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NestedContent",
          "kind": "LinkedField",
          "name": "content",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            (v4/*: any*/),
            (v6/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Presentation",
              "kind": "LinkedField",
              "name": "presentation",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "portalId",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "menuId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "folderId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "files",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fileUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fileType",
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnailUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Position",
                  "kind": "LinkedField",
                  "name": "positions",
                  "plural": true,
                  "selections": [
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "position",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Showroom",
  "abstractKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signedUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shared",
      "storageKey": null
    }
  ],
  "type": "Response",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetShowroomQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "showroom",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetShowroomQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "showroom",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "baeff46353cfb371fab51b1ab698dc83",
    "id": null,
    "metadata": {},
    "name": "GetShowroomQuery",
    "operationKind": "query",
    "text": "query GetShowroomQuery(\n  $showroomId: ID!\n) {\n  showroom(id: $showroomId) {\n    __typename\n    ... on Showroom {\n      _id\n      id\n      name\n      mainHeading\n      subHeading\n      headerImage\n      templateId\n      tags\n      link\n      creator\n      coverpagesIds\n      backgroundImage\n      accountId\n      createdAt\n      content {\n        name\n        content {\n          image\n          mainHeading\n          presentationId\n          subHeading\n          imageUrl\n          presentation {\n            coverUrl\n            portalId\n            name\n            menuId\n            folderId\n          }\n          files {\n            _id\n            name\n            fileUrl\n            fileType\n            coverUrl\n            thumbnailUrl\n            positions {\n              presentationId\n              position\n            }\n          }\n        }\n      }\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      hash\n      signedUrl\n      userId\n      shared\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '602d765e293858c7113b803497f054dd';

module.exports = node;
