import { TourProvider } from "@reactour/tour";
import ProgressBar from "components/ui/atoms/ProgressBar/ProgressBar";
import { steps } from "constants/steps";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { pages } from "./pages";

export default function routes() {
  const routeHandler = (pagenames, prefix = "") => {
    return pagenames.map(route => {
      if (!route.children) {
        return (
          <Route
            key={`${prefix}${route.path}`}
            path={`${prefix}${route.path}`}
            exact={route.exact}
            element={
              <route.layout>
                <route.element />
              </route.layout>
            }
          />
        );
      }
      return routeHandler(route.children, route.path);
    });
  };
  return (
    <Router>
        <TourProvider
        rounded={10}
        disableDotsNavigation={true}
        scrollDuration={0.2}
        showDots={false}
        showPrevNextButtons={false}
        showNavigation={false}
        isHighlightingObserved={true}
        highlightedMaskClassName="highlightedItem"
        onClickMask={()=>{}}
        badgeContent={({ totalSteps, currentStep }) => (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        )}
        scrollSmooth
        steps={steps}
        highlightedAreaClassName="highlightedItem"
        maskClassName="highlightedItemMask"
      >
      <Routes>
        {routeHandler(pages)}
        <Route path="/" element={<Navigate to="/start-guide" replace />} />
      </Routes>
      </TourProvider>
    </Router>
  );
}
