import React from 'react';
import { Wrapper, Input, List, Button } from "./styles";
import { showErrorToast } from '@master-wizr/toast';

export function Tags(props) {
    const { tag, tags, handleChangeTag, handleRemoveTag, handleKeyDown } = props;

    const handleTagChange = (event) => {
        const newTag = event.target.value;
        if (newTag.length <= 19) {
            handleChangeTag(event);
        } else {
            showErrorToast("Tag length should be less than 20 characters");
        }
    };

    return (
        <Wrapper className="editTags">
            <Input
                type="text"
                onKeyDown={handleKeyDown}
                onChange={handleTagChange}
                name={tag}
                value={tag}
                maxLength={20}
            />
            <List>
                {tags?.map((tag, index) => (
                    <Button key={index} className="added-tags">
                        {tag}
                        <span onClick={() => { handleRemoveTag(tag) }} key={index}>x</span>
                    </Button>
                ))}
            </List>
        </Wrapper>
    );
}

export default Tags;
