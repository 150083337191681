/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetCoverpageQueryVariables = {|
  coverpageId: string
|};
export type GetCoverpageQueryResponse = {|
  +coverpage: ?{|
    +_id?: string,
    +id?: string,
    +portalId?: string,
    +name?: string,
    +mainHeading?: string,
    +details?: string,
    +templateId?: number,
    +thumbnail?: ?string,
    +tags?: ?$ReadOnlyArray<?string>,
    +showroomIds?: ?$ReadOnlyArray<string>,
    +showroomLink?: string,
    +creator?: string,
    +accountId?: string,
    +templateStyle?: ?{|
      +cardPosition: ?string,
      +BackgroundBlur: ?string,
    |},
    +createdAt?: any,
    +updatedAt?: ?any,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +signedUrl?: ?string,
    +userId?: ?string,
  |}
|};
export type GetCoverpageQuery = {|
  variables: GetCoverpageQueryVariables,
  response: GetCoverpageQueryResponse,
|};
*/


/*
query GetCoverpageQuery(
  $coverpageId: ID!
) {
  coverpage(id: $coverpageId) {
    __typename
    ... on Coverpage {
      _id
      id
      portalId
      name
      mainHeading
      details
      templateId
      thumbnail
      tags
      showroomIds
      showroomLink
      creator
      accountId
      templateStyle {
        cardPosition
        BackgroundBlur
      }
      createdAt
      updatedAt
    }
    ... on Response {
      message
      status
      success
      token
      hash
      signedUrl
      userId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "coverpageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "coverpageId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainHeading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "details",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showroomIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showroomLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateStyle",
      "kind": "LinkedField",
      "name": "templateStyle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardPosition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "BackgroundBlur",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Coverpage",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signedUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "type": "Response",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCoverpageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "coverpage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetCoverpageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "coverpage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f3694a6e8bee2d63f604440233c296a",
    "id": null,
    "metadata": {},
    "name": "GetCoverpageQuery",
    "operationKind": "query",
    "text": "query GetCoverpageQuery(\n  $coverpageId: ID!\n) {\n  coverpage(id: $coverpageId) {\n    __typename\n    ... on Coverpage {\n      _id\n      id\n      portalId\n      name\n      mainHeading\n      details\n      templateId\n      thumbnail\n      tags\n      showroomIds\n      showroomLink\n      creator\n      accountId\n      templateStyle {\n        cardPosition\n        BackgroundBlur\n      }\n      createdAt\n      updatedAt\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      hash\n      signedUrl\n      userId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3cae8409a002f38cf4323045d5ee4df3';

module.exports = node;
