// your-app-name/src/RelayEnvironment.js
import fetchGraphQL from "graphql/fetch";
import {
  Environment,
  Network,
  Observable,
  RecordSource,
  Store,
} from "relay-runtime";
import { SOCKET_DOMAIN } from "constants/env";
import { createClient } from "graphql-ws";
import { getAuthData } from "helpers/authHandler";

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.

async function fetchRelay(params, variables) {
  return fetchGraphQL(params.text, variables).then(json => {
    if (json && json.errors) {
      throw new Error(json.errors[0]);
    }
    return json;
  });
}

const wsClient = createClient({
  url: SOCKET_DOMAIN,
  connectionParams: {
    authentication: getAuthData(),
  },
});

const subscribe = (operation, variables) => {
  return Observable.create(sink => {
    return wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink
    );
  });
};

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
  network: Network.create(fetchRelay, subscribe),
  store: new Store(new RecordSource()),
});
