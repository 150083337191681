import styled from "styled-components";
import Spinner from "../../../../assets/images/spinner.gif";

const Wrapper = styled.div`
    height: 170px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c2c2c;
`

const Img = styled.img`
    width: 100%;
    height: auto;
`

function ListCardLoader() {
    return (
        <Wrapper>
            <Img src={Spinner} />
        </Wrapper>
    );
}
export default ListCardLoader;
