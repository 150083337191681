import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./customeDataPicker.module.scss";

export default function CustomDatePicker({ start, end, setState }) {
  const handleStartDateChange = date => {
    setState(prev => ({
      ...prev,
      start: date,
    }));
    if (end < date) {
      setState(prev => ({
        ...prev,
        end: date,
      }));
    }
  };

  const handleEndDateChange = date => {
    setState(prev => ({
      ...prev,
      end: date,
    }));
  };

  return (
    <div style={{ width: "100%" }} className={classes["datepicker-wrapper"]}>
      <span className={classes["from-to"]}>From</span>
      <DatePicker
        selected={start}
        onChange={handleStartDateChange}
        startDate={start}
        endDate={end}
        minDate={new Date()}
      />
      <span className={classes["from-to"]}>To</span>
      <DatePicker
        selected={end}
        onChange={handleEndDateChange}
        startDate={start}
        endDate={end}
        minDate={start}
      />
    </div>
  );
}

CustomDatePicker.propTypes = {
  end: PropTypes.instanceOf(Date),
  start: PropTypes.instanceOf(Date),
  setState: PropTypes.func,
};
