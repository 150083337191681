import React from "react";
import styles from "./LeftRight.module.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LeftRight({
  backgroundImage,
  background,
  extraClass,
  ...rest
}) {
  const navigate = useNavigate();
  const containerStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : background,
  };
  const {t} = useTranslation();

  return (
    <div className={styles.container} style={containerStyle}>
      <div
        className={`${styles.content} ${styles[extraClass]}`}
        style={{ color: rest?.color }}
      >
        <div className={styles.text}>
          <h2 className={styles.title}>{t(rest?.title)}</h2>
          <p className={styles.desc}>{t(rest?.description)}</p>
          {rest?.btn && (
            <button
              onClick={() => navigate(`/${rest?.toLink}`)}
              style={{ backgroundColor: rest?.bgColor }}
              className={styles.btn}
            >
              {t(rest?.btn)}
            </button>
          )}
        </div>
        <div className={styles.imageHolder}>
          <img
            src={rest?.image}
            style={{ boxShadow: rest?.boxShadow }}
            className={styles.image}
            alt="snapshot"
          />
        </div>
      </div>
    </div>
  );
}

LeftRight.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string,
  btn: PropTypes.string,
  image: PropTypes.string,
  background: PropTypes.string,
  extraClass: PropTypes.string,
};
