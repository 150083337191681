import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';

export default (props) => {
  return (
    <>

    <Modal
      size={props.size}
      show={props.open}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      style={{
        background: 'rgba(0, 0, 0, 0.75)',
        backdropFilter: 'blur(35px)',
        fontFamily: 'Raleway',
        }}
      onHide={props.onHide}
      dialogClassName={props.cls}
    >
      {props.children}
    </Modal>
    </>
  );
}
