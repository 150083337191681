import fscreen from 'fscreen';

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

export const arrayEquals = (a, b) => {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}


const handler = () => {
  if (fscreen.fullscreenElement !== null) {
  } else {
  }
 }

export const goFS = () => {
  const elem = document.getElementById("root");
  if (fscreen.fullscreenEnabled) {
    fscreen.addEventListener('fullscreenchange', handler, false);
    fscreen.requestFullscreen(elem);
  }
}

export const exitFS = () => fscreen.exitFullscreen();