import { getCookie } from "helpers";
import { getAuthData, encryptString } from "helpers/authHandler";

export default async function fetchGraphQL(text, variables) {
  const cookieAuthToken =getAuthData();
  const ACTIVE_PROFILE = encryptString(getAuthData("activeProfile"));
  const response = await fetch(
    `${process.env.REACT_APP_API}` || "http://localhost:4001/graphql",
    {
      method: "POST",
      headers: {
        Authorization: `bearer ${cookieAuthToken || ""}`,
        "Content-Type": "application/json",
        ActiveProfile: ACTIVE_PROFILE,
      },
      body: JSON.stringify({
        query: text,
        variables,
      }),
    }
  );

  // Get the response as JSON
  return await response.json();
}

