import styled from "styled-components";

export const ImageContainerFixedWidth = styled.div`
/*Style the border*/
border: ${props => props.border || ""};
border-radius: ${props => props.radius || ""};
margin:0;
padding:0;
`

export const ImageFixedWidth = styled.img`
width:${props => (props.width)+"px" || "100%"};/*Input width*/
height: ${props => (56.25*props.width/100)+"px" || "0"}; /*Height gets calculated itself*/
`
export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
`;

export const Content = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s ease-out;
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:hover {
      transform: scale(1.02) !important;
      transition: all 0.3s linear;
    
  }
`;

export function Image(props){
    const {url} = props;

    return(
        <ImageContainer>
            <Content src={url}/>
        </ImageContainer>
    )
}
