import { Blur, Wrapper, Title, Details, ShowroomsLink, Scroll } from "./common";
import SharedText from "components/ui/atoms/SharedText/SharedText";
import { mainHeadingInit, mainSubHeadingInit } from "components/ui/atoms/CoverPages/editorSettings";
import { useQuery } from "relay-hooks";
import CoverpageQuery from "graphql/queries/GetCoverpageQuery";
import { useState, useEffect } from "react";
import AddShowroom from "components/ui/atoms/CoverPages/addShowroomPopup";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export function CoverPage2({coverpageData, onChangeEditor, backgroundUrl, textColor, isPreview,sharing, handleCheckoutShowrooms, updateCoverpage, templateId, disabled, showroomid}){
    const navigation = useNavigate();
    const [showList, setShowList] = useState(false)
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const { data, isLoading } = useQuery(
        CoverpageQuery,
        { coverpageId: id },
        {
          fetchPolicy: "store-and-network",
        }
    );

    useEffect(() => {
        if (id && !isLoading) {
            updateCoverpage(data.coverpage)
        }
    }, [data, isLoading]);

    const addShowroomIds = (showroomIDs) => {
        updateCoverpage({showroomIds: showroomIDs})
    }
    return(
        <Wrapper image={""} pd="7% 0" pb={templateId === 7 ? "0" : ""} centerAlign isPreview={(isPreview || sharing)}>
            <Blur width={templateId === 7 ? "100%" : "76%"}>
              <Scroll height="245px">
                {/* <Title>Title</Title>
                <Details>Consectetur adipiscing elit. Nam lacinia hendrerit turpis, ut mattis nibh. Sed vitae lorem quis nunc sagittis sagittis</Details> */}
                <SharedText
                    isPreview={isPreview}
                    title={coverpageData.mainHeading}
                    isSubHead={true}
                    disabled={disabled}
                    description={coverpageData.details}
                    onChangeEditor={onChangeEditor}
                    mainHeadingInit={mainHeadingInit} 
                    subHeadingInit={mainSubHeadingInit}
                />
                        {showroomid?<ShowroomsLink onClick={() =>{navigation(`/showrooms/${showroomid}`)}}>Checkout my Show Rooms</ShowroomsLink>:""}
                <AddShowroom height="300%" top="-200%" left="61.5%" setShowList={setShowList} showList={showList} addShowroomIds={addShowroomIds} showroomIds={data ? data.coverpage.showroomIds : coverpageData.showroomIds} />
                </Scroll>
            </Blur>
        </Wrapper>
    )
}

export default CoverPage2;
