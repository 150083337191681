import graphql from "babel-plugin-relay/macro";

const GetMagazinesByPortal = graphql`
  query GetMagazinesByPortalQuery($portalId: ID!) {
    magazinesByPortalId(portalId: $portalId) {
      ... on ListOfMagazines {
        magazines {
          _id
          id
          name
          backgroundColor
          backgroundImage
          companyLogo
          mainTemplateId
          subTemplateId
          portalId
          rows {
            content {
              heading
              subHeading
              description
              headerImage
              fileUrl
              videoThumbnail
              cardThumbnail
              headerOverlayContent
              linkTo
              buttonText
              graphData {
                attr1
                attr2
                attr3
                data {
                  name
                  val1
                  val2
                  val3
                }
              }
            }
            isShown
          }
          thumbnail
          tags
          creator
          accountId
          createdAt
          updatedAt
        }
      }
      ... on Response {
        message
        status
        success
      }
    }
  }
`;
export default GetMagazinesByPortal;
