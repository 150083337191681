import React, { useEffect, useState } from "react";
import styles from "./FrontGuide.module.scss";
import { useNavigate } from "react-router-dom";
import { useTour } from "@reactour/tour";
import StickyNav from "containers/NavBar";
import {steps as stepsData} from "constants/steps";
import selectData from "graphql/mutations/selectData";
import { getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import { useTranslation } from "react-i18next";
export default function FrontGuide() {
  const { setIsOpen,currentStep,setSteps,setCurrentStep,isOpen} = useTour();
  const { t } = useTranslation();
  const modals = getClientData(useQuery, clientOnly, {}, "Modal") || {};
  function translate(data) {
    t(data);
  }
  const data = [
    {
      image: "https://cdn.cf.masterwizr.com/Group_40450_1_clwxa0.png",
      title: "Show Room",
      description:
        "Combine-2-20-presentation",
      to: "/showroom-templates",
      clickEvent:()=>setSteps( [...stepsData().slice(0,12)]),
    },
    {
      image:"https://cdn.cf.masterwizr.com/Group_40451_1_lymxuv.png",
      title: "Cover Page",
      description:
        "Create-personalized-connection",
      to: "/coverpage-templates",
      steps:(12,16),
      clickEvent:()=>setSteps( [...stepsData().slice(0,4), ...stepsData().slice(12, 17)]),
    },
    {
      image: "https://cdn.cf.masterwizr.com/Group_40450_2_qzwxih.png",
      title: "Microsite",
      description:
        "Related-Show-Rooms",
      to: "/showrooms",
      clickEvent:()=>setSteps( [...stepsData().slice(0,4), ...stepsData().slice(17, stepsData().length)]),
    },
  ];
  const setTour = () => {
    setIsOpen(true);
    selectData({ ...modals, tour: true }, "Modal");
    setCurrentStep(0);
  };
  const navigation = useNavigate();

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const studioBodyElement = document.querySelector(".studio-body");
    if (studioBodyElement) {
      setShowButton(true);
    }
    console.log('studioBodyElement', studioBodyElement)
  }, []);

  console.log('showButton', showButton)


  return (
    <>
      <div className={styles.container}>
        <StickyNav />
        <h1 className={`start-here ${styles.title}`}>{t("create-together")}</h1>
        <button className={styles.actionBtn} onClick={() => setTour()}>
          {t("Start Tour")}
        </button>
        <div className={`guideCards ${styles.data}`}>
          {data?.map((item, i) => {
            return (
              <button
                onClick={() => {
                  if (currentStep >= 3) {
                    navigation(item.to);
                    item.clickEvent();
                  } else if (isOpen === false) {
                    navigation(item.to);
                  }
                }}
                className={`click-card-${i} ${styles.card}`}
                key={i}
              >
                <img src={item.image} alt={`Step ${i + 1}`} />
                <h3>{t(item.title)}</h3>
                <p>{t(item.description)}</p>
              </button>
            );
          })}
        </div>
      </div>
      {showButton && (
        <div  className={styles.magazineParentButton}>
          <button
            className={styles.magazineButton}
            onClick={() => navigation("/magazine")}
          >
            Go to Magazine
          </button>
        </div>
      )}
    </>
  );
}
