import CoverpageQuery from "graphql/queries/GetCoverpageQuery";
import React from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "relay-hooks";
import CoverpageDetailedPage from "./CoverpageDetailedPage";
import EditMicrositeMutation from "graphql/mutations/EditMicrositeMutation";
import DeleteMicrositeMutation from "graphql/mutations/DeleteMicrositeMutation";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import responseHandler from "helpers/responseHandler";

function CreateMode({ id }) {
  return <CoverpageDetailedPage data={null} id={id} />;
}
function EditOrPreviewMode({ id, isMicrosite, setShowCoverPage, micrositeData, setNoCoverpageOption, microsite, setMicrosite, isEdit }) {  
  const navigation = useNavigate();
  const urlShowroomIds = useParams().id;
  const showroomsIdsLength = micrositeData?.showroomsIds?.length || urlShowroomIds.split(',').length;
  
  const { data, isLoading } = useQuery(
    CoverpageQuery,
    { coverpageId: id },
    {
      fetchPolicy: "store-or-network",
    }
  );

  const editCoverpageSelection = () => {
    if(micrositeData.showroomsIds){
    navigation(`/edit-coverpage-microsite/${micrositeData.showroomsIds.toString()}/2/${micrositeData._id}/${micrositeData.coverpageId}/${micrositeData.templateId}`)
    }else {
      navigation(`/cover-pages/${urlShowroomIds}/2/${id}/${data.coverpage.templateId}`)
    }
  }

  

  const deleteMicrositeFunc = () =>{
    if(micrositeData.showroomsIds){
    let editedMicrosite = micrositeData;
    editedMicrosite.coverpageId = null;
    editedMicrosite.templateId = null;
    const {  updatedAt, accountId, createdAt, creator,id ,__typename ,  ...edited } = editedMicrosite  
      EditMicrositeMutation(edited, response => {
        responseHandler(response, "Coverpage Removed Successfully", ({_id, showroomsIds }) =>{
          setShowCoverPage(false);
          navigation(`/microsite/${showroomsIds.toString()}/null?id=null&micrositeid=${_id}`)
        })
      });
    }else{
      setShowCoverPage(false);
      setNoCoverpageOption(true);
      let micro = {...microsite};
      micro.coverpageId = null;
      micro.templateId = null;
      setMicrosite(micro);
      navigation(`/microsite/${urlShowroomIds}/null`)
    }
  };

  const deleteMicrosite = () => {
    if(micrositeData.showroomsIds){
    DeleteMicrositeMutation(micrositeData?._id, response => {
      responseHandler(response, "Microsite Deletion Successfully", () =>{
        navigation("/microsites");
      })
    });
  }
    else{
      navigation("/showrooms")
    }
  };

  if (isLoading) return null;
  return micrositeData?.coverpageId!==null?<CoverpageDetailedPage isEdit={isEdit} showroomsIdsLength={showroomsIdsLength} data={data} id={data.coverpage.templateId} isMicrosite={isMicrosite} setShowCoverPage={setShowCoverPage} deleteMicrositeFunc={deleteMicrositeFunc} editCoverpageSelection={editCoverpageSelection} deleteMicrosite={deleteMicrosite}  />:"";
}

function CoverpagePage({isMicrosite, setShowCoverPage, micrositeData, setNoCoverpageOption, microsite, setMicrosite,isEdit}) {
  const [searchParams] = useSearchParams();
  let { id } = useParams();
  const paramId = searchParams.get("id");
  if (paramId) return <EditOrPreviewMode isEdit={isEdit} isMicrosite={isMicrosite} setNoCoverpageOption={setNoCoverpageOption} setShowCoverPage={setShowCoverPage} id={paramId} micrositeData={micrositeData} microsite={microsite} setMicrosite={setMicrosite} />;
  return <CreateMode id={id}  />;
}

export default CoverpagePage;
