/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetPortalQueryVariables = {|
  id: string
|};
export type GetPortalQueryResponse = {|
  +portal: ?{|
    +_id?: string,
    +name?: string,
    +menus?: $ReadOnlyArray<?{|
      +name: string,
      +_id: string,
      +visibility: boolean,
      +folders: $ReadOnlyArray<?{|
        +_id: string,
        +name: string,
        +presentations: $ReadOnlyArray<?{|
          +_id: string,
          +name: string,
          +coverUrl: ?string,
          +files: $ReadOnlyArray<?{|
            +fileType: string,
            +fileUrl: string,
            +thumbnailUrl: ?string,
            +coverUrl: ?string,
            +name: string,
            +_id: string,
            +positions: $ReadOnlyArray<?{|
              +presentationId: string,
              +position: number,
            |}>,
          |}>,
        |}>,
      |}>,
    |}>,
    +message?: string,
    +status?: number,
  |}
|};
export type GetPortalQuery = {|
  variables: GetPortalQueryVariables,
  response: GetPortalQueryResponse,
|};
*/


/*
query GetPortalQuery(
  $id: ID!
) {
  portal(id: $id) {
    __typename
    ... on Portal {
      _id
      name
      menus {
        name
        _id
        visibility
        folders {
          _id
          name
          presentations {
            _id
            name
            coverUrl
            files {
              fileType
              fileUrl
              thumbnailUrl
              coverUrl
              name
              _id
              positions {
                presentationId
                position
              }
            }
          }
        }
      }
    }
    ... on Response {
      message
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverUrl",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Menu",
      "kind": "LinkedField",
      "name": "menus",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Folder",
          "kind": "LinkedField",
          "name": "folders",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Presentation",
              "kind": "LinkedField",
              "name": "presentations",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "files",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fileType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fileUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnailUrl",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    (v3/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Position",
                      "kind": "LinkedField",
                      "name": "positions",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "presentationId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "position",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Portal",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Response",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetPortalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "portal",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetPortalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "portal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "949dec655b49760f322222c286984053",
    "id": null,
    "metadata": {},
    "name": "GetPortalQuery",
    "operationKind": "query",
    "text": "query GetPortalQuery(\n  $id: ID!\n) {\n  portal(id: $id) {\n    __typename\n    ... on Portal {\n      _id\n      name\n      menus {\n        name\n        _id\n        visibility\n        folders {\n          _id\n          name\n          presentations {\n            _id\n            name\n            coverUrl\n            files {\n              fileType\n              fileUrl\n              thumbnailUrl\n              coverUrl\n              name\n              _id\n              positions {\n                presentationId\n                position\n              }\n            }\n          }\n        }\n      }\n    }\n    ... on Response {\n      message\n      status\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cefc78b2a12c3a4c47c18ca317b711d1';

module.exports = node;
