/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetShowroomsQueryVariables = {||};
export type GetShowroomsQueryResponse = {|
  +showrooms: ?{|
    +showrooms?: $ReadOnlyArray<{|
      +_id: string,
      +id: string,
      +name: string,
      +mainHeading: string,
      +subHeading: string,
      +tags: ?$ReadOnlyArray<string>,
      +headerImage: ?string,
      +templateId: number,
      +backgroundImage: ?string,
      +content: ?$ReadOnlyArray<?{|
        +name: string,
        +content: ?$ReadOnlyArray<?{|
          +image: string,
          +mainHeading: string,
          +presentationId: string,
          +subHeading: string,
          +imageUrl: string,
        |}>,
      |}>,
      +creator: string,
    |}>,
    +message?: string,
    +status?: number,
    +success?: boolean,
    +token?: ?string,
    +hash?: ?string,
    +signedUrl?: ?string,
    +userId?: ?string,
    +shared?: ?string,
  |}
|};
export type GetShowroomsQuery = {|
  variables: GetShowroomsQueryVariables,
  response: GetShowroomsQueryResponse,
|};
*/


/*
query GetShowroomsQuery {
  showrooms {
    __typename
    ... on ListOfShowrooms {
      showrooms {
        _id
        id
        name
        mainHeading
        subHeading
        tags
        headerImage
        templateId
        backgroundImage
        content {
          name
          content {
            image
            mainHeading
            presentationId
            subHeading
            imageUrl
          }
        }
        creator
      }
    }
    ... on Response {
      message
      status
      success
      token
      hash
      signedUrl
      userId
      shared
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainHeading",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subHeading",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Showroom",
      "kind": "LinkedField",
      "name": "showrooms",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headerImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NestedContent",
              "kind": "LinkedField",
              "name": "content",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "presentationId",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfShowrooms",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signedUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shared",
      "storageKey": null
    }
  ],
  "type": "Response",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetShowroomsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "showrooms",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetShowroomsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "showrooms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8da6758402715e9f366c477d067dfc57",
    "id": null,
    "metadata": {},
    "name": "GetShowroomsQuery",
    "operationKind": "query",
    "text": "query GetShowroomsQuery {\n  showrooms {\n    __typename\n    ... on ListOfShowrooms {\n      showrooms {\n        _id\n        id\n        name\n        mainHeading\n        subHeading\n        tags\n        headerImage\n        templateId\n        backgroundImage\n        content {\n          name\n          content {\n            image\n            mainHeading\n            presentationId\n            subHeading\n            imageUrl\n          }\n        }\n        creator\n      }\n    }\n    ... on Response {\n      message\n      status\n      success\n      token\n      hash\n      signedUrl\n      userId\n      shared\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6aaefdfa2e83ebf812528d738b04bea7';

module.exports = node;
