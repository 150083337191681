import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
`;

export const Content = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s ease-out;
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${props => props.zIndex};
`;

export function Image(props){
    const { url, onClick, id } = props;

    return(
        <ImageContainer  className='image-container' id={id}>
            <Content src={url} onClick={onClick}/>
        </ImageContainer>
    )
}
