import { useState, useEffect, useContext } from "react";

import "./style.scss";
import { ReactComponent as Edit} from "assets/images/edit.svg";
import { ReactComponent as Delete} from "assets/images/circular-trash.svg";
import {useParams, useNavigate } from 'react-router-dom'
import { useQuery } from "relay-hooks";
import CreateMicrositeMutation from "graphql/mutations/CreateMicrositeMutation";
import DeleteMicrositeMutation from "graphql/mutations/DeleteMicrositeMutation";
import ShowRoom2Template from "../ShowRoom2Template";
import PopUp from "components/ui/atoms/PopUp/PopUp";
import { Title } from "components/ui/atoms/DesignEditor/styles";
import Input from "components/ui/atoms/Input/input";
import EditMicrositeMutation from "graphql/mutations/EditMicrositeMutation";
import StickyNav from "containers/NavBar";
import { getAccountId, getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import { SUPER_ROLES, PORTAL_ROLES, STATIC_URL } from 'constants/env';
import CoverpageDetailedPage from "../CoverpageDetailedPage/CoverpageDetailedPage";
import CoverpagePage from "../CoverpageDetailedPage/CoverpagePage";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { showroomTemplates } from "constants/templates";
import { ReactComponent as Add} from "assets/images/add-icon.svg";
import DeleteModal from "containers/Modals/deleteModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import template1 from "assets/images/template1.png";
import template2 from "assets/images/template2.png";
import template3 from "assets/images/template3.png";
import template4 from "assets/images/template4.png";
import template5 from "assets/images/template5.png";
import template6 from "assets/images/template6.png";
import expandable3 from "assets/images/expandable3.png";
import template8 from "assets/images/template8.png";
import template9 from "assets/images/template9.png";
import template10 from "assets/images/template10.png";
import template11 from "assets/images/template11.png";
import { ShowroomCard } from "components/ui/atoms/ShowroomCard/ShowroomCard";
import IsLoading from "components/ui/atoms/IsLoading";
import {ReactComponent as Close} from "assets/images/close.svg";
import {ReactComponent as Eye} from "assets/images/eye.svg";
import {ReactComponent as EyeToggle} from "assets/images/eye-toggle.svg";
import {ReactComponent as Reorder} from "assets/images/reorder-icon.svg";
import responseHandler from "helpers/responseHandler";
import CancelButton from "components/ui/atoms/CancelButton";
import { uploadFilesAndDeleteByUrl } from "helpers/fileUpload";
import UploadModal from "containers/Modals/UploadModal";
import { Modal } from "react-bootstrap";
import Loader from "../ShowRoomDesign/Loader";
import { backgroundImages } from "constants/backgroundImages";
import { useTour } from "@reactour/tour";
const RoomSlider = (props) => {
  const isSharingMode = window.location.pathname.includes("/share/");

  const navigation = useNavigate();
  const { id, coverpageid } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const [coverpageId,setCoverpageId] = useState(urlParams.get('id') || null);
  const micrositeId = urlParams.get('micrositeid');
  const [selectedShowRooms, setShowRooms] = useState(props.selectedShowRooms||[]);
  const [reorderedRooms, setReorderedRooms] = useState([]);
  const [showCoverPage, setShowCoverPage] = useState((coverpageId && coverpageId !== 'null' ) || props.sharedCoverpageData ? true : false);
  const [popup, setPopup] = useState(false);
  const [micrositeName, setMicrositeName] = useState("");
  const [existingMicrosite, setExistingMicrosite] = useState(null);
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const portalRole = portal?.portalRole;
  const accountRole = getClientData(useQuery, clientOnly, {} , "Profile")?.accountRole;
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [portalPermissions, setPortalPermissions] = useState(null);
  const [accountPermissions, setAccountPermissions] = useState(null);
  const [noCoverpageOption, setNoCoverpageOption] = useState((coverpageId === "null" || coverpageId===undefined) && !props.sharedCoverpageData);
  const [viewingItem, setViewingItem] = useState(false);
  const [deletePopup,setDeletePopup] = useState(false);
  const [isReOrderMode, setReOrderMode] = useState(false);
  const [showroomsIds, setShowroomsIds] = useState(props.showroomsIds);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [bgModal,setBgModal] = useState(false);
  const updatedMicrosite = props?.queryMicrosite?.data?.microsite;
  const {setIsOpen} = useTour();

  useEffect(() => {
    if(!showCoverPage){
      document.body.classList.add('microsite-item');
    
      return function cleanup() {
        document.body.classList.remove('microsite-item');
      }
    }
  }, [showCoverPage]);

  useEffect(()=>{
    if(micrositeId===null||micrositeId===undefined){
      if(!isSharingMode){
      setIsCreateMode(true);
    }
    }
  },[])
  
  if(props.selectedShowRooms !== selectedShowRooms){
    setShowRooms(props.selectedShowRooms);
  };
  if(props.showroomsIds!== showroomsIds){
    setShowroomsIds(props.showroomsIds);
  } 
  const newData = props?.queryMicrosite?.data?.microsite;
  useEffect(()=>{
    if(micrositeId!==null&&micrositeId!==undefined&&newData.showroomsIds.toString()!==id){
    navigation(`/microsite/${newData.showroomsIds.toString()}/${coverpageid}?id=${coverpageId}&micrositeid=${micrositeId}`);
  }
  }, [micrositeId, newData]);

  useEffect(()=>{
    if(micrositeId!==null&&micrositeId!==undefined){
      if(props.queryMicrosite?.data?.microsite.coverpageId===null){
        if(coverpageId!==null&&coverpageId!=="null"){
          setNoCoverpageOption(true);
          setShowCoverPage(false);
          navigation(`/microsite/${props.queryMicrosite?.data?.microsite.showroomsIds.toString()}/null?id=null&micrositeid=${micrositeId}`);
        }
      }else{
        if(coverpageId===null||coverpageId==="null"){
          setCoverpageId(props.queryMicrosite?.data?.microsite.coverpageId);
          setNoCoverpageOption(false);
          setShowCoverPage(true);
          navigation(`/microsite/${props.queryMicrosite?.data?.microsite.showroomsIds.toString()}/${props.queryMicrosite?.data?.microsite.templateId}?id=${props.queryMicrosite?.data?.microsite.coverpageId}&micrositeid=${micrositeId}`);

        }
        else if(props.queryMicrosite?.data?.microsite.coverpageId.toString()!==coverpageId){
          setCoverpageId(props.queryMicrosite?.data?.microsite.coverpageId);
          navigation(`/microsite/${props.queryMicrosite?.data?.microsite.showroomsIds.toString()}/${props.queryMicrosite?.data?.microsite.templateId}?id=${props.queryMicrosite?.data?.microsite.coverpageId}&micrositeid=${micrositeId}`);
        }
      }
    }
  },[props.queryMicrosite?.data?.microsite])
  useEffect(()=>{
    setReorderedRooms(props.selectedShowRooms);
  },[props.selectedShowRooms]);
 
  useEffect(()=>{
    const newCoverpageId = new URLSearchParams(window.location.search).get("id")||null;
    setCoverpageId(newCoverpageId);
    if((coverpageId===null||coverpageId==='null') && !props.sharedCoverpageData){
      setNoCoverpageOption(true);
    }
  },[showCoverPage])
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  const grid = 3;

  const templateType = tempId => {
    const templateIds = [1,2,3,4]
    const expandableIds = [5,6,7]
    if (templateIds.includes(tempId)) {
      return "template"
    }
    if (expandableIds.includes(tempId)) {
      return "expandable"
    }
    else {
      return "portrait"
    }
  }

  const getItemStyle = (isDragging, draggableStyle, temp) => ({
    width: templateType(temp)==="portrait"?"auto":"300px",
    userSelect: "none",
    marginLeft:"10px",
    marginRight:"10px",
    boxSizing: "border-box",
    alignItems:"center",
    justifyContent:"center",
    cursor:"pointer",
    backgroundColor:isDragging ? "#303030":"#202020",
    boxShadow: "0px 3px 20px #000000",
    borderRadius: "5px",
    color: "white",
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "transparent" : "transparent",
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    flexWrap:"wrap",
    padding: grid,
    width:"100vw",
    overflowX:"hidden",
    gap:"20px",
    paddingTop:"70px"
  });
  const onDragEnd = (result) =>{
    if (!result.destination) {
      return;
    }
    const items = reorder(
      selectedShowRooms,
      result.source.index,
      result.destination.index
    );
    setReorderedRooms(items);
    let arr = [];
    for(let i = 0; i < items.length; i++){
      arr.push(items[i]._id);
    }
    changeShowroomOrder(arr, items);
  }
  const switchRoom = tempId => {
    switch (tempId) {
      case 1:
        return template1;
      case 2:
        return template2;
      case 3:
        return template3;
      case 4:
        return template4;
      case 5:
        return template5;
      case 6:
        return template6;
      case 7:
        return expandable3;
      case 8:
        return template8;
      case 9:
        return template9;
      case 10:
        return template10;
      case 11:
        return template11;
      default:
        break;
    }
  };

  const [microsite, setMicrosite] = useState({
    name:"Microsite",
    showroomsIds: showroomsIds||[],
    coverpageId: coverpageId,
    templateId: parseInt(coverpageid),
    portalId: portal?.portalId,
    creator: "61f7d0bd60dfeb58a9808f86",
    accountId: getAccountId(),
    backgroundImage: updatedMicrosite?.backgroundImage,
  })
  const [selIte, setSelIte] = useState("item-1");
  const handleMutation = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const saveMicrosite = {...microsite};
    saveMicrosite.name = micrositeName;
  CreateMicrositeMutation(saveMicrosite, response => {
    setIsLoading(false);
    responseHandler(response, "Microsite created successfully", () =>{
      setMicrosite(microsite);
      navigation('/microsites')
    })
  })
};
const changeShowroomOrder = (list, items) => {
  let editedMicrosite = microsite;
  editedMicrosite._id = micrositeId;
  editedMicrosite.name = "edit-showrooms";
  editedMicrosite.showroomsIds = list;
  const {updatedAt, accountId, createdAt, creator, id, __typename, ...edited } = editedMicrosite;
  EditMicrositeMutation(edited, response => {
    responseHandler(response, "Showrooms are reordered...", () =>{})
  });
}


const editMicrositeFunc = (editedMicrosite, tempArr) =>{
  const {  updatedAt, accountId, createdAt, creator,id ,__typename ,  ...edited } = editedMicrosite  
  edited.backgroundImage = microsite?.backgroundImage;
    EditMicrositeMutation(edited, response => {
        if(response.editMicrosite.message === "Microsite was deleted successfully" ){
          navigation("/microsites");
          showSuccessToast("Microsite deleted successfully!"); 
          hideDeletePopup();
        }
        else{
          showErrorToast("Failed to delete Microsite");
        }
    });
};   

const deleteMicrosite = () => {
  DeleteMicrositeMutation(micrositeId, response => {
    responseHandler(response, "Microsite Deleted Successfully", () =>{
      setDeletePopup(false);  
      navigation("/microsites");
    })
  });
};
const handleEdit = (showroomId) => {
  const editedMicrosite = {...props.queryMicrosite.data.microsite};
  const tempArr = [...editedMicrosite.showroomsIds];
  let index = tempArr.indexOf(showroomId.toString());
  tempArr.splice(index, 1);
  editedMicrosite.showroomsIds = tempArr;
  editedMicrosite.name = "edit-showrooms"
  if(editedMicrosite.showroomsIds.length>0){
    editMicrositeFunc(editedMicrosite, tempArr);
  }
  };

const handleNameChange = (e) => {
  if(e.length >= 28){
    showErrorToast("Name is not allowed more than 28 characters");
  }
  setMicrositeName(e);
}
  const hidePopup = () => {
    setPopup(false);
  };
  const hideDeletePopup = () => {
    setDeletePopup(false);
  }

  useEffect(()=>{
    setAccountPermissions(accountRole);
    setPortalPermissions(portalRole);
  },[portalRole,accountRole]);

  useEffect(()=>{
    if((coverpageId===null||coverpageId===undefined) && !props.sharedCoverpageData){
      setNoCoverpageOption(true);
    }
    if(portalRole!==undefined&&accountRole!==undefined){
    if(!(PORTAL_ROLES.includes(portalRole)||SUPER_ROLES.includes(accountRole))||portalRole==="PUBLISHER"){
      setUnAuthorized(true);
     }
    }
  },[]);

  useEffect(()=>{

  },[viewingItem]);
  const handleBackgroundImageSelection = (imageURL) => {
    setMicrosite({...microsite, backgroundImage: imageURL});
  };
  const changeCoverpageFunc = () => {
    const editedMicrosite = {
      ...props?.queryMicrosite?.data?.microsite,
      _id: micrositeId,
      backgroundImage: microsite?.backgroundImage,
      name:""
    }
    const { updatedAt, accountId, createdAt, creator, id ,__typename , ...edited } =
    editedMicrosite;
  EditMicrositeMutation(edited, response => {
    responseHandler(response, "Microsite Updated Successfully", () =>{
      responseHandler(response, "Microsite Updated Successfully", () =>{})

    })
  });
};

//upload bg image
const [openModal, setOpenModal] = useState({open: false});
const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
const [imageChangesQueue, setImageChangesQueue] = useState([]);
const [handleBg,setHandleBg] = useState(false);

const updateBgImage = (bgimage) => {
  const editedMicrosite = {
    ...props?.queryMicrosite?.data?.microsite,
    _id: micrositeId,
    name:"thumbnail",
    backgroundImage: bgimage
  }
  const { updatedAt, accountId, createdAt, creator, id ,__typename , ...edited } =
  editedMicrosite;
EditMicrositeMutation(edited, response => {
  responseHandler(response, "Microsite Updated Successfully", () =>{
    responseHandler(response, "Microsite Updated Successfully", () =>{
      setImageChangesQueue([])
      setOpenModal({open:false});    
      setIsUploadingThumbnail(false); 
    })

  })
});
};
const updateThumbnails = async () => {
  setIsUploadingThumbnail(true);
  if(!imageChangesQueue.length) {
    return updateBgImage("");
  } else {
       const {uploadedResult : result} = await uploadFilesAndDeleteByUrl(imageChangesQueue,"thumbnails")
      if (!result) {
        setIsUploadingThumbnail(false);
      } else{
        const bgimage= `${STATIC_URL}${result[0]?.thumbnailUrl}`;
         updateBgImage(bgimage);
         setMicrosite({...microsite, backgroundImage: bgimage});
         setIsUploadingThumbnail(false); 
    }
  }
}
useEffect(() => {
  if(handleBg){

    document.querySelector(".done")?.addEventListener("click", updateThumbnails);
    return () => {
      document.querySelector(".done")?.removeEventListener("click", updateThumbnails);
  }
  } 
},[imageChangesQueue])
  return (
    <div 
    className={`microsite-full-container ${(microsite?.backgroundImage || props?.backgroundImage) !== null && !(microsite?.backgroundImage?.includes("HD_transparent")) && `blurMicrosite`}`}
    style={{backgroundImage: isSharingMode ? `url(${props?.backgroundImage})` : `url(${microsite?.backgroundImage})`}}
    >
        {isUploadingThumbnail && <Loader />}
      {microsite?.backgroundImage !== updatedMicrosite?.backgroundImage && <button onClick={changeCoverpageFunc} className="save-bg">Save Changes</button>}
     {bgModal && isEdit &&
       <div className="modals-backgrounds">
                <button 
         onClick={() => {
          setHandleBg(true);
          setImageChangesQueue([]);
          setOpenModal({
            open: true,
            id: props?.queryMicrosite?.data?.microsite?._id,
            data: { micrositeBackground: props?.queryMicrosite?.data?.microsite?.backgroundImage},
          });
        }}
       >Upload Background</button>
       <div className="images-bg">
       <img
          onClick={()=>handleBackgroundImageSelection("https://cdn.cf.masterwizr.com/HD_transparent_picture_lwvng3.png")}
           src="https://cdn.cf.masterwizr.com/icon-transparent_k2fff4.png" alt="bg"/>

             {backgroundImages?.map((item,i)=>{
          return(
            <div 
            onClick={() => handleBackgroundImageSelection(item)}
            >
            <img
            className={microsite?.backgroundImage?.includes(item) ? `selected` : `bgHolder`}
             alt="bg" src={item} key={i} />
             </div>
          )
        })}
       </div>
       </div>
       }
    {!isReOrderMode && !isSharingMode && !unAuthorized && <button onClick={()=>setIsEdit(!isEdit)} className="toggle-microsite-edit">{isEdit ? <Eye/> : <EyeToggle/>}</button>}
    <div className={`bottom-btn absolute ${(micrositeId===null || micrositeId===undefined) && 'microsite-actions'}` }>
      {(micrositeId === null || micrositeId === undefined) && !isSharingMode && <CancelButton handleCancel={() => {
            navigation(`/showrooms`);
          }}/>
          }
    {(micrositeId===null||micrositeId===undefined) && !isSharingMode  && <button className="finish finishmicrosite" onClick={()=>{setPopup(true); setIsOpen(false)}} style={{color:"white", marginBottom:"-20px", marginTop:"10px"}}>Save</button>}
    </div>
    {!showCoverPage?<>
      <div className="slider-wrapper">
      {!isReOrderMode && <StickyNav
        isEditBackground={isEdit ? true : false}
        bgModal={bgModal}
        isSharingMode={isSharingMode}
        setBgModal={setBgModal}
        showCoverPage={showCoverPage} clickEvent={()=>setShowCoverPage(true)} unAuthorized={unAuthorized} isMicrosite noitems handleText={"Open Cover Page"} micrositeData={micrositeId!==null&&micrositeId!==undefined?[id, props.queryMicrosite.data.microsite._id]:null} noCoverpage={noCoverpageOption} createMode={isCreateMode} showroomIds={id}  />}
        <div className={viewingItem?"slider-container hide-container":"slider-container"}>
          {selectedShowRooms&&selectedShowRooms?.map((room, index) => (
            <input
              defaultChecked={index === 0 ? true : false}
              key={index}
              type="radio"
              name="slider"
              id={`item-${index + 1}`}
              onChange={() => setSelIte(`item-${index + 1}`)}
            />
          ))}
          <input
            defaultChecked={false}
            key={8}
            type="radio"
            name="slider"
            id="item-8"
            onChange={()=>{}}/>


        
          <div className={`room-container ${viewingItem ? "display-none" : ""}`}>
       
            {selectedShowRooms&&selectedShowRooms?.map((room, index) => (
              <label
                className="card-room"
                htmlFor={`item-${index + 1}`}
                id={`card-header-${index + 1}`}
                key={index}
              >
               {room.name}
              </label>
            ))}
             {isEdit &&
             <>
             {(micrositeId!==null&&micrositeId!==undefined && !unAuthorized )|| isCreateMode ?
            <label className="card-room"
              onClick={!isCreateMode?()=>{navigation(`/edit-microsite/${id.toString()}/${coverpageid}?id=${coverpageId}&micrositeid=${micrositeId}`, { replace: true })}:()=>navigation(`/create-microsite/${id.toString()}/${coverpageId}/${coverpageid}`)}
              id={'id-8'}>
                <Add/>
              </label>:""}
              {isEdit &&
         <>
          {(micrositeId!==null&&micrositeId!==undefined) && !isSharingMode && !unAuthorized && selectedShowRooms.length >1? 
          <div className={`card-room reorder-micro-btn ${isReOrderMode?"hide-text":""}`}>
            <p style={{color:"gray", cursor:"pointer",zIndex:"999999"}} onClick={()=>{setReOrderMode(true)}}><Reorder /></p>
          </div>:""
          }
         </>
         }
             </>
             }
            
          </div>
          {(micrositeId!==null&&micrositeId!==undefined) && !isSharingMode && !unAuthorized ? <div className={`order-container-slider ${isReOrderMode?"visible-show-slider":""}`} style={{ overflow: "hidden"}}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal" ignoreContainerClipping={false}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(
                      snapshot.isDraggingOver,
                      reorderedRooms.length
                    )}
                    {...provided.droppableProps}
                  >
                    {reorderedRooms.map((item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index} >
                        {(provided, snapshot) => (
                          <div  ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            item.templateId,
                          )}>
                          <ShowroomCard
                            item={item}
                            typeOfCard={templateType(item.templateId)}
                          >
                          </ShowroomCard>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isReOrderMode &&  <div className="reorder-container">
            <p className="reorder-close" style={{color:"gray", cursor:"pointer"}} onClick={()=>setReOrderMode(false)}><Close/></p>
            <p className="reorder-text">Drag and drop to sort the order </p>
            </div>}
          </div>:""}
          <div className={viewingItem?"show-room-container hide-container":"show-room-container"}>
            {selectedShowRooms&&selectedShowRooms?.map((room, index) => (
              
              
              <label
                className={viewingItem?"show-room-item hide-container":"show-room-item"}
                htmlFor={`item-${index + 1}`}
                id={`card-${index + 1}`}
                key={index}
              >
                {props.editing?
                ""
                :
                <>
                  {isEdit &&
                  <>
                   {!unAuthorized&&!isCreateMode?<div className="edit-microsite">
                    <button onClick={()=>navigation(`/showrooms/${room._id}`, {
                                      state: { alreadyExists: true },
                                    })} style={{color:"white"}}><Edit /></button>
                    <br/>
                    
                    {!isCreateMode&&<button onClick={selectedShowRooms?.length > 1 ? selectedShowRooms?.length === 2 ? () => {setDeletePopup(true)}: () => {setShowDeleteModal(true)} : () =>  {setDeletePopup(true)}} style={{color:"white"}}><Delete /></button>}
                    </div>:""}
                  </>
                  }
                    {(showDeleteModal && selectedShowRooms?.length > 1) && (
                        <DeleteModal
                          text={`Are you sure you want to delete ${room?.name} from ${props?.queryMicrosite?.data?.microsite?.name}?`}
                          toggleModal={() => setShowDeleteModal(false)}
                          deleteFunc={() => handleEdit(room._id)}
                          toast={() => showSuccessToast('Showroom deleted successfully!')}
                        />
                    )}
                </>
                }
                {
                room.templateId!==5&&room.templateId!==6&&room.templateId!==7?
                <ShowRoom2Template
                itemsClass={
                  room.templateId=== 4
                  ? "template-4" :  room.templateId >=8 && room.templateId <= 11? `portrait-template portrait-template-${room.templateId}`
                    : room.templateId===3 ? "template-3" :
                    room.templateId >= 12 && room.templateId <= 15 ? `single-template single-template-${room.templateId}` :
                     `item-center item-center-${room.templateId}`
                  }
                  slideNumber={showroomTemplates.filter(item => item.id === parseInt(room.templateId))[0].numberOfItems}
                  onChangeEditor={()=>{}}
                  index={room._id}
                  isPreview={true}
                  showRoomData={room}
                  isMicrosite={true}
                  setViewingItem={setViewingItem}/>:
                  <ShowRoom2Template  
                  itemsClass={
                  room.templateId=== 4
                  ? "template-4" :  room.templateId >=8 && room.templateId <= 11? `portrait-template portrait-template-${room.templateId}`
                    : room.templateId===3 ? "template-3" :
                    room.templateId >= 12 && room.templateId <= 15 ? `single-template single-template-${room.templateId}` :
                     "item-center"
                  }
                  slideNumber={showroomTemplates.filter(item => item.id === parseInt(room.templateId))[0].numberOfItems}
                  onChangeEditor={() => {}}
                  tooltip={true}
                  icons={true}
                  headerImage={selectedShowRooms[index]?.headerImage ? STATIC_URL+selectedShowRooms[index]?.headerImage : showroomTemplates.filter(item => item.id === parseInt(room.templateId))[0].headerImage}
                  index={room._id}
                  isPreview={true}
                  showRoomData={room}
                  isMicrosite={true}
                  isExpandable={true}
                  setViewingItem={setViewingItem}/>
                }
              </label>
              
            ))}
          </div>
        </div>
      </div>
      </>: isSharingMode ?<CoverpageDetailedPage isEdit={isEdit} showCoverPage={showCoverPage} setShowCoverPage={setShowCoverPage}  isMicrosite={true} data={props.sharedCoverpageData} id={props.sharedCoverpageId} 
        />: <CoverpagePage isEdit={isEdit}   isMicrosite={true} setShowCoverPage={setShowCoverPage} setNoCoverpageOption={setNoCoverpageOption} micrositeData={{...props.queryMicrosite.data?.microsite}} microsite={microsite} setMicrosite={setMicrosite} isCreateMode={isCreateMode}/>}
      {isLoading && <IsLoading />}
      <PopUp state={popup} hide={hidePopup} width="50%" maxWidth="600px">
      <form onSubmit={handleMutation}
                >
          <div className="center-content">
            <p className="title">Create Microsite</p>
            <div className={`text name`}>
              <Title>Name</Title>
              <Input
                placeholder="Name Your Microsite"
                type="text"
                name="link"
                onChange={(e)=>handleNameChange(e.target.value)}
                required={true}
                autoFocus
                nrlength="28"
              />
            </div>
            
            <div className="buttons-popup">
              <button onClick={() => hidePopup(true)} className="finish">
                Cancel
              </button>
              <button
                className="finish"
                type="submit"
              >
                Done
              </button>
            </div>
          </div>
          </form>
        </PopUp>
        <PopUp state={deletePopup} hide={hideDeletePopup} width="100%" maxWidth="800px">
          <div className="center-content">
            <p className="title">Delete Microsite</p>
            <p className="title">Are you sure you want to delete the entire microsite?</p>
          
            <div className="buttons-popup">
              <button onClick={hideDeletePopup} className="finish">
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteMicrosite();
                }}
                className="finish"
              >
                Done
              </button>
            </div>
          </div>
        </PopUp>
        {openModal.open && (
       <Modal animation={false}
       show={openModal.open} hide={!openModal.open}
       >
         <UploadModal
          closeModal={setOpenModal}
          modal={openModal}
          image={imageChangesQueue}
          setImage={setImageChangesQueue}
          title="Change Microsite Background"
          microsite={true}
        />
       </Modal>
      )}
    </div>
  );
};

export default RoomSlider;
