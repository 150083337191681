import StickyNav from "containers/NavBar";
import ShowroomTemplates from "containers/Templates/showrooms";
import React from "react";

function ShowRooms(){
    return(
        <>
        <StickyNav/>
        <ShowroomTemplates />
        </>
    );
}
export default ShowRooms;