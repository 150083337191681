// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../environment/index.js";

// 2
const mutation = graphql`
  mutation CreateShowroomMutation($showroom: newShowroom) {
    createShowroom(showroom: $showroom) {
      ... on Showroom {
        _id
        id
        name
        portalId
        mainHeading
        subHeading
        templateId
        tags
        link
        accountId
        creator
        coverpagesIds
        backgroundImage
        createdAt
        updatedAt
        content {
          name
          content {
            image
            mainHeading
            presentationId
            subHeading
            imageUrl
            presentation {
              coverUrl
            }
            # files {
            #   _id
            #   name
            #   relatedPresentationIds
            #   fileUrl
            #   fileType
            #   coverUrl
            #   thumbnailUrl
            #   visibility
            #   creator
            # }
          }
        }
      }
      ... on Response {
        __typename
        message
        status
        success
        token
        hash
        signedUrl
        userId
      }
    }
  }
`;

const createShowroom = (showroom, callback) => {
  // 4
  const variables = {
    showroom,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      window.store = store;
    },
  });
}; // 3

export default createShowroom;
