import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const OrderRooms = ()=>{
// fake data generator[]
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

const [itemsList, setItemsList] = useState(getItems(7));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 3;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  boxSizing: "border-box",
  width:"300px",
  alignItems:"center",
  justifyContent:"center",
  cursor:"pointer",
  background:isDragging ? "#202020 0% 0% no-repeat padding-box":"#cdcdcd 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 20px #000000",
  borderRadius: "5px",
  color: "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  width:"90vw",
//   width: itemsLength * 68.44 + 16,


});




  const onDragEnd = (result) =>{
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      itemsList,
      result.source.index,
      result.destination.index
    );

    setItemsList(items);
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  
    return (
      <div style={{ overflow: "scroll", height: 200 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(
                  snapshot.isDraggingOver,
                  itemsList.length
                )}
                {...provided.droppableProps}
              >
                {itemsList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  
}
export default OrderRooms;