/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetPortalsQueryVariables = {||};
export type GetPortalsQueryResponse = {|
  +portals: ?{|
    +portals?: $ReadOnlyArray<{|
      +menus: $ReadOnlyArray<?{|
        +folders: $ReadOnlyArray<?{|
          +presentations: $ReadOnlyArray<?{|
            +coverUrl: ?string,
            +files: $ReadOnlyArray<?{|
              +_id: string,
              +name: string,
              +relatedPresentationIds: $ReadOnlyArray<string>,
              +fileUrl: string,
              +fileType: string,
              +coverUrl: ?string,
              +thumbnailUrl: ?string,
              +audioUrl: ?string,
              +visibility: ?boolean,
              +creator: string,
              +positions: $ReadOnlyArray<?{|
                +presentationId: string,
                +position: number,
              |}>,
              +createdAt: any,
              +updatedAt: ?any,
            |}>,
            +_id: string,
            +name: string,
          |}>,
          +_id: string,
          +name: string,
        |}>,
        +_id: string,
        +name: string,
      |}>,
      +_id: string,
      +name: string,
    |}>
  |}
|};
export type GetPortalsQuery = {|
  variables: GetPortalsQueryVariables,
  response: GetPortalsQueryResponse,
|};
*/


/*
query GetPortalsQuery {
  portals {
    __typename
    ... on ListOfPortals {
      portals {
        menus {
          folders {
            presentations {
              coverUrl
              files {
                _id
                name
                relatedPresentationIds
                fileUrl
                fileType
                coverUrl
                thumbnailUrl
                audioUrl
                visibility
                creator
                positions {
                  presentationId
                  position
                }
                createdAt
                updatedAt
              }
              _id
              name
            }
            _id
            name
          }
          _id
          name
        }
        _id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverUrl",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Portal",
      "kind": "LinkedField",
      "name": "portals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Menu",
          "kind": "LinkedField",
          "name": "menus",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Folder",
              "kind": "LinkedField",
              "name": "folders",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Presentation",
                  "kind": "LinkedField",
                  "name": "presentations",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "files",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "relatedPresentationIds",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileType",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thumbnailUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "audioUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "visibility",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "creator",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Position",
                          "kind": "LinkedField",
                          "name": "positions",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "presentationId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "position",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "updatedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfPortals",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetPortalsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "portals",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetPortalsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "portals",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c36172ab3315f535a4e0fbec4fcfabe2",
    "id": null,
    "metadata": {},
    "name": "GetPortalsQuery",
    "operationKind": "query",
    "text": "query GetPortalsQuery {\n  portals {\n    __typename\n    ... on ListOfPortals {\n      portals {\n        menus {\n          folders {\n            presentations {\n              coverUrl\n              files {\n                _id\n                name\n                relatedPresentationIds\n                fileUrl\n                fileType\n                coverUrl\n                thumbnailUrl\n                audioUrl\n                visibility\n                creator\n                positions {\n                  presentationId\n                  position\n                }\n                createdAt\n                updatedAt\n              }\n              _id\n              name\n            }\n            _id\n            name\n          }\n          _id\n          name\n        }\n        _id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dff3478e757625d6eb8c18ebe9bc0c15';

module.exports = node;
