import { useQuery } from "relay-hooks";
import List from "containers/lists";
import EditCoverpageMutation from "graphql/mutations/EditCoverpageMutation";
import DeleteCoverpageMutation from "graphql/mutations/DeleteCoverpageMutation";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import GetCoverpagesByPortalQuery from "graphql/queries/GetCoverpagesByPortal";

import logo1 from "assets/images/coverpage_white.png";

import { useState } from "react";
import { useEffect } from "react";
import IsLoading from "../IsLoading";
import responseHandler from "helpers/responseHandler";

export function CoverPageList() {
  const [coverpages, setCoverPages] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  //Get Actual Portal
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  //Fetch Data with actual PortalId
  const { data, isLoading } = useQuery(
    GetCoverpagesByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );

  useEffect(() => {
    if (isLoading) return;
    setCoverPages(data?.getCoverpagesByPortalId.coverpages);
  }, [isLoading, data?.getCoverpagesByPortalId.coverpages]);

  const navigation = useNavigate();

  const handleEditCoverpage = coverpage => {
    const {
      _id: editCoverpageId,
      id,
      __typename,
      updatedAt,
      templateId,
      createdAt,
      creator,
      ...edited
    } = coverpage;
    let errors = "";
    if (!edited.name) {
      errors = "Name";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} is required`);
    }
    EditCoverpageMutation(editCoverpageId, edited, response => {
      responseHandler(response, "Coverpage edited successfully.");
    });
  };

  const handleDeleteCoverPage = id => {
    setIsDeleting(true);
    DeleteCoverpageMutation(id, response => {
      responseHandler(response, "Coverpage deleted successfully.");
      if (response.deleteCoverpage) {
        setIsDeleting(false);
      } else setIsDeleting(false);
    });
  };

  if (isLoading) return <IsLoading isCoverpage/>;
  if (!coverpages) return null;

  // Remove logging after usage
  return (
    <List
      isList
      isInStepOne={false}
      title="Cover Page"
      logo={logo1}
      bannerImg="https://master-wizr-uploads.s3.amazonaws.com/studio/bg-coverpages.png"
      items={coverpages}
      isLoading={isLoading}
      handleEdit={handleEditCoverpage}
      deleteFunc={handleDeleteCoverPage}
      maxPerPage={9}
      isDeleting={isDeleting}
    ></List>
  );
}

export default CoverPageList;
