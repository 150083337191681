export const getFileExtension = filename => {
  return filename.split(".").pop();
};
/**
 * Calculate the file positions for the given presentation id
 * @param {*} id Presentation id for the files whos positions are to get calculated
 * @param {*} files Files for the with presentations to find the presentationId positions
 * @returns [files] with positions adjusted for the given presentations
 */
 export const positionFiles = (id, files) => {
  const tempFiles = [];
  if (!files || !files.length) return [];
  for (let i = 0; i < files.length; i += 1) {
    const { positions, ...rest } = files[i];
    const position = positions?.find(it => it.presentationId === id)?.position;
    tempFiles.push({ position, ...rest, id: rest._id });
  }
  return tempFiles.sort((a, b) => a.position - b.position);
};