import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.443);
  backdrop-filter: blur(26px);
`;

const PopupWrapper = styled.div`
  border-radius: 8px;
  background: #151515;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.905);
  h2 {
    background-image: linear-gradient(to right, rgb(251 65 65), #e78f36);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  h4 {
    color: #8e8d8d;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media(max-width: 1550px){
    h2{
      font-size: 2.2rem
    }
    h4 {
      font-size: 1.2rem;
    }
  }
`;

const Loader = styled.div`
height: 8px;
width: 200px;
--c: no-repeat linear-gradient(to right, rgb(251, 65, 65), #e78f36);
background: var(--c), var(--c), linear-gradient(90deg, #5721b7 -10.17%, #d668cd -5.26%, transparent 0%);
background-size: 60% 100%;
animation: l16 5s infinite;
box-shadow: 0 0 5px rgba(255, 255, 255, 0.553);
border-radius: 2px;
margin-top: 2%;
@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}
}
`;

function IsLoading({ isShowroom, isCoverpage, isMicrosite, isContent, micrositeContent, title }) {
  const { t } = useTranslation();
  return (
    <Wrapper className="isloadingpopup">
      <PopupWrapper>
        <h2>{t("Please wait")}</h2>
        <h4>
        {title ? null : `${t("Your")} `}
          {title || (isShowroom
            ? `Showrooms`
            : isCoverpage
            ? "Cover Pages"
            : isMicrosite
            ? "Microsites"
            : micrositeContent 
            ? "Microsite"
            : `content`)}{" "}
          {isContent || micrositeContent ? `is` : `are`}{title ? ` ${t("opening")}` : ` ${t("being updated")}`}
        </h4>
        <Loader />
      </PopupWrapper>
    </Wrapper>
  );
}
export default IsLoading;
