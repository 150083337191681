import PropTypes from "prop-types";
import "./solid.scss";
import { Link } from "react-router-dom";
import { Heading } from '../../../../containers/Templates/styles';
import { useTranslation } from "react-i18next";

function Solid({ title, list, checkLicense }) {
  const {t} = useTranslation();

    return (
        <div className="solid images-container">
            <Heading className="titleBrightMode">{t(title)}</Heading>
            <div className="content">
                {list.map((d, i) => {
                    return (
                        <Link to={`/coverpage-detailed/${d.id}`}
                            state={{ list: d }}
                            key={i} style={{ backgroundImage: `url(${d.image})` }} className={`blur ${d.extraClass}`}
                            onClick={() => {
                                checkLicense();
                              }}
                        >
                            {d.twodiv ?
                                <div className="two-blur">
                                    <div className="different-place">
                                        <div className="first line">
                                        </div>
                                        <div className="second line">
                                        </div>
                                        <div className="third line">
                                        </div>
                                    </div>
                                    <div className="different-place ">
                                        <div className="first line">
                                        </div>
                                        <div className="second line">
                                        </div>
                                        <div className="third line">
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="image-part ">
                                        <div className="first line"> 
                                        </div>
                                        <div className="second line">
                                        </div>
                                        <div className="third line">
                                        </div>
                                        <div className="four line">
                                        </div>
                                        <div className="five line">
                                        </div>
                                </div>
                            }
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}
export default Solid;
Solid.propTypes = {
    list: PropTypes.array,
    title: PropTypes.string,
};
