import { Link } from "react-router-dom";
import { FlexContainer } from '../flexContainer';
import StudioIcon from "./mw-studio-icon.svg";
import { StudioLogo, Header, MWLogo, NavItem } from "./styles";
import MwHqOrange from "assets/images/MW_HQ-ORANGE.png";
import NavMenu from "../../components/ui/organisms/NavMenu";

export function PreviewNavBar(props){
    return(
        <FlexContainer pd="25px 30px 0px 30px" spaceBetween>
            {
                props.menu ?
                ""
                :
                <NavMenu />
            }
            <Link to='/templates'>
                <FlexContainer vCenterAlign>
                    <StudioLogo src={StudioIcon} />
                    <Header orange>MW&nbsp;<b>Studio</b></Header>
                </FlexContainer>
            </Link>
            <FlexContainer> <MWLogo src={MwHqOrange} /></FlexContainer>
        </FlexContainer>
    )
}

export default PreviewNavBar;
