const design1 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design1.png"
const design2 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design2.png"
const design3 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design3.png"
const design4 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design4.png"
const design5 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design5.png"
const design6 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design6.png"
const design7 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design7.png"
const design8 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design8.png"
const design9 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design9.png"
const design10 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design10.png"
const design11 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design11.png"
const design12 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design12.png"
const design13 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design13.png"
const design14 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design14.png"
const design15 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design15.png"
const design16 = "https://master-wizr-uploads.s3.amazonaws.com/studio/design16.png"
const overlay1 = "https://master-wizr-uploads.s3.amazonaws.com/studio/overlay1.png"
const overlay2 = "https://master-wizr-uploads.s3.amazonaws.com/studio/overlay2.png"
const overlay3 = "https://master-wizr-uploads.s3.amazonaws.com/studio/overlay3.png"
const overlay4 = "https://master-wizr-uploads.s3.amazonaws.com/studio/overlay4.png"

export const image = [
    {
        image: design1,
        textColor: "#000000"
    },
    {
        image: design2,
        textColor: "#000000"
    },
    {
        image: design3,
        textColor: "#000000"
    },
    {
        image: design4,
        textColor: "#000000"
    },
    {
        image: design5,
        textColor: "#000000"
    },
    {
        image: design6,
        textColor: "#FFFFFF"
    },
    {
        image: design7,
        textColor: "#000000"
    },
    {
        image: design8,
        textColor: "#000000"
    },
    {
        image: design9,
        textColor: "#000000"
    },
    {
        image: design10,
        textColor: "#000000"
    },
    {
        image: design11,
        textColor: "#000000"
    },
    {
        image: design12,
        textColor: "#000000"
    },
    {
        image: design13,
        textColor: "#000000"
    },
    {
        image: design14,
        textColor: "#000000"
    },
    {
        image: design15,
        textColor: "#000000"
    },
    {
        image: design16,
        textColor: "#000000"
    }
];

export const overlay = [
    {
        image: overlay1,
    },
    {
        image: overlay2,
    },
    {
        image: overlay3,
    },
    {
        image: overlay4,
    }
];
