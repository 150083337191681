import graphql from "babel-plugin-relay/macro";

export const WatchPortalUpdates = graphql`
  subscription WatchPortalUpdatesSubscription($id: ID!) {
    portalUpdates(id: $id) {
      _id
      name
      menus {
        name
        _id
        visibility
        folders {
          _id
          name
          presentations {
            _id
            name
            files {
              fileType
              fileUrl
              name
              _id
            }
          }
        }
      }
    }
  }
`;

export const WatchPortalUpdatesStudio = graphql`
  subscription WatchPortalUpdatesStudioSubscription($id: ID!) {
    portalUpdates(id: $id) {
      _id
      name
      coverpages {
        _id
        id
        details
        mainHeading
        name
        portalId
        showroomIds
        templateStyle {
          cardPosition
          BackgroundBlur
        }
        showroomLink
        templateId
        thumbnail
      }
      showrooms {
        _id
        id
        portalId
        name
        mainHeading
        subHeading
        headerImage
        templateId
        tags
        link
        content {
          name
          content {
            image
            imageUrl
            mainHeading
            presentationId
            subHeading
            presentation {
              coverUrl
              portalId
              name
              menuId
              folderId
            }
            files {
              _id
              name
              relatedPresentationIds
              fileUrl
              fileType
              coverUrl
              thumbnailUrl
              visibility
              creator
            }
          }
        }
      }
      microsites {
        _id
        id
        name
        showroomsIds
        backgroundImage
        coverpageId
        tags
        templateId
        portalId
        creator
        accountId
        thumbnailUrl
      }
      magazines {
        _id
        id
        name
        backgroundColor
        backgroundImage
        mainTemplateId
        subTemplateId
        portalId
        rows {
          content {
            description
            headerImage
            fileUrl
            videoThumbnail
            cardThumbnail
            headerOverlayContent
            heading
            subHeading
            linkTo
            buttonText
            graphData {
              attr1
              attr2
              attr3
              data {
                name
                val1
                val2
                val3
              }
            }
          }
          isShown
        }
        thumbnail
        tags
        creator
        accountId
      }
    }
  }
`;

export const WatchUserUpdates = graphql`
  subscription WatchUserUpdatesSubscription($id: ID!) {
    userUpdates(id: $id) {
      profiles {
        _id
        userId
        accountId
        accountRole
        accountInfo {
          isActive
          _id
          name
          licenseInfo {
            licenseType
          }
        }
        userInfo {
          _id
          firstName
          lastName
          email
        }
        accessedModules {
          name
          role
          tier
        }
        accessedPortals {
          portalId
          portalRole
          portalInfo {
            _id
            accountId
            name
            creator
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
