const { default: toastFactory } = require("./toastFactory");

const responseHandler = (
  response,
  customSuccessMessage,
  onSuccess,
  customErrorMessage,
  onError
) => {
  if (response.message) {
    toastFactory.generateErrorMessage({
      message: customErrorMessage || response.message,
    });
    if (onError) onError();
  } else if (response) {
    const queryObject = response[Object.keys(response).filter(key => key !== "__typename")[0]];
    if (
      queryObject &&
      queryObject.__typename === "Response" &&
      !queryObject.success
    ) {
      // failed query
      toastFactory.generateErrorMessage({
        message: customErrorMessage || queryObject.message,
        status: queryObject.status,
      });
      if (onError) onError();
    } else if (queryObject?._id || queryObject?.success) {
      //success query
      if(customSuccessMessage) toastFactory.generateSuccessMessage(customSuccessMessage);
      else if (queryObject?.message) toastFactory.generateSuccessMessage(queryObject?.message);
      if (onSuccess) onSuccess(queryObject);
    }else{
      toastFactory.generateErrorMessage({
        message: "Error, please try again later",
      });
    }
  }
};

export default responseHandler;
