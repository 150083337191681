import React from "react";
import MainTemplate from "../../atoms/MainTemplate/MainTemplate";
import PropTypes from "prop-types";

function ShowRoom2Template({
  showRoomData,
  onChangeEditor,
  imagesTemplate,
  uploaded,
  tooltip,
  icons,
  isPreview,
  itemsClass,
  slideNumber,
  detailsOfTemplate,
  headerImage,
  isExpandable,
  isMicrosite,
  setViewingItem,
  uploadHeader,
  imageChangesQueue,
  setImageChangesQueue,
}) {
  return (
    <>
      <MainTemplate
        list={showRoomData}
        isPreview={isPreview}
        title={showRoomData?.mainHeading}
        isSubHead={true}
        description={showRoomData?.subHeading}
        onChangeEditor={onChangeEditor}
        imagesTemplate={imagesTemplate}
        uploaded={uploaded}
        tooltip={tooltip}
        icons={icons}
        itemsClass={itemsClass}
        slideNumber={slideNumber}
        detailsOfTemplate={detailsOfTemplate}
        headerImage={headerImage}
        isExpandable={isExpandable}
        isMicrosite={isMicrosite}
        setViewingItem={setViewingItem}
        uploadHeader={uploadHeader}
        imageChangesQueue={imageChangesQueue}
        setImageChangesQueue={setImageChangesQueue}
      />
    </>
  );
}
export default ShowRoom2Template;

ShowRoom2Template.propTypes = {
  showRoomData: PropTypes.object,
  onChangeEditor: PropTypes.func,
  imagesTemplate: PropTypes.array,
  uploaded: PropTypes.bool,
  isPreview: PropTypes.bool,
};
