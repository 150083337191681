import NotFound from "components/hocs/404";
import General from "components/hocs/General";
import DetailedPage from "../components/ui/organisms/DetailedPage";
import Preview from "../components/ui/organisms/DetailedPage/SharingMode";
import CoverPageList from "components/ui/atoms/CoverPages/coverPagesList";
import CoverPageListSelect from "components/ui/atoms/CoverPages/coverPagesListSelect";
import ShowroomsList from "components/ui/atoms/Showrooms/ShowroomsList";
import RenderShowroom from "containers/Templates/showrooms/renderShowroom";
import CoverPagePreivew from "components/ui/organisms/CoverPagePreview/CoverPagePreview";
import MicrositesList from "components/ui/atoms/Microsites/MicrositesList";
import CoverpagePage from "components/ui/organisms/CoverpageDetailedPage/CoverpagePage";
import SharedContentPageDetails from "components/ui/organisms/SharedContentPage";
import MicrositePage from "components/ui/organisms/MicrositePage";
import OrderRooms from "components/ui/molecules/OrderRooms/OrderRooms";
import ShowRooms from "components/ui/organisms/ShowRoomTemplates";
import CoverPagesRoute from "components/ui/organisms/CoverPagesRoute";
import Magazine from "components/ui/organisms/Magazine/index";
import Home from "components/ui/organisms/Home/Home";
import FrontGuide from "components/ui/organisms/FrontGuide/FrontGuide";

export const pages = [
  {
    path: "/showrooms/:id",
    exact: true,
    layout: General,
    element: RenderShowroom,
  },
  {
    path: "/order-rooms",
    exact: true,
    layout: General,
    element: OrderRooms,
  },
  {
    path: "/showroom-template-expandable/:id",
    exact: true,
    layout: General,
    element: RenderShowroom,
  },
  {
    path: "/showroom-templates",
    exact: true,
    layout: General,
    element: ShowRooms,
  },
  {
    path: "/coverpage-templates",
    exact: true,
    layout: General,
    element: CoverPagesRoute,
  },
  {
    path: "/cover-pages/:showroomids/:mode/:selectedCover/:templateId",
    exact: true,
    layout: General,
    element: CoverPageListSelect,
  },
  // This route has to be /cover-pages
  {
    path: "/cover-pages",
    exact: true,
    layout: General,
    element: CoverPageList,
  },
  {
    path: "/showrooms",
    exact: true,
    layout: General,
    element: ShowroomsList,
  },
  {
    path: "/edit-microsite/:id/:coverpageid/",
    exact: true,
    layout: General,
    element: ShowroomsList,
  },
  {
    path: "/create-microsite/:id/:selectedCover/:templateId",
    exact: true,
    layout: General,
    element: ShowroomsList,
  },
  {
    path: "/edit-coverpage-microsite/:showroomids/:mode/:micrositeId/:coverpageId/:templateId",
    exact: true,
    layout: General,
    element: CoverPageListSelect,
  },
  {
    path: "/preview-cover/:id",
    exact: true,
    layout: General,
    element: CoverPagePreivew,
  },
  {
    path: "/detailed-page/:id",
    exact: true,
    layout: General,
    element: DetailedPage,
  },
  {
    path: "/coverpage-detailed/:id",
    exact: true,
    layout: General,
    element: CoverpagePage,
  },
  {
    path: "/microsites",
    exact: true,
    layout: General,
    element: MicrositesList,
  },
  {
    path: "/detailed-page/:id/sharing",
    exact: true,
    layout: General,
    element: Preview,
  },
  {
    path: "/microsite/:id/:coverpageid",
    exact: true,
    layout: General,
    element: MicrositePage,
  },
  {
    path: "/share/:slug",
    exact: true,
    layout: General,
    element: SharedContentPageDetails,
  },
  {
    path: "/magazine/*",
    exact: true,
    layout: General,
    element: Magazine,
  },
  {
    path: "/studio-guide",
    exact: true,
    layout: General,
    element: Home,
  },
  {
    path: "/start-guide",
    exact: true,
    layout: General,
    element: FrontGuide,
  },
  {
    path: "*",
    element: NotFound,
    layout: NotFound,
  },
];
