import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FlexContainer } from "../flexContainer";

export const Wrapper = styled.nav`
  background: #050505;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  padding: 15px 40px 10px 25px;
  justify-content: space-between;
  @media (max-height: 650px) {
    padding: 10px 25px;
  }
`;

export const StudioLogo = styled.img`
  width: 30px;
  height: 25px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
  @media (min-height: 700px) {
    width: 35px;
    height: 30px;
  }
  @media (min-width: 3000px) {
    width: 50px;
    height: 45px;
  }
  @media (min-width: 4000px) {
    width: 60px;
    height: 55px;
  }
`;

export const Header = styled.span`
  color: #edeeef;
  font-size: 16px;
  margin-left: 17px;
  cursor: default;
  b {
    font-weight: 900;
    color: ${props => (props.orange ? "#FF8F00" : "#EDEEEF")};
  }
  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 5px;
  }
  @media (min-width: 2560px) {
    font-size: 24px;
  }
  @media (min-width: 3000px) {
    font-size: 33px;
  }
  @media (min-width: 4000px) {
    font-size: 44px;
  }
`;

export const MWLogo = styled.img`
  width: 25px;
  height: 25px;
  @media (min-width: 3000px) {
    width: 45px;
    height: 45px;
  }
  @media (min-width: 4000px) {
    width: 55px;
    height: 55px;
  }
  @media (min-height: 700px) {
    width: 30px;
    height: 30px;
  }
`;

export const NavItem = styled(NavLink)`
  font-size: 12px;
  text-transform: capitalize;
  color: #ffffff;
  margin-right: 0px;
  margin-top: 5px;
  opacity: 50%;
  font-weight: normal;
  cursor: pointer;
  list-style-type: none;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    font-weight: 550;
  }

  &.active {
    opacity: 1;
    font-weight: 600;
  }
  @media (min-height: 700px) {
    font-size: 14px;
  }
  @media (min-width: 1050px) {
    margin-right: 40px;
  }
  @media (min-width: 1280px) {
    margin-right: 80px;
  }
  @media (min-width: 2560px) {
    font-size: 20px;
  }
  @media (min-width: 3000px) {
    font-size: 31px;
    margin-top: 0px;
  }
  @media (min-width: 4000px) {
    font-size: 43px;
  }
`;
export const ItemsWrapper = styled(FlexContainer)`
  max-width: 1000px;
  @media (min-width: 3000px) {
    max-width: 1600px;
  }
`;

export const DropdownWrapper = styled(FlexContainer)`
  width: 100%;
  height: 100vh;
  background: white;
  position: fixed;
  z-index: 99999999;
  color: black;
  top: 0px;

  @media (min-width: 768px) {
    width: 380px;
    height: unset;
    top: 78px;
    right: 10px;
    border-radius: 16px;
    box-shadow: 0px 15px 40px gray;
  }
  @media (min-width: 2560px) {
    top: 55px;
  }
  @media (min-width: 3000px) {
    top: 50px;
  }
`;

export const Btn = styled.button`
  border-radius: 8px;
  width: calc(100% - 40px);
  cursor: pointer;
  padding: 0px 20px;
  margin-top: 8px;
  height: 35px;
  border: none;
  color: #ffffff;
  opacity: 1;
  font-size: 15px;
  &.secBtn {
    color: black;
    background: rgba(256, 256, 256, 0.1);
  }
  &.addBtn {
    background: #ec7600;
  }
`;

export const ContentWrapper = styled(FlexContainer)`
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #282828;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b1b5bc24;
  }
  @media (min-width: 768px) {
    height: unset;
    max-height: 74vh;
  }
  @media (min-width: 1480px) {
    max-height: 78vh;
  }
  @media (max-height: 720px) {
    max-height: 70vh;
  }
`;

export const AccountsTitle = styled(FlexContainer)`
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 25px 20px;
  border-bottom: 1px solid rgba(256, 256, 256, 0.05);
  font-weight: bold;

  h3 {
    margin-bottom: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    opacity: 0.5;
    font-size: 13px;
  }

  .close {
    width: 30px;
    position: relative;
    height: 30px;
    left: 0;
    bottom: 15px;
    margin-right: -17px;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const HeaderWrapper = styled(FlexContainer)`
  width: 50px;
  height: 50px;
  background: rgba(256, 256, 256, 0.05);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  svg {
    fill: #ec7600;
  }
`;

export const Tile = styled(FlexContainer)`
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  margin: 8px 20px 0px 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const HorizontalRule = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const Footer = styled(FlexContainer)`
  align-self: flex-end;
  align-items: center;
`;
