import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DesignEditor.scss";

const Sidebar = ({ close, image, overlay, extraClass, changeBackgroudUrl }) => {

    const closeHandler = (e) => {
        e.preventDefault();
        close();
    };

    return (
        <div className={`sidebar ${extraClass}`}>
            <div>
                <p className="drag-drop">Drag and drop</p>
                <p className="text-img">Images</p>
                <div className="images-design">
                    {image.map((d, index) => {
                        return (
                            <img key={index} src={d.image} alt="templateimg" onClick={() => {
                                changeBackgroudUrl(d.image, d.textColor);
                            }}/>
                        );
                    })}
                </div>
                <p className="text-img">Overlay</p>
                <div className="overlay">
                    {overlay.map((d, index) => {
                        return (
                            <div className="image">
                                <img key={index} src={d.image} alt="templateimg" />
                            </div>
                        );
                    })}
                </div>
            </div>
            <button id="close" onClick={closeHandler}></button>
        </div>
    );
};

export default Sidebar;
Sidebar.propTypes = {
    close: PropTypes.func,
    image: PropTypes.array,
    overlay: PropTypes.array,
    extraClass: PropTypes.string
};