import styled from "styled-components";
import DeleteIcon from "../deleteIcon";
import ShareIcon from "../shareIcon";
import { Image } from "../image";
import { ToastContainer, toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "containers/Modals/deleteModal";
import DeleteShowroomMutation from "graphql/mutations/DeleteShowroomMutation";
import { ReactComponent as Settings } from "assets/images/settings.svg";
import EditInput from "components/ui/atoms/Input/editInput";
import Sharing from "components/ui/atoms/ShareModal/Sharing";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import { useQuery } from "relay-hooks";
import { SUPER_ROLES, PORTAL_ROLES, STATIC_URL } from "constants/env";
import CustomeModel from "components/ui/atoms/Modals/CustomeModel";
import StyledButton from "components/ui/atoms/Buttons/StyledButton";
import checkTypes from "../../helpers/checkTypes";
import { VideoPlayer } from "@master-wizr/video-player";
import MicrositeByCoverpageId from "graphql/queries/MicrositeByCoverpageId";
import MicrositeByShowroomId from "graphql/queries/MicrositeByShowroomId";
import UploadModal from "containers/Modals/UploadModal";
import { uploadFiles, uploadFilesAndDeleteByUrl, deleteFilesByUrls } from "helpers/fileUpload";
import EditMicrositeMutation from "graphql/mutations/EditMicrositeMutation";
import { ShowroomCard } from "components/ui/atoms/ShowroomCard/ShowroomCard";
import { thumbnailGenerator } from "helpers/thumbnailGenerator";
import responseHandler from "helpers/responseHandler";
import ShareableLink from "components/ui/atoms/ShareModal/shareableLink";
import Tags from "../Tags";
import ImgModal from "components/ui/atoms/ImgModal/ImgModal";
import EditShowroomMutation from "graphql/mutations/EditShowroomMutation";
import EditCoverpageMutation from "graphql/mutations/EditCoverpageMutation";

import ListCardLoader from "components/ui/atoms/ListCardLoader";
import WarnModal from "containers/Modals/Warn";
import { Modal } from "react-bootstrap";

const ImageItemWrapper = styled.div`
  position: relative;
  margin-bottom: 70px;
  cursor: pointer;
  width: ${props => (props.cover ? "100%" : "fit-content")};
`;

const Button = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(256, 256, 256, 0.08);
  position: absolute;
  right: 5px;
`;

const AddThumbnail = styled(Button)`
  top: 40px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const ListItems = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 9, 9, 0.815);
  left: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  z-index: 1;
`;
const ListButton = styled.button`
  width: 100%;
  padding-left: 10px;
  text-align: left;
  color: white;
  padding: 5px;
  padding-left: 1rem;
  font-size: 15px;
  &:hover {
    font-weight: bold;
  }
  @media (min-width: 2560px) {
    font-size:20px;
  }
  @media (min-width: 3000px) {
    font-size:30px;
  }
  @media (min-width: 4000px) {
    font-size:40px;
  }
`;
const Footer = styled.div`
  color: white;
  margin-top: 0px;
  font-size: 14px;
  text-align: left;
  position: absolute;
  opacity: 0.5;
  margin-left: ${props => (props.check ? "22px" : "5px")};
  width: 100%;
  &:hover {
    opacity: 1;
  }
  input {
    padding-left: 0px;
  }
  @media (min-height: 900px) {
    font-size:16px;
  }
  @media (min-width: 2000px) {
    margin-top:8px;
  }
  @media (min-width: 3000px) {
    margin-top:20px;
    margin-left: 35px;
  }
  @media (min-width: 4000px) {
    margin-top:22px;
  }
`;

export function ListImageComponent(props) {
  const navigation = useNavigate();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const {
    onClick,
    url,
    name,
    id,
    tag,
    tags,
    deleteFunc,
    showrooms,
    handleEdit,
    mainHeading,
    subHeading,
    item,
    check,
    isShowroom,
    microsite,
    selectedList,
    setSelectedList,
    typeOfCard,
    micrositeData,
    micrositeTypeOfCard,
    unAuthorized,
    itemsData,
    openSettings,
    toggleSettings
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shareableLink, setShareableLink] = useState(null);
  const [options, showOptions] = useState(false);
  const [rename, setRename] = useState(true);
  const [checkOption, setCheckedOption] = useState(false);
  const [fieldState, setFieldState] = useState({ value: name });
  const [selectedListShowroom, setSelectedListShowroom] = useState([]);
  const [selectedAsCoverPage, setSelectedAsCoverPage] = useState(false);
  const [share, setShare] = useState(false);
  const [openModal, setOpenModal] = useState({open: false});
  const [imageChangesQueue, setImageChangesQueue] = useState([]);
  const [popup, setPopup] = useState(false);
  const [tagsList, setTagsList] = useState(tags?.length ? tags : []);
  const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [coverPageTags, setCoverPageTags] = useState(tags?.length ? tags : []);
  const [micrositeTags, setMicrositeTags] = useState(item?.tags?.length ? tags : []);
  const coverPage = location.pathname.includes("/cover-pages");
  const [openSettingsDrop, setOpenSettings] = useState(false);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenSettings(false); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleKeyDown = (e, isCover, isMicrosite) => {
    let val = e.target.value;
  
    if (e.key === "Enter" && val.length > 1) {
      if (isCover) {
        setCoverPageTags((prevTags) => {
          if (!prevTags.includes(val)) {
            e.target.value = "";
            setTagValue("");
            return [...prevTags, val];
          } else {
            showErrorToast(`Tag already exists`);
            return prevTags;
          }
        });
      } else if (isMicrosite){
        setMicrositeTags((prevTags) => {
          if (!prevTags.includes(val)) {
            e.target.value = "";
            setTagValue("");
            return [...prevTags, val];
          } else {
            showErrorToast(`Tag already exists`);
            return prevTags;
          }
        });
      }
      else {
        setTagsList((prevTags) => {
          if (!prevTags.includes(val)) {
            e.target.value = "";
            setTagValue("");
            return [...prevTags, val];
          } else {
            showErrorToast(`Tag already exists`);
            return prevTags;
          }
        });
      }
    }
  };
  const handleRemoveTag = (tag, isCover) => {
    if (isCover) {
      setCoverPageTags((prev) => prev.filter((tagData) => tagData !== tag));
    } else {
      setTagsList((prev) => prev.filter((tagData) => tagData !== tag));
    }
  };

  const hidePopup = () => {
    setPopup(false);
  };

  const saveNewTags = () => {
    if(tagValue) return setShowPopUp(true)
    setIsEditing(true)
    setPopup(false)
    showOptions(false)
    try {
      const editShowroom = {...item}
      editShowroom.tags = tagsList;
      let contentArr = [] ;
      let presObj = {};
      let row={}
      let rows = [...editShowroom.content];
      for(let i = 0; i<rows.length; i++){
        row = {...editShowroom.content[i]}
        contentArr = [...row.content]
        for(let j = 0; j<contentArr.length; j++){
          presObj = {...contentArr[j]};
          delete presObj.files;
          contentArr[j] = {...presObj};
          row.content = contentArr;
          rows[i] = row;
        }
      }
      editShowroom.content = rows;
      const {
        _id: editShowroomId,
        id,
        updatedAt,
        templateId,
        accountId,
        createdAt,
        creator,
        __typename,
        ...edited
      } = editShowroom;
      EditShowroomMutation(editShowroomId, edited, response => {
        responseHandler(response, "Tags updated successfully!");
        hidePopup() 
        setIsEditing(false)
      });
    } catch (error) {
      setIsEditing(false)
      console.log(error);
      showErrorToast("Tags was not updated successfully!");
      return;
    }
  }
  
  const saveNewTagsCoverpage = () => {
    if(tagValue) return setShowPopUp(true)
    setIsEditing(true)
    setPopup(false)
    showOptions(false)
    try {
      const editCoverpage = {...item}
      editCoverpage.tags = coverPageTags;
      const {
        _id: editCoverpageId,
        id,
        updatedAt,
        templateId,
        accountId,
        createdAt,
        creator,
        __typename,
        ...edited
      } = editCoverpage;
      EditCoverpageMutation(editCoverpageId, edited, response => {
        responseHandler(response, "Tags updated successfully!");
        hidePopup() 
        setIsEditing(false)
      });
    } catch (error) {
      setIsEditing(false)
      console.log(error);
      showErrorToast("Tags was not updated successfully!");
      return;
    }
  }
  const saveNewTagsMicrosite = () => {
    if(tagValue) return setShowPopUp(true)
    setIsEditing(true)
    setPopup(false)
    showOptions(false)
    try {
      const editMicrosite = {...item}
      editMicrosite.tags = micrositeTags;
      const {
        id,
        updatedAt,
        templateId,
        accountId,
        createdAt,
        creator,
        __typename,
        ...edited
      } = editMicrosite;
      EditMicrositeMutation( edited, response => {
        responseHandler(response, "Tags updated successfully!");
        hidePopup() 
        setIsEditing(false)
      });
    } catch (error) {
      setIsEditing(false)
      console.log(error);
      showErrorToast("Tags was not updated successfully!");
      return;
    }
  }
  
  const SettingsButton = styled(Button)`
  top: 5px;
  right: 5px;
  z-index: 2;
  svg {
    margin-top: 5px;
    margin-top: 3px;
    transform:scale(0.9);
    path {
      opacity: ${options ? 1 : 0.5};
      &:hover {
        background: white;
        opacity: 1;
      }
    }
  }
  @media (min-width: 3000px) {
    transform: scale(1.5);
    top: 20px;
    right: 20px;
  }
  @media (min-width: 4000px) {
    transform: scale(2);
    top: 30px;
    right: 30px;
  }
`;
  
  const path = window.location.host;
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");

  useEffect(()=>{
    setSelectedListShowroom(selectedList);
  },[selectedList])

  useEffect(() => {
    if (selectedListShowroom.includes(props.id)) {
      setCheckedOption(true);
    } else {
      setCheckedOption(false);
    }
    if (props.selectedAsCoverPage) {
      setSelectedAsCoverPage(true);
      if (props.selectedCoverPage === id) {
        setCheckedOption(true);
      } else {
        setCheckedOption(false);
      }
    } else {
      setSelectedAsCoverPage(false);
    }
  }, [selectedListShowroom, props.selectedCoverPage]);

  const handleCheck = () => {
    if (!selectedAsCoverPage) {
      if (!isShowroom) {
        if (checkOption === false) {
          if (selectedListShowroom.length < 7) {
            setSelectedList([...selectedListShowroom, props.id]);
            setCheckedOption(true);
          } else {
            showErrorToast("Cannot select more than 7 showrooms");
          }
        } else {
          if (props.selectedList.length < 8) {
            const arr = [...props.selectedList];
            var index = arr.indexOf(props.id);
            if (index !== -1) {
              arr.splice(index, 1);
            }
            setSelectedList(arr);
          }
        }
      } else {
        if (checkOption === false) {
          setSelectedList([props.id]);
          setCheckedOption(true);
        } else {
          setSelectedList([]);
          setCheckedOption(false);
        }
      }
    } else {
      if (checkOption === false) {
        setCheckedOption(true);
        props.setSelectedCoverPage(id);
        props.setSelectedCoverPageTemplate(item.templateId);
      } else {
        setCheckedOption(false);
        props.setSelectedCoverPage(null);
        props.setSelectedCoverPageTemplate(null);
      }
    }
  };
  // TODO Disable delete if Id is present.

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const onRename = () => {
    setRename(!rename);
    showOptions(false);
  };

  const handleChange = e => {
    e.preventDefault();
    const value = e.target.value;
    if(value.length >= 28){
      showErrorToast("Name is not allowed more than 28 characters");
    }
    const key = e.target.name;
    setFieldState({
      ...fieldState,
      value,
    });
  };

  const onClose = () => {
    onRename();
    setFieldState({
      ...fieldState,
      value: name,
    });
  };

  const handleRename = (element) => {
      let name = fieldState.value.trim();
      let newItem = { ...item };
    
      const duplicateItem = itemsData?.find(item => item.name === name && item.id !== newItem.id);
      if (duplicateItem) {
        element.current.value = "";
        element.current.focus();
        showErrorToast("Name already exists, please try another name.");
        return;
      }
    
      if (name.length > 0) {
        element.current.value = name;
      }
    
      newItem.name = name;
      handleEdit(newItem);
      onRename();
  };

  const getItemName = () => {
    const { pathname } = location;
    return pathname.includes("cover-pages")
      ? "coverpage"
      : pathname.includes("showrooms")
      ? "showroom"
      : "microsite";
  };

  const updateMicrositeThumbmail = (link) => {
    const { id, name, portalId, showroomsIds, coverpageId, templateId } = item;
    let newMicrosite = {
      _id: id,
      name: "thumbnail",
      portalId: portalId,
      showroomsIds: showroomsIds,
      coverpageId: coverpageId,
      templateId: templateId,
      thumbnailUrl: link
    }
  
    EditMicrositeMutation(newMicrosite, response => {
      responseHandler(response, "Microsite Updated Successfully", ({_id}) =>{
        setImageChangesQueue([])
        setOpenModal({open:false});    
        showOptions(false);   
        setIsUploadingThumbnail(false); 
      })
    });
  };


  const updateThumnails = async () => {
    setIsUploadingThumbnail(true);
    showOptions(false)
    if(!imageChangesQueue.length) {
      return updateMicrositeThumbmail("");
    } else {


      const {uploadedResult : result} = await uploadFilesAndDeleteByUrl(imageChangesQueue,"thumbnails")
        if (!result) {
          console.log('failed to upload')
          // logic to handle upload fail
          setIsUploadingThumbnail(false);
        } else{
          const link= `${STATIC_URL}${result[0].thumbnailUrl}`;

          updateMicrositeThumbmail(link);
        }
      
    }
  }

  const deleteThumbnail = async (url) => {
    await deleteFilesByUrls([url]);
    const { id, name, portalId, showroomsIds, coverpageId, templateId } = item;
    let newMicrosite = {
      _id: id,
      name: "thumbnail",
      portalId: portalId,
      showroomsIds: showroomsIds,
      coverpageId: coverpageId,
      templateId: templateId,
      thumbnailUrl: ""
    }
  
    EditMicrositeMutation(newMicrosite, response => {
      responseHandler(response, "Microsite Updated Successfully", ({_id}) =>{
        setImageChangesQueue([])
        setOpenModal({open:false});     
        showOptions(false); 
      })
    });
  }
  useEffect(() => {
    document.querySelector(".done")?.addEventListener("click", updateThumnails);
    return () => {
      document.querySelector(".done")?.removeEventListener("click", updateThumnails);
    } 
  },[imageChangesQueue])

  const [settingsTooltip, setSettingsTooltip] = useState(false)

  const handleHitTagPrompt = () => {
    setPopup(true)
    setShowPopUp(false)
  }

  return (
    <>
      <ImageItemWrapper 
       ref={wrapperRef} 
       cover={window.location.pathname.includes("cover") ? true : false}>
        {microsite ? (
          micrositeData &&
          (item.thumbnailUrl !== "" && item.thumbnailUrl !== undefined ? (
            <>
            {isUploadingThumbnail? <ListCardLoader />:
            <ShowroomCard
              onClick={check ? handleCheck : onClick}
              item={item}
              typeOfCard="template"
              microsite={true}
            />}
              </>
          ) : (<>
            {isUploadingThumbnail? <ListCardLoader />:
            // <Image url={item.thumbnailUrl}  onClick={check ? handleCheck : onClick} />
            <ShowroomCard
              onClick={check ? handleCheck : onClick}
              item={micrositeData}
              typeOfCard={micrositeTypeOfCard(micrositeData?.templateId)}
            />}
            </>
          ))
        ) : item?.content === undefined ? (
          <Image url={url} onClick={check ? handleCheck : onClick} />
        ) : (<>
        {isEditing? <ListCardLoader />:
          <ShowroomCard
            onClick={check ? handleCheck : onClick}
            item={item}
            typeOfCard={typeOfCard}
          />}
        </>)}
        {check ? (
          <div
            onClick={() => handleCheck()}
            className={checkOption ? `circle active` : `circle`}
          ></div>
        ) : (
          !unAuthorized && (
            <>
              <SettingsButton
                onClick={()=>{toggleSettings();setOpenSettings(true)}}
                onMouseOver={() => setSettingsTooltip(true)}
                onMouseLeave={() => setSettingsTooltip(false)}
              >
                <Settings />
              </SettingsButton>
            </>
          )
        )}
        {settingsTooltip && <div className="settings-tooltip">Settings</div>}
        {/* {microsite && !unAuthorized && portal?.portalRole!=="PUBLISHER" && <AddThumbnail onClick={() => {
          setImageChangesQueue([])
          setOpenModal({open: true, id: item.id, microsite}) }}>
              <div>
                +
              </div>
            </AddThumbnail>} */}
        {openSettings && openSettingsDrop && !unAuthorized && (
          <ListItems>
            {microsite && !unAuthorized && portal?.portalRole !== "PUBLISHER" && (
              <ListButton
                onClick={() => {
                  setImageChangesQueue([]);
                  setOpenModal({
                    open: true,
                    id: item.id,
                    data: { micrositeThumbnail: item.thumbnailUrl },
                  });
                }}
              >
                Change thumbnail
              </ListButton>
            )}
            {portal?.portalRole !== "PUBLISHER" && (location.pathname.includes("/showrooms") || coverPage || microsite )&& (
              <>
                <ListButton className="tooltips" onClick={() => setPopup(true)}>
                  Add Tags
                </ListButton>
              </>
            )}

            {portal?.portalRole !== "PUBLISHER" && (
              <ListButton onClick={onRename}>Rename</ListButton>
            )}
            <ListButton onClick={() => setShare(true)}>Share</ListButton>
            {portal?.portalRole !== "PUBLISHER" && (
              <ListButton onClick={toggleDeleteModal}>Delete</ListButton>
            )}
          </ListItems>
        )}
        {shareableLink && (
          <ShareableLink
            setShareableLink={setShareableLink}
            shareableLink={shareableLink}
          />
        )}
        {share && (
          <Sharing
            itemName={getItemName()}
            portalId={portal.portalId}
            accountId={profile.accountId}
            itemId={id}
            handleClose={() => setShare(false)}
            onUrlCreated={url => {
              setShareableLink(url);
              setShare(false);
            }}
          />
        )}
             {popup &&
                <ImgModal
                  description={
                    coverPage ? "Add tags to your Cover Page.  Easy finding your files later" :  "Add tags to your Showroom.  Easy finding your files later"
                  }
                  title="Saving - Tags"
                  state={popup && !showPopUp}
                  hide={hidePopup}
                  extraClass="savemodal"
                  width="50%"
                  maxWidth="600px"
                >
                  <div className="center-content">
                    <div className="input-flex">
                      <p>Tags</p>
                        <Tags
                          tag={tagValue}
                          tags={coverPage ? coverPageTags : microsite ? micrositeTags : tagsList}
                          handleRemoveTag={(tag) => coverPage ? handleRemoveTag(tag, true) : handleRemoveTag(tag, false)}
                          handleKeyDown={(e) => coverPage ? handleKeyDown(e, true,false) : microsite ? handleKeyDown(e, false,true) :  handleKeyDown(e, false,false)}
                          handleChangeTag={(e) => setTagValue(e.target.value)}
                        />
                    </div>
                    <span className="spantxt">
                      To add tags, please press Enter
                    </span>
                    <div className="buttons">
                      <button onClick={hidePopup} style={{color: "white"}}>Cancel</button>
                      <button onClick={coverPage? saveNewTagsCoverpage : microsite ? saveNewTagsMicrosite : saveNewTags}>Done</button>
                    </div>
                  </div>
                </ImgModal>
               }
        <Footer check={check} className="template-title">
          <EditInput
            value={fieldState.value}
            name={name}
            disabled={rename}
            onChange={handleChange}
            onClose={onClose}
            handleRename={handleRename}
            nrLength="28"
          />
        </Footer>
        <ToastContainer />
      </ImageItemWrapper>
      {showDeleteModal && item.__typename !== "Microsite" && (
        <DeleteModelWithCheck
          item={item}
          toggleDeleteModal={toggleDeleteModal}
          name={name}
          deleteFunc={deleteFunc}
        />
      )}
      {showDeleteModal && item.__typename === "Microsite" && (
        <DeleteModal
          text={`Are you sure you want to delete ${name}?`}
          toggleModal={toggleDeleteModal}
          deleteFunc={deleteFunc}
        />
      )}
      {openModal.open && (
        <UploadModal
          closeModal={setOpenModal}
          modal={openModal}
          image={imageChangesQueue}
          setImage={setImageChangesQueue}
          title="Add new thumbnail to microsite"
          thumbnailDeleter={deleteThumbnail}
          closeOptions={showOptions}
          microsite={true}
        />
      )}
      {showPopUp && (
          <Modal show={showPopUp} onHide={!showPopUp} animation={false}>
              <WarnModal
              handleHitTagPrompt={handleHitTagPrompt}
              />
          </Modal>
      )}
    </>
  );
}
const DeleteCheckedModel = ({
  toggleDeleteModal,
  deleteFunc,
  name,
  micrositeByCoverpageId,
  micrositeByShowroomId,
}) => {
  const micrositeName =
    micrositeByCoverpageId?.name ||
    micrositeByShowroomId?.name;

  const modelText = micrositeName
    ? `Are you sure you want to delete ${name}, because it will affect the microsite ${micrositeName}, and might other microsites?`
    : `Are you sure you want to delete ${name}?`;
  return (
    <DeleteModal
      text={modelText}
      toggleModal={toggleDeleteModal}
      deleteFunc={deleteFunc}
    />
  );
};
const DeleteModelWithCheck = ({
  item,
  toggleDeleteModal,
  name,
  deleteFunc,
}) => {
  const { _id, __typename } = item;
  const showroomObj = { showroomId: _id };
  const coverapgeObj = { coverpageId: _id};
  const { data, isLoading } = useQuery(
    __typename === "Showroom"
      ? MicrositeByShowroomId
      : __typename === "Coverpage"
      ? MicrositeByCoverpageId
      : null,
    __typename === "Showroom"
      ? showroomObj
      : __typename === "Coverpage"
      ? coverapgeObj
      : {},
    {
      fetchPolicy: "store-and-network",
    }
  );

  if (isLoading) return null;
  return (
    <DeleteCheckedModel
      micrositeByCoverpageId={data.micrositeByCoverpageId}
      micrositeByShowroomId={data.micrositeByShowroomId}
      toggleDeleteModal={toggleDeleteModal}
      deleteFunc={deleteFunc}
      name={name}
    />
  );
};

export default ListImageComponent;
