import styled from "styled-components";
import Spinner from "../../../../assets/images/spinner.gif";

const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background-color: #000000d1;
`

const Img = styled.img`
    width: 300px;
    height: auto;
`

function Loader() {
    return (
        <Wrapper>
            <Img src={Spinner} />
        </Wrapper>
    );
}
export default Loader;
