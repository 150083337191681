import { Overlay, ContentWrapper, ShowRoomCard, CloseButton } from "./styles";
import { Image } from "../../../../containers/imageContainer";
import { useLocation, useNavigate } from 'react-router-dom';


const Card = (props) => ( 
    
    <ShowRoomCard onClick={()=>props.click(`/templates`)}>
        <Image url={props.image || "https://master-wizr-uploads.s3.eu-north-1.amazonaws.com/presentations/Slide1-1619191129903.JPG"} />
    </ShowRoomCard>
)

export function CheckoutShowrooms(props){
    const navigation = useNavigate();
    return(
        <Overlay>
            <CloseButton onClick={props.closeCheckoutShowrooms}>Close</CloseButton>
            <ContentWrapper>
                <Card  click={navigation}/>
            </ContentWrapper>
        </Overlay>
    )
}

export default CheckoutShowrooms;
