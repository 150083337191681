export function ShareIcon(props){
    return(
        <svg
            width={30}
            height={30}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            {...props}
        >
            {props.round ? <path fill={props.fill || "#8e8e8e"}
                d="M255.8 2.7C116.3 2.7 3.3 115.7 3.3 255.2s113 252.5 252.5 252.5 252.5-113 252.5-252.5S395.2 2.7 255.8 2.7zm0 491.3C123.9 494 17 387.1 17 255.2S123.9 16.4 255.8 16.4s238.8 106.9 238.8 238.8S387.7 494 255.8 494z"
                /> : ''}
            <path fill={props.fill || "#8e8e8e"} d="M334.5 297.5c-15.7 0-29.8 6.5-39.9 16.9l-76.7-42c1.8-5.5 2.8-11.3 2.8-17.3 0-6.8-1.3-13.3-3.5-19.3l76-41.6c10.2 11.3 24.9 18.5 41.3 18.5 30.7 0 55.6-24.9 55.6-55.6s-24.9-55.6-55.6-55.6c-30.7 0-55.6 24.9-55.6 55.6 0 6.2 1 12.1 2.9 17.6l-76.6 41.9c-10.1-10.6-24.3-17.2-40.1-17.2-30.7 0-55.6 24.9-55.6 55.6s24.9 55.6 55.6 55.6c16.5 0 31.4-7.3 41.5-18.7l75.9 41.5c-2.3 6.1-3.6 12.7-3.6 19.6 0 30.7 24.9 55.6 55.6 55.6 30.7 0 55.6-24.9 55.6-55.6s-24.9-55.5-55.6-55.5zm0-174.6c19 0 34.4 15.4 34.4 34.4s-15.4 34.4-34.4 34.4-34.4-15.4-34.4-34.4 15.4-34.4 34.4-34.4zM165.1 289.6c-19 0-34.4-15.4-34.4-34.4s15.4-34.4 34.4-34.4 34.4 15.4 34.4 34.4-15.4 34.4-34.4 34.4zm169.4 97.9c-19 0-34.4-15.4-34.4-34.4s15.4-34.4 34.4-34.4 34.4 15.4 34.4 34.4-15.4 34.4-34.4 34.4z" />
        </svg>

    )
}

export default ShareIcon;
