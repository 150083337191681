import graphql from "babel-plugin-relay/macro";

const PortalQuery = graphql`
  query GetPortalQuery($id: ID!) {
    portal(id: $id) {
      ... on Portal {
        _id
        name
        menus {
          name
          _id
          visibility
          folders {
            _id
            name
            presentations {
              _id
              name
              coverUrl
              files {
                fileType
                fileUrl
                thumbnailUrl
                coverUrl
                name
                _id
                positions {
                  presentationId
                  position
                }
              }
            }
          }
        }
      }
      ... on Response {
        message
        status
      }
    }
  }
`;

export default PortalQuery;
