import styled from "styled-components"


export const Button = styled.button`
    & {
    border-radius: ${props => props.br ? props.br : '10px'};;
    background: ${props => props.bg};
    height: ${props => props.height ? props.height : '35px'};
    width: ${props => props.width};
    font-weight: ${props => props.bold ? '700' : props.fontWeight};
    padding: ${props => props.pd || '0 20px'};
    font-size: 13px;
    box-shadow: ${props => props.dropShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : ''};
    margin-top: ${props => props.mt ? props.mt : ''};
    margin-right: ${props => props.mr ? props.mr : ''};
    margin-bottom: ${props => props.mb ? props.mb : ''};
    margin-left: ${props => props.ml ? props.ml : ''};
    margin: ${props => props.m || ''};
    cursor: pointer;
    text-transform: ${props => props.tt || 'uppercase'};
    float: ${props => props.fl ? props.fl : ''};
    border: ${props => props.b};
    &:focus {
        outline: none;
    }
    &:hover {
        color: ${props => props.hovercolor || ''} !important;
    }
    color: ${props => props.color};
    z-index: ${props => props.zIndex};
  }  
  @media screen and (min-width: 2560px) {
        padding: 10px 20px;
        width: 180px;
        font-size: 25px;
        height:inherit;
  }
  @media screen and (min-width: 3000px) {
          padding: 15px 20px;
          width: 270px;
          font-size: 38px;
          height:inherit;
  }
  @media screen and (min-width: 4000px) {
          padding: 20px;
          width: 380px;
          font-size: 49px;
          height:inherit;
  }
`


export const DangerButton = styled(Button)`
    color: ${props => props.color ? props.color : '#EE0D0D'};
    background: ${props => props.bg || 'rgb(238,13,13,9%)'};
`
//Primary Button
export const PrimaryButton = styled.button`
  //Default
  width: 110px;
  height: 30px;
  padding: 5px 20px;
  font-size: 14px;
  text-align: center;
  color: #fbfbfb; //default
  font: 20px;
  border-radius: 6px;
  opacity: 1;
  margin-right: ${props => (props.marginRight ? "61px" : "0px")};
  margin-top: ${props => (props.marginTop ? "20px" : "0px")};
  background: ${props => props.gray ? "#575757 0% 0% no-repeat padding-box" : props.red ? '#FC2121' : "#FC9621 0% 0% no-repeat padding-box"};
  &:hover {
    background-color: white;
    color: #ec7600;
  }

  //Added
  transition: 0.2s ease-in-out;
  cursor: pointer;

  //Later on we can switch to this animation
  /*
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(#fc9621, 8%);
    transition: all 0.3s;
    z-index: -1;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }*/
  @media screen and (min-height: 900px) {
    width: 120px;
    height:inherit;
    padding: 8px 20px;
  }
  @media screen and (min-width: 2560px) {
    width: 160px;
    font-size: 20px;
    height: 45px;
  }
  @media screen and (min-width: 3000px) {
    width: 270px;
    font-size: 35px;
    height: 70px;
  }
  @media screen and (min-width: 4000px) {
        padding: 20px;
        width: 380px;
        font-size: 49px;
        height:inherit;
  }
`;

//Secondary Button
export const SecondaryButton = styled.button`
  width: 134px;
  height: 71px;
  background: #373737 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
  font: normal normal bold 20px/24px Raleway;
  letter-spacing: 0px;
  color: #ec7600;
  display: flex;
  margin-left: auto;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font: normal normal bold 20px/24px Raleway;
  }
`;

//Slide Button
export const SideButton = styled.button`
  width: 41px;
  height: 97px;
  color:#FFFFFF;
  background: #363636 0% 0% no-repeat padding-box;
  border-radius: 0px 2px 2px 0px;
  opacity: 1;
  position: absolute;
  top: 50%;

  p {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #ffffff;
    font-size: 14px;
    height: 100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  @media screen and (min-width: 2000px) {
    width: 60px;
    height: 150px;
    p{
      font-size:22px;
    }
  }
  @media screen and (min-width: 3000px) {
    width: 90px;
    height: 220px;
    p{
      font-size:35px;
    }
  }
  @media screen and (min-width: 4000px) {
    width: 115px;
    height: 280px;
    p{
      font-size:49px;
    }
  }

`;

//Tertiary Button
export const TertiaryButton = styled.button`
  width: ${props => (props.gray ? "58px" : "50px")};
  height: ${props => (props.gray ? "21px" : "0px")};
  text-align: center;
  text-decoration: underline;
  font: ${props =>
    props.gray
      ? "#normal normal normal 18px/21px Raleway"
      : "normal normal bold 20px/24px Raleway"};
  letter-spacing: 0px;
  color: ${props => (props.gray ? "#FFFFFF" : "#FF7700")};
  opacity: 1;
  margin-bottom: ${props => (props.marginBotom ? "20px" : "0px")};
`;

//Circular Button
export const CircualButton = styled.button`
  width: 65px;
  height: 65px;
  background: #474747 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal 600 10px/11px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

//Helper Button Add Copy etc.
export const HelperButton = styled.button`
  width: 76px;
  height: 30px;
  background: #0c0c0c 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #cccccc;
`;
