import graphql from "babel-plugin-relay/macro";

const ShowroomQuery = graphql`
  query GetShowroomQuery($showroomId: ID!) {
    showroom(id: $showroomId) {
      ... on Showroom {
        _id
        id
        name
        mainHeading
        subHeading
        headerImage
        templateId
        tags
        link
        creator
        coverpagesIds
        backgroundImage
        accountId
        createdAt
        content {
          name
          content {
            image
            mainHeading
            presentationId
            subHeading
            imageUrl
            presentation {
                  coverUrl
                  portalId
                  name
                  menuId
                  folderId
                }
            files {
              _id
              name
              fileUrl
              fileType
              coverUrl
              thumbnailUrl
              positions {
                presentationId
                position
              }
            }
          }
        }
      }

      ... on Response {
        message
        status
        success
        token
        hash
        signedUrl
        userId
        shared
      }
    }
  }
`;

export default ShowroomQuery;
