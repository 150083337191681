
import PropTypes from "prop-types";

const CancelButton = props => {
    const {
        handleCancel
    } = props;

    return (
        <button
            className="cover-button cancel-button"
            onClick={handleCancel}
        >
            Cancel
        </button>
    );
};

CancelButton.propTypes = {
    handleCancel: PropTypes.func
};

export default CancelButton;
