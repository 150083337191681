import { positionFiles } from "helpers/fileHandler";
import { useMemo } from "react";

/**
 * Hook to calculate the file positions for the given presentation id
 * @param {*} id Presentation id for the files whos positions are to get calculated
 * @param {*} files Files for the with presentations to find the presentationId positions
 * @param {*} skip Skip presentation positining, if we only have files without presentations;
 * @returns [files] with positions adjusted for the given presentations
 */
export const usePresentationFilesOrdering = (id, files, skip = false) => {
  return useMemo(() => {
    let sortedFiles = [];
    if ((files.length && id) || (files.length && !id && skip)) {
      sortedFiles = positionFiles(id, files);
    }
    return sortedFiles;
  }, [id, files, skip]);
};
