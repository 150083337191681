// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "graphql/environment/index.js";

// 2
const mutation = graphql`
  mutation CreateMicrositeMutation($microsite: newMicrosite) {
    createMicrosite(microsite: $microsite) {
      ... on Microsite {
        _id
        id
        name
        showroomsIds
        backgroundImage
        coverpageId
        templateId
        tags
        portalId
        creator
        accountId
        createdAt
        updatedAt
      }
      ... on Response {
        __typename
        message
        status
        success
        token
        hash
        userId
      }
    }
  }
`;

export default (microsite, callback) => {
  // 4
  const variables = {
    microsite,
  };

  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err),
    updater: store => {
      window.store = store;
    },
  });
}; // 3
