import AWS from "aws-sdk";
import { AWS_KEY_ID, AWS_KEY, BUCKET, AWS_REGION } from "constants/env";
import { getFileExtension } from "./fileHandler";

AWS.config.update({
  accessKeyId: AWS_KEY_ID,
  secretAccessKey: AWS_KEY,
  region: AWS_REGION,
});
const S3 = new AWS.S3();
const thumbnailUrl = (type, filename) => {
  if (["jpeg", "jpg", "png", "gif"].includes(type.toLowerCase())) {
    return `thumbnails/${filename}`;
  }
  return "";
};

export async function deleteFilesByUrls(urls) {
  if (!urls.length || urls.length === 0) return [];
  const result = [];
  urls.map(async url => {
    const slashArr = url.split("/");
    const name = slashArr.pop();
    const prefix = slashArr.pop();
    const Key = `${prefix}/${name}`;
    let params = { Bucket: BUCKET, Key };
    try {
      await S3.deleteObject(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else console.log("Response:", data);
      }).promise();
      result.push({ url, status: true });
    } catch (e) {
      result.push({ url, status: false });
      console.log(e);
    }
  });
  return result;
}

export async function uploadFiles(files, prefix) {
  console.log("files", files);
  const newFiles = [];
  for (let idx = 0; idx <= files.length - 1; idx++) {
    const file = files[idx];
    if (!file || !file.name) return [];
    const fileExtention = getFileExtension(file.name);
    file.tempName = file.tempName.replace(/[^a-zA-Z0-9.]/g, '')
    const newFileName = `${file.tempName}-${Date.now()}.${fileExtention}`;
    const Key = `${prefix}/${newFileName}`;
    let paramsCov = { Bucket: BUCKET, Body: file, Key, ContentType: file.type };
    await S3.upload(paramsCov).promise();
    newFiles.push({
      fileUrl: Key,
      name: file.tempName,
      thumbnailUrl: thumbnailUrl(fileExtention, newFileName),
      position: file.position,
      fileType: file.fileType || file.type,
      
    });
  }
  return newFiles;
}

export async function uploadFilesAndDeleteByUrl(filesObject, prefix) {
  const files = filesObject.map(fobj => fobj.blob);
  const urls = filesObject.map(fobj => fobj.coverUrl);
  const uploadedResult = await uploadFiles(files, prefix);
  const deletedResult = await deleteFilesByUrls(urls);
  return { uploadedResult, deletedResult };
}
