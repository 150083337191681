import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    width: 82%; 
`

export const Input = styled.input`
    padding: 5px 10px;
    background: #3B3B3B;
    margin-bottom: 8px;
    border-radius: 8px;
    width: 100% !important;
`

export const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

export const Button = styled.div`
    margin-right: 5px;
    margin-bottom: 8px;
    padding: 5px 10px;
    background: #1e1e1ea1;
    border-radius: 8px;
    font-size:12px;
    span{
        margin-left: 10px;
        cursor: pointer;

        &:hover{
            color: #F08202;
        }
    }
    @media (min-height: 1440px) and (min-width: 2560px) {
        font-size: 15px;
    }
    @media (min-height: 2160px) and (min-width: 3840px) {
        font-size: 17px;
    }
`