import React, { useState } from "react";
import styles from "./FAQ.module.scss";
import { faqData } from "constants/home";
import { ReactComponent as Plus } from "assets/images/plusIcon.svg";
import { ReactComponent as Minus } from "assets/images/minusIcon.svg";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const {t} = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter" || event.key === " ") {
      toggleFaq(index);
    }
  };

  return (
    <div className={styles.faqSection}>
      <span className={styles.faq}>{t("faq")}</span>
      <h1 className={styles.desc}>{t("in-case-you-missed-anything")}</h1>
      {faqData.map((faq, index) => (
        <div
          key={index}
          className={`${styles.faqItem} ${
            activeIndex === index ? styles.active : ""
          }`}
          onClick={() => toggleFaq(index)}
          onKeyDown={event => handleKeyDown(event, index)}
          role="button"
          tabIndex={0}
        >
          <div className={styles.question}>
            <h3 className={styles.title}>{t(faq.question)}</h3>
            {activeIndex === index ? <Minus /> : <Plus />}
          </div>
          {activeIndex === index && (
            <div className={styles.answer}>{t(faq.answer)}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
