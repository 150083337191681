import graphql from "babel-plugin-relay/macro";


const MicrositesQuery = graphql`
  query GetMicrositesQuery {
    microsites {
      ... on ListOfMicrosites {
        microsites {
          _id
          id
          name
          showroomsIds
          backgroundImage
          coverpageId
          tags
          templateId
          portalId
          creator
          accountId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default MicrositesQuery;
