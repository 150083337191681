import graphql from "babel-plugin-relay/macro";

const MicrositesQuery = graphql`
  query GetMicrositesByPortalQuery($portalId: ID!) {
    micrositesByPortal(portalId: $portalId) {
      ... on ListOfMicrosites {
        microsites {
          __typename
          _id
          id
          name
          showroomsIds
          tags
          backgroundImage
          coverpageId
          templateId
          portalId
          creator
          accountId
          thumbnailUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default MicrositesQuery;
