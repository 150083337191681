import React from "react";

function ProgressBar({ currentStep, totalSteps }) {
  const progress = ((currentStep + 1) / totalSteps) * 100;

  return (
    <div className="progress-bar">
      <div
        className="progress-bar-fill"
        style={{ width: `${progress}%` }}
      ></div>
      <p style={{ left: `${progress}%` }}>{Math.floor(progress)}%</p>
    </div>
  );
}
export default ProgressBar;
