import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import "./details.scss";
import bg from "../../../../assets/images/bg.png";
import nobg from "../../../../assets/images/nobg.png";
import SharedText from "../SharedText/SharedText";
import {mainHeadingInit, mainSubHeadingInit } from './editorSettings';
import { useQuery } from "relay-hooks";
import CoverpageQuery from "graphql/queries/GetCoverpageQuery";
import Checkbox from "containers/checkbox";
import PopUp from "../PopUp/PopUp";
import ShowroomsQuery from "graphql/queries/GetShowroomsQuery";
import AddShowroom from "./addShowroomPopup";
import { ShowroomsLink } from "containers/Templates/coverpages/common";


const ContentWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    justify-content: ${(props) => props.block || 'flex-end'};
`;

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 360px;
    width: 80%;
    @media screen and (min-width: 2000px) {
        max-width: 500px;
    }
    @media screen and (min-width: 3000px) {
        max-width: 700px;
    }
    @media screen and (min-width: 4000px) {
        max-width: 950px;
    }
`;

const Navigation = styled.div`
    display: inline-flex;
    gap: 10px;
    padding-bottom: 5px;
    align-items: center;
    width: 100%;
    justify-content: center;
    @media (min-height: 600px) {
        padding-bottom: 10px;
    }

`;

const ContentDiv = styled.div`
    #main-heading > p {
        font-size: 1.7rem;
        color: ${(props) => props.textColor || 'white'};
        @media screen and (min-height: 722px) {
            font-size: 2em;
        }
        @media screen and (min-width: 2000px) {
            font-size: 3.5rem;
        }
        @media screen and (min-width: 3000px) {
            font-size: 5em;
        }
        @media screen and (min-width: 4000px) {
            font-size: 7em;
        }
    }
    .main-shared-text h3 p {
        color: ${(props) => props.textColor || 'white'};
        // font-weight: 500;
        @media screen and (min-width: 2000px) {
            font-size: 22px;
            line-height: 2.5rem;
        }
        @media screen and (min-width: 3000px) {
            font-size: 35px;
            line-height: 3.5rem;
        }
        @media screen and (min-width: 4000px) {
            font-size: 49px;
            line-height: 4.5rem;
        }
    }
`;

function DetailedPage({coverpageData, onChangeEditor, backgroundUrl, textColor,handleCheckoutShowrooms, updateCoverpage, disabled, isPreview, sharing, showroomid}) {
    const location = useLocation();
    const navigation = useNavigate();
    const { state } = location;
    const [block, setBlock] = useState('flex-end');
    const [background, setBackground] = useState("");
    const [darkbackground, setDarkbackground] = useState("");
    const [coloreddiv, setColoreddiv] = useState("");
    const [opendiv, setOpendiv] = useState("");
    const [showList, setShowList] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const { data, isLoading } = useQuery(
        CoverpageQuery,
        { coverpageId: id },
        {
          fetchPolicy: "store-and-network",
        }
    );

    useEffect(() => {
        if (id && !isLoading) {
            updateCoverpage(data.coverpage)
        }
    }, [data, isLoading]);

    const addShowroomIds = (showroomIDs) => {
        updateCoverpage({showroomIds: showroomIDs})
    }

    const Nobackground = (color) => {
        if (background.includes(color)) {
            setBackground("");
        }
        else {
            setBackground(color);
        }
    };
    const addWhite = (div) => {
        if (opendiv.includes(div)) {
            setOpendiv("");
        }
        else {
            setOpendiv(div);
        }
    };

    const Changebg = (color) => {
        if (coloreddiv.includes(color)) {
            setColoreddiv("");
        }
        else {
            setColoreddiv(color);
        }
    };

    const backgroundColored = () => {
        if (coloreddiv==="dark-blur") {
            setColoreddiv("background-color");
        }
        else {
            setColoreddiv("dark-blur");

        }
    };

    return (
        <div className="detailed-page">
            <div className={`image-part ${background}`} style={{ backgroundImage: `url(${backgroundUrl})` }}>
                <ContentWrapper block={block}>
                    <Wrapper>
                        <Navigation>
                            <div className={`text-position ${coloreddiv} ${opendiv} ${(sharing || isPreview) && 'sharingmode'}`} >
                                <div  className={`lighter color ${block==="flex-start" && 'activeBlock'}`} onClick={() => {
                                    setBlock('flex-start');
                                }}></div>
                                <div className={`dark color ${block==="center" && 'activeBlock'}`} onClick={() => {
                                    setBlock('center');
                                }}></div>
                                <div className={`darker color ${block==="flex-end" && 'activeBlock'}`} onClick={() => {
                                    setBlock('flex-end');
                                }}></div>
                            </div>
                        </Navigation>
                        <ContentDiv 
                            className={`blur box ${coloreddiv} ${opendiv}`}
                            style={{ backgroundColor: darkbackground }}
                            textColor={textColor}>
                            <SharedText
                                title={coverpageData.mainHeading}
                                isSubHead={true}
                                disabled={disabled}
                                description={coverpageData.details}
                                onChangeEditor={onChangeEditor}
                                mainHeadingInit={mainHeadingInit} 
                                subHeadingInit={mainSubHeadingInit}
                            />
                            {/* <div className="link-icon">
                                <button onClick={() => setShowList(true)}>
                                    Checkout showroom
                                </button>
                            </div> */}
                        <AddShowroom setShowList={setShowList} showList={showList} addShowroomIds={addShowroomIds} showroomIds={coverpageData.showroomIds} />
                        </ContentDiv>
                        <div className="link-icon">
                        {showroomid?<ShowroomsLink onClick={() =>{navigation(`/showrooms/${showroomid}`)}}>Checkout my Show Rooms</ShowroomsLink>:""}
                        </div>
                        <div>
                            <div className={`backgrounds ${coloreddiv} ${opendiv} ${(sharing || isPreview) && 'sharingmode'}`} style={{ backgroundColor: darkbackground }} >
                                <img src={nobg} alt="bg"onClick={() => {
                                        Nobackground("no-background");
                                    }} />
                                <img src={bg} alt="bg" onClick={backgroundColored}
                                />
                                <div className="colors">
                                    <div className="white-icon" onClick={() => {
                                        Changebg("dark-blur");
                                    }} ></div>
                                    <div className={`dropdown_container`}>
                                        <div className="dark" onClick={() => {
                                            Changebg("background-color");
                                        }} ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                </ContentWrapper>

            </div>
        </div>
    );
}
export default DetailedPage;

DetailedPage.propTypes = {
    match: PropTypes.string,
    location: PropTypes.string,
    disabled: PropTypes.bool,
};
