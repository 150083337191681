/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LICENSE_OPTIONS = "OPTION1" | "OPTION2" | "OPTION3" | "%future added value";
export type ROLES = "ADMINISTRATOR" | "CREATOR" | "EDITOR" | "PUBLISHER" | "STUDENT" | "SUPERADMINISTRATOR" | "TRAINER" | "VIEWER" | "%future added value";
export type TIER = "LITE" | "REGULAR" | "%future added value";
export type WatchUserUpdatesSubscriptionVariables = {|
  id: string
|};
export type WatchUserUpdatesSubscriptionResponse = {|
  +userUpdates: ?{|
    +profiles: $ReadOnlyArray<?{|
      +_id: string,
      +userId: string,
      +accountId: string,
      +accountRole: ROLES,
      +accountInfo: ?{|
        +isActive: ?boolean,
        +_id: string,
        +name: string,
        +licenseInfo: ?{|
          +licenseType: LICENSE_OPTIONS
        |},
      |},
      +userInfo: {|
        +_id: string,
        +firstName: ?string,
        +lastName: ?string,
        +email: string,
      |},
      +accessedModules: ?$ReadOnlyArray<?{|
        +name: string,
        +role: ?ROLES,
        +tier: ?TIER,
      |}>,
      +accessedPortals: ?$ReadOnlyArray<?{|
        +portalId: string,
        +portalRole: ?ROLES,
        +portalInfo: ?{|
          +_id: string,
          +accountId: string,
          +name: string,
          +creator: string,
          +createdAt: any,
          +updatedAt: ?any,
        |},
      |}>,
    |}>
  |}
|};
export type WatchUserUpdatesSubscription = {|
  variables: WatchUserUpdatesSubscriptionVariables,
  response: WatchUserUpdatesSubscriptionResponse,
|};
*/


/*
subscription WatchUserUpdatesSubscription(
  $id: ID!
) {
  userUpdates(id: $id) {
    profiles {
      _id
      userId
      accountId
      accountRole
      accountInfo {
        isActive
        _id
        name
        licenseInfo {
          licenseType
        }
      }
      userInfo {
        _id
        firstName
        lastName
        email
      }
      accessedModules {
        name
        role
        tier
      }
      accessedPortals {
        portalId
        portalRole
        portalInfo {
          _id
          accountId
          name
          creator
          createdAt
          updatedAt
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userUpdates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "profiles",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accountInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isActive",
                "storageKey": null
              },
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "License",
                "kind": "LinkedField",
                "name": "licenseInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "licenseType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "userInfo",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleRole",
            "kind": "LinkedField",
            "name": "accessedModules",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PortalRoles",
            "kind": "LinkedField",
            "name": "accessedPortals",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portalRole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Portal",
                "kind": "LinkedField",
                "name": "portalInfo",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creator",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchUserUpdatesSubscription",
    "selections": (v4/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchUserUpdatesSubscription",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "52b1c3f49ec64185d81276a08dd6f93e",
    "id": null,
    "metadata": {},
    "name": "WatchUserUpdatesSubscription",
    "operationKind": "subscription",
    "text": "subscription WatchUserUpdatesSubscription(\n  $id: ID!\n) {\n  userUpdates(id: $id) {\n    profiles {\n      _id\n      userId\n      accountId\n      accountRole\n      accountInfo {\n        isActive\n        _id\n        name\n        licenseInfo {\n          licenseType\n        }\n      }\n      userInfo {\n        _id\n        firstName\n        lastName\n        email\n      }\n      accessedModules {\n        name\n        role\n        tier\n      }\n      accessedPortals {\n        portalId\n        portalRole\n        portalInfo {\n          _id\n          accountId\n          name\n          creator\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a288acce0b987b986aa709671b0cfec6';

module.exports = node;
