/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MicrositeByCoverpageIdQueryVariables = {|
  coverpageId: string
|};
export type MicrositeByCoverpageIdQueryResponse = {|
  +micrositeByCoverpageId: ?({|
    +__typename: "Response",
    +message: string,
    +status: number,
  |} | {|
    +__typename: "Microsite",
    +coverpageId: ?string,
    +name: string,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |})
|};
export type MicrositeByCoverpageIdQuery = {|
  variables: MicrositeByCoverpageIdQueryVariables,
  response: MicrositeByCoverpageIdQueryResponse,
|};
*/


/*
query MicrositeByCoverpageIdQuery(
  $coverpageId: ID!
) {
  micrositeByCoverpageId(coverpageId: $coverpageId) {
    __typename
    ... on Response {
      __typename
      message
      status
    }
    ... on Microsite {
      __typename
      coverpageId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "coverpageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "coverpageId",
    "variableName": "coverpageId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverpageId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MicrositeByCoverpageIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "micrositeByCoverpageId",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "Microsite",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MicrositeByCoverpageIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "micrositeByCoverpageId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Response",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "Microsite",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d624bf305d2699720009414d8e0bbf21",
    "id": null,
    "metadata": {},
    "name": "MicrositeByCoverpageIdQuery",
    "operationKind": "query",
    "text": "query MicrositeByCoverpageIdQuery(\n  $coverpageId: ID!\n) {\n  micrositeByCoverpageId(coverpageId: $coverpageId) {\n    __typename\n    ... on Response {\n      __typename\n      message\n      status\n    }\n    ... on Microsite {\n      __typename\n      coverpageId\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3a0cd833ccba48da5738511308e088d';

module.exports = node;
