export const showroomExamples = [
    "https://cdn.cf.masterwizr.com/Rectangle_16_t2uu3n.jpg",
    "https://cdn.cf.masterwizr.com/Rectangle_9_hzgknr.jpg",
    "https://cdn.cf.masterwizr.com/Rectangle_11_cfbgd9.jpg",
    "https://cdn.cf.masterwizr.com/Rectangle_5_rpcou6.jpg",
    "https://cdn.cf.masterwizr.com/Rectangle_12_cq0ltd.jpg",
    "https://cdn.cf.masterwizr.com//Rectangle_13_t4zl53.jpg",
    "https://cdn.cf.masterwizr.com/Rectangle_6_bdrdtz.jpg"
]

export const coversExamples = [
    "https://cdn.cf.masterwizr.com/example11_h58lxw.png",
    "https://cdn.cf.masterwizr.com/example22_hidjzj.png",
    "https://cdn.cf.masterwizr.com/example33_t5ymke.png",
    "https://cdn.cf.masterwizr.com/example55_bs6rxy.png",
    "https://cdn.cf.masterwizr.com/example11_h58lxw.png",
    "https://cdn.cf.masterwizr.com/example22_hidjzj.png",
    "https://cdn.cf.masterwizr.com/example33_t5ymke.png",
    "https://cdn.cf.masterwizr.com/example55_bs6rxy.png",
]

export const micrositesExamples = [
    "https://cdn.cf.masterwizr.com/example4_stx1bc.png",
    "https://cdn.cf.masterwizr.com/example5_b5phth.png",
    "https://cdn.cf.masterwizr.com/example6_cegth6.png",
    "https://cdn.cf.masterwizr.com/example7_larmbo.png",
    "https://cdn.cf.masterwizr.com/example4_stx1bc.png",
    "https://cdn.cf.masterwizr.com/example5_b5phth.png",
    "https://cdn.cf.masterwizr.com/example6_cegth6.png",
    "https://cdn.cf.masterwizr.com/example7_larmbo.png",
]