/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetMagazinesByPortalQueryVariables = {|
  portalId: string
|};
export type GetMagazinesByPortalQueryResponse = {|
  +magazinesByPortalId: ?{|
    +magazines?: $ReadOnlyArray<{|
      +_id: string,
      +id: string,
      +name: string,
      +backgroundColor: ?string,
      +backgroundImage: ?string,
      +companyLogo: ?string,
      +mainTemplateId: string,
      +subTemplateId: number,
      +portalId: string,
      +rows: ?$ReadOnlyArray<?{|
        +content: ?$ReadOnlyArray<?{|
          +heading: ?string,
          +subHeading: ?string,
          +description: ?string,
          +headerImage: ?string,
          +fileUrl: ?string,
          +videoThumbnail: ?string,
          +cardThumbnail: ?string,
          +headerOverlayContent: ?string,
          +linkTo: ?string,
          +buttonText: ?string,
          +graphData: ?{|
            +attr1: ?string,
            +attr2: ?string,
            +attr3: ?string,
            +data: ?$ReadOnlyArray<?{|
              +name: ?string,
              +val1: ?number,
              +val2: ?number,
              +val3: ?number,
            |}>,
          |},
        |}>,
        +isShown: ?boolean,
      |}>,
      +thumbnail: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +creator: string,
      +accountId: ?string,
      +createdAt: any,
      +updatedAt: ?any,
    |}>,
    +message?: string,
    +status?: number,
    +success?: boolean,
  |}
|};
export type GetMagazinesByPortalQuery = {|
  variables: GetMagazinesByPortalQueryVariables,
  response: GetMagazinesByPortalQueryResponse,
|};
*/


/*
query GetMagazinesByPortalQuery(
  $portalId: ID!
) {
  magazinesByPortalId(portalId: $portalId) {
    __typename
    ... on ListOfMagazines {
      magazines {
        _id
        id
        name
        backgroundColor
        backgroundImage
        companyLogo
        mainTemplateId
        subTemplateId
        portalId
        rows {
          content {
            heading
            subHeading
            description
            headerImage
            fileUrl
            videoThumbnail
            cardThumbnail
            headerOverlayContent
            linkTo
            buttonText
            graphData {
              attr1
              attr2
              attr3
              data {
                name
                val1
                val2
                val3
              }
            }
          }
          isShown
        }
        thumbnail
        tags
        creator
        accountId
        createdAt
        updatedAt
      }
    }
    ... on Response {
      message
      status
      success
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "portalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "portalId",
    "variableName": "portalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Magazine",
      "kind": "LinkedField",
      "name": "magazines",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyLogo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainTemplateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subTemplateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Row",
          "kind": "LinkedField",
          "name": "rows",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Card",
              "kind": "LinkedField",
              "name": "content",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "heading",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subHeading",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "headerImage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fileUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "videoThumbnail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cardThumbnail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "headerOverlayContent",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkTo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "buttonText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Graph",
                  "kind": "LinkedField",
                  "name": "graphData",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "attr1",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "attr2",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "attr3",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GraphData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "val1",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "val2",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "val3",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isShown",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfMagazines",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    }
  ],
  "type": "Response",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetMagazinesByPortalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "magazinesByPortalId",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetMagazinesByPortalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "magazinesByPortalId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f429d1d4947b52757146a8046c344ec",
    "id": null,
    "metadata": {},
    "name": "GetMagazinesByPortalQuery",
    "operationKind": "query",
    "text": "query GetMagazinesByPortalQuery(\n  $portalId: ID!\n) {\n  magazinesByPortalId(portalId: $portalId) {\n    __typename\n    ... on ListOfMagazines {\n      magazines {\n        _id\n        id\n        name\n        backgroundColor\n        backgroundImage\n        companyLogo\n        mainTemplateId\n        subTemplateId\n        portalId\n        rows {\n          content {\n            heading\n            subHeading\n            description\n            headerImage\n            fileUrl\n            videoThumbnail\n            cardThumbnail\n            headerOverlayContent\n            linkTo\n            buttonText\n            graphData {\n              attr1\n              attr2\n              attr3\n              data {\n                name\n                val1\n                val2\n                val3\n              }\n            }\n          }\n          isShown\n        }\n        thumbnail\n        tags\n        creator\n        accountId\n        createdAt\n        updatedAt\n      }\n    }\n    ... on Response {\n      message\n      status\n      success\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c279bbd35f8c5b3368c34a751f8d71de';

module.exports = node;
