/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type clientOnlyQueryVariables = {||};
export type clientOnlyQueryResponse = {|
  +__typename: string,
  +selectedAccounts: ?{|
    +details: ?string
  |},
  +selectedProfile: ?{|
    +details: ?string
  |},
  +selectedPortal: ?{|
    +details: ?string
  |},
  +selectedPortals: ?{|
    +details: ?string
  |},
  +selectedShowRooms: ?{|
    +details: ?string
  |},
  +selectedMicrosites: ?{|
    +details: ?string
  |},
  +selectedCoverPages: ?{|
    +details: ?string
  |},
  +selectedModal: ?{|
    +details: ?string
  |},
  +selectedPortalrole: ?{|
    +details: ?string
  |},
  +selectedSidebar: ?{|
    +details: ?string
  |},
|};
export type clientOnlyQuery = {|
  variables: clientOnlyQueryVariables,
  response: clientOnlyQueryResponse,
|};
*/


/*
query clientOnlyQuery {
  __typename
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "details",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedAccounts",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedProfile",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedPortal",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedPortals",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedShowRooms",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedMicrosites",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedCoverPages",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedModal",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedPortalrole",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientOnlyType",
        "kind": "LinkedField",
        "name": "selectedSidebar",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "clientOnlyQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "clientOnlyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f2f037188f24904aaef9f7212adb3d3",
    "id": null,
    "metadata": {},
    "name": "clientOnlyQuery",
    "operationKind": "query",
    "text": "query clientOnlyQuery {\n  __typename\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fb22ea953d5e83017422674e98d2220';

module.exports = node;
