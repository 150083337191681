import graphql from "babel-plugin-relay/macro";

const clientOnly = graphql`
  query clientOnlyQuery {
    ... on Query {
      __typename
    }
    selectedAccounts {
      details
    }
    selectedProfile {
      details
    }
    selectedPortal {
      details
    }
    selectedPortals {
      details
    }
    selectedShowRooms {
      details
    }
    selectedMicrosites {
      details
    }
    selectedCoverPages {
      details
    }
    selectedModal {
      details
    }  
    selectedPortalrole {
      details
    }
    selectedSidebar {
      details
    }
  }
`;

export default clientOnly;
