import MicrositeQuery from "graphql/queries/GetMicrositeQuery";
import ShowroomsByPortalQuery from "graphql/queries/showroom/ShowroomsByPortal";
import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "relay-hooks";
import RoomSlider from "../RoomSlider";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import IsLoading from "components/ui/atoms/IsLoading";

function SharedMicrositeDetailedPage(props) {
  return <RoomSlider {...props} />;
}

function MicrositeDetailedPage() {
  const { id, coverpageid } = useParams();
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const urlParams = new URLSearchParams(window.location.search);
  const micrositeId = urlParams.get("micrositeid");

  const { data, isLoading } = useQuery(
    ShowroomsByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    }
  );

  const queryMicrosite = useQuery(
    MicrositeQuery,
    { micrositeId: micrositeId },
    {
      fetchPolicy: "store-or-network",
      skip: !micrositeId,
    }
  );

  const fetchdata = () => {
    let arr = id.split(",");
    const showrooms = data?.showroomsByPortalId?.showrooms
    let tempArr = [];
    for(let i = 0; i<arr.length;i++){
      for(let j = 0; j<showrooms.length;j++){
        if(arr[i] === showrooms[j]._id){
          tempArr.push(showrooms[j]);
        }
      }
    }
    return {showroomsIds: arr, selectedShowRooms: tempArr};
  }

  if (isLoading || queryMicrosite.isLoading) return <IsLoading micrositeContent/>;

  return <RoomSlider {...fetchdata()} queryMicrosite={queryMicrosite} />;
}

function MicrositePage(props) {
  const isSharingMode = window.location.pathname.includes("/share/");
  return isSharingMode ? (
    <SharedMicrositeDetailedPage {...props} />
  ) : (
    <MicrositeDetailedPage />
  );
}
export default MicrositePage;
